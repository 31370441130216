// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EnergyHeader--btn {
  display: flex;
  gap: 2rem;
  padding-block: 24px;
  font-weight: 600; }
  .EnergyHeader--btn p {
    font-size: 18px;
    align-self: center; }
`, "",{"version":3,"sources":["webpack://./src/Views/AfterAuth/StartChargeingView/EnergyCalculations/EnergyHeader/EnergyHeader.scss"],"names":[],"mappings":"AACI;EACI,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,gBAAgB,EAAA;EAJnB;IAMO,eAAe;IACf,kBAAkB,EAAA","sourcesContent":[".EnergyHeader {\n    &--btn {\n        display: flex;\n        gap: 2rem;\n        padding-block: 24px;\n        font-weight: 600;\n        p {\n            font-size: 18px;\n            align-self: center;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
