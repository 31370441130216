import React from 'react';
import { LoginView } from '../../Views';
import { ReactGA } from "../../Utils/ga4";

const Login = () => {
    React.useEffect(() => {
        ReactGA.sendPageView("Login page");
    }, [])

    return <LoginView />;
};

export default Login;
