import React from 'react'
import UserInfoView from '../../Views/beforeAuth/LoginView/UserInfoView'
import { ReactGA } from "../../Utils/ga4";

const UserInfo = () => {
  React.useEffect(() => {
    ReactGA.sendPageView("Vehicle selection page");
  }, [])
  return <UserInfoView />
}

export default UserInfo