// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.WalletView {
  padding-block: 3rem; }
  .WalletView .WalletView--filter-btn {
    display: flex;
    justify-content: space-between; }
    .WalletView .WalletView--filter-btn h1 {
      font-size: 18px;
      font-weight: 600; }
  .WalletView .WalletView--transaction-history {
    padding-block: 1rem;
    margin-bottom: 8rem; }
`, "",{"version":3,"sources":["webpack://./src/Views/AfterAuth/WalletView/WalletView.scss"],"names":[],"mappings":"AAAA;EACI,mBAAmB,EAAA;EADvB;IAGQ,aAAa;IACb,8BAA8B,EAAA;IAJtC;MAMY,eAAe;MACf,gBAAgB,EAAA;EAP5B;IAWY,mBAAmB;IACnB,mBAAmB,EAAA","sourcesContent":[".WalletView{\n    padding-block:3rem ;\n    .WalletView--filter-btn{\n        display: flex;\n        justify-content: space-between;\n        h1{\n            font-size: 18px;\n            font-weight: 600;\n        }\n    }\n    .WalletView--transaction-history{\n            padding-block: 1rem;\n            margin-bottom: 8rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
