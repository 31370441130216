// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frame-container {
  width: 100%;
  max-width: 360px;
  /* Set a maximum width for the frame */
  margin: 0 auto;
  /* Center the frame horizontally */ }
  @media (max-width: 768px) {
    .frame-container {
      max-width: 100%;
      /* Take full width on medium screens */ } }
`, "",{"version":3,"sources":["webpack://./src/Assets/icons/Frame/frame.scss"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,gBAAgB;EAAE,sCAAA;EAClB,cAAc;EAAE,kCAAA,EAAmC;EAEnD;IALF;MAMI,eAAe;MAAE,sCAAA,EAAuC,EAE3D","sourcesContent":[".frame-container {\n  width: 100%;\n  max-width: 360px; /* Set a maximum width for the frame */\n  margin: 0 auto; /* Center the frame horizontally */\n\n  @media (max-width: 768px) {\n    max-width: 100%; /* Take full width on medium screens */\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
