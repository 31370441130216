import React, { useEffect, useState } from 'react';
import './addvehicleview.scss';
import { useNavigate } from 'react-router-dom';
import { Pathname } from '../../../../Router/Pathname';
import ArrowLeft from '../../../../Assets/icons/ArrowLeft';
import Input from '../../../../Components/Common/Input/Input';
import { Button } from '../../../../Components/Common';
import { useDispatch } from 'react-redux';
import { createVehicle } from '../Redux/actionCreator';
// import CustomSelect from '../../../../Components/Common/CustomSelect/CustomSelect';
import CustomSelect from '../../../../Components/Common/CustomSelect/CustomSelect';
import ModalSucessProgress from '../../../../Components/Common/ModalSucessProgress/ModalSucessProgress';
import { resetCreateVehicle } from '../Redux/reducer';
import Form from '../../../../Components/Common/Form';
import * as Yup from 'yup';

const AddVehiclesView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [inputdata, setinputdata] = useState({
        vehicletype: '',
        vehiclemake: '',
    });
    const [modalOpen, setModalOpen] = useState(false);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const handleValueChange = (fieldName, value) => {
        setinputdata((prevInputData) => ({
            ...prevInputData,
            [fieldName]: value,
        }));
    };
    const payload = {
        vehicle_make: inputdata.vehiclemake,
        vehicle_type: inputdata.vehicletype,
    };

    const handleaddvehicle = async (values) => {
       console.log("asfhiokwyeguitfweuifvweuiotwvuoiefgvow");
        openModal(true);

        const res = await dispatch(createVehicle({
            body:values
        }));
        setTimeout(()=>{
            setModalOpen(false);
            navigate(`${Pathname.MY_VEHICLES}`);
        },2500)    
    };


    const vehicle_type = ['2-Wheeler', '4-Wheeler'];
    const options = vehicle_type.map((item) => ({
        label: item,
        value: item,
    }));

    return (
        <main className="AddVehiclesView">
            <div className="AddVehiclesView--btn">
                <span onClick={() => navigate(`${Pathname.MY_VEHICLES}`)}>
                    <ArrowLeft />
                </span>
                <p>Add Vehicle</p>
            </div>

            <Form  
            handleSubmit={handleaddvehicle}
                        initialValues={{
                           vehicle_type:"",
                           vehicle_make:"",
                        }}
                        validationSchema={schema}
                        >

                             {(formik) => {
                            const {
                                values,
                                errors,
                                setFieldValue,
                                touched,
                            } = formik;
                            return(
            <>
            <div className="AddVehiclesView--input">  
                <div>
                    <CustomSelect
                        optionKey={'value'}
                        options={options}
                        placeholder={'Select Vehicle Type'}
                        handleOptionSelected={(obj) => {
                            setFieldValue('vehicle_type', obj?.value);
                        }}
                        // defaultValue={inputdata.vehicletype}
                        defaultValue={values?.vehicle_type}
                        validate={
                            touched?.vehicle_type &&
                            !errors?.vehicle_type
                        }
                        errorMessage={touched?.vehicle_type && errors?.vehicle_type}
                    />
                </div>
                <div>
                    <Input
                        label={'Enter vehicle make'}
                        // value={inputdata.vehiclemake}
                        value={values?.vehicle_make}
                        fieldName="vehicle_make"
                        onValueChange={(value) => setFieldValue('vehicle_make', value)}
                       errorMessage={touched?.vehicle_make&& errors?.vehicle_make}
                        validate={
                            touched?.vehicle_make &&
                            !errors?.vehicle_make
                        }
          
                    />
                </div>
            </div>
            <div className="AddVehiclesView--add-btn">
                <Button
                    // onClick={handleaddvehicle}
                    // disabled={values?.vehicle_type && values?.vehicle_make ? false : true}
                    className={'AddVehiclesView-btn'}
                    text={'Add Vehicle'}
                    type={'submit'}
                    variant={
                        values?.vehicle_type &&
                        values?.vehicle_make 
                       ? 'primary'
                            : 'disabled_outline'
                    }
                   
                />
                <ModalSucessProgress
                    isOpen={modalOpen}
                    onClose={closeModal}
                    text="Vehicle added"
                />
            </div>
            </>
            )}}
             </Form>
        </main>
    );
};
export default AddVehiclesView;

const schema = Yup.object().shape({
   vehicle_type: Yup.string().trim().required('vehicle type is required'),
   vehicle_make: Yup.string().trim().required('vehicle make is required'),
})
