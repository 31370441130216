import React from 'react';
import './addmoneysuggests.scss';
const AddMoneySuggests = ({amount,setAmount}) => {
    const handleclickAmount=()=>{
        setAmount(amount)
    }
    return (
        <div className="AddMoney--Div">
            <p onClick={handleclickAmount}>+{amount}</p>
        </div>
    );
};

export default AddMoneySuggests;
