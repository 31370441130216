import React, { useEffect, useRef, useState } from 'react';
import './style.scss';
import { Buffer } from 'buffer';
import WarnlingModal from '../WarningModal/WarnlingModal';
import {
    deleteVehicle,
    getVehicle,
    setasdefault,
} from '../../../Views/AfterAuth/MyVehiclesView/Redux/actionCreator';
import { useDispatch, useSelector } from 'react-redux';

const VehicleModal = ({ openVehicles, handleClose, handleset }) => {
    const dispatch = useDispatch();
  
    const token = localStorage.getItem('t_id') || null;
    const parts = token?.split('.');

    // Base64 decode the parts
    const payload =
        parts && parts.length && Buffer.from(parts[1], 'base64').toString('utf8');

    // Parse the JSON in the decoded Header and Payload
    const payloadObj = JSON.parse(payload);

    // Now, you can access the data in the JWT
    let customer_id = payloadObj?.customerId;
    console.log('Payload:', customer_id);

    const [open, setOpen] = useState(false);
    const modalRef = useRef(null);

    const handleopenDelete = () => {
        setOpen(true);
    };
    const handlecloseDelete = () => {
        setOpen(false);
    };

    const handleOutsideClick = (e) => {
        if (modalRef.current && !modalRef.current.contains(e.target)) {
            handleClose();
        }
    };
    useEffect(() => {
        if (openVehicles) {
            document.addEventListener('mousedown', handleOutsideClick);
        } else {
            document.removeEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [openVehicles]);

    if (!openVehicles) {
        return null;
    }

    const handelsetdefault = () => {
        // handleset('default');
        handleClose();
        dispatch(
            setasdefault({
                body: {
                    vehicle_id: openVehicles,
                },
                next: () => {
                    dispatch(
                        getVehicle({
                            id: customer_id,
                        })
                    );
                },
            })
        );
    };

    return (
        <div ref={modalRef} className="modal1">    
            <p onClick={handelsetdefault}>Set as default</p>
            <p onClick={handleopenDelete}>Delete</p>
            <WarnlingModal
                isOpen={open}
                onClose={handlecloseDelete}
                 text="Are you sure you want to delete?"
                handleSubmit={() => {
                    dispatch(deleteVehicle({
                        id:openVehicles,
                        next: () => {
                            setOpen(false);
                            dispatch(
                                getVehicle({
                                    id: customer_id,
                                })
                            );
                        },
                    }));
                    handleClose();
                }}
            />
        </div>
    );
};
export default VehicleModal;
