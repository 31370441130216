import React, { useState } from 'react';
import CloseIcon from '../../../Assets/icons/CloseIcon';
import './style.scss';
import FilterBtn from '../FilterBtn/FilterBtn';
import { useNavigate } from 'react-router-dom';
import { Pathname } from '../../../Router/Pathname';
const FilterTransactions = ({ modalOpen, modalClose }) => {
    const navigate=useNavigate()
    const [active, setActive] = useState("");
    // console.log("🚀 ~ file: FilterTransactions.jsx:7 ~ FilterTransactions ~ active:", active)
    const handleClick = (event) => {
      setActive(event.target.value);
    }
    const handleFilter=()=>{
        if(active){
        //    navigate(`${Pathname.WALLET}`,{state:{active}})
           modalClose()
        }
    }

    const handleOverlayClick = (event) => {
        if (event.target.classList.contains('custom-modal-overlay')) {
            modalClose();
        }
    };

    return (
        <div className="bg-color">

            {modalOpen && (
                <div className="custom-modal-overlay" onClick={handleOverlayClick}>
                    <div className="custom-modal1">  
                        <button className="close-button" onClick={modalClose}>
                            <CloseIcon />
                        </button>
                        <div className="modal-content">
                            <div className="filter-section">
                                <p className="filter-title">Filter Transactions</p>
                                <div className="type-month">
                                    <div className="type">
                                        <p>Type</p>
                                        <div>
                                            <FilterBtn text="Paid" id={'1'} activeStatus={active} handleClick={handleClick}/>
                                            <FilterBtn text="Added" id={'2'} activeStatus={active}  handleClick={handleClick}/>
                                            <FilterBtn text="Refunded" id={'3'} activeStatus={active}  handleClick={handleClick}/>
                                        </div>
                                    </div>
                                    <div className="month">
                                        <p>Month</p>
                                        <div>
                                            <FilterBtn text="Jan’23" id={'4'}activeStatus={active} handleClick={handleClick}/>
                                            <FilterBtn text="Feb’23" id={'5'} activeStatus={active} handleClick={handleClick}/>
                                            <FilterBtn text="March’23" id={'6'} activeStatus={active} handleClick={handleClick}/>
                                            <FilterBtn text="April’23" id={'7'} activeStatus={active} handleClick={handleClick}/>
                                            <FilterBtn text="May’23" id={'8'} activeStatus={active} handleClick={handleClick}/>
                                            <FilterBtn text="Jun’23" id={'9'} activeStatus={active} handleClick={handleClick}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='Btn-filter'>
                                    <div className={active ? 'clear-btn' : ''}>
                                        <button>Clear all</button>
                                    </div>
                                    <div className={active ? 'apply-btn' : 'inactivebtn'}>
                                        <button onClick={handleFilter}>Apply filters</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FilterTransactions;
