import React from 'react';
import './EmspView.scss';
import InvoiceDownload from '../../../Components/Common/InvoiceDownload/InvoiceDownload';

const EmspView = () => {

    return (
        <section className="EmspView">
            <h1>hello Home</h1>
            <InvoiceDownload/>
        </section>
    );
};

export default EmspView;
