import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from '../Modal/Modal';
import DividerCard from '../DividerCard/DividerCard';
import dayjs from 'dayjs';
import Button from '../Button/Button';
import './RefundInvoiceDownload.scss';
import { downloadRefundInvoice } from '../../../Views/AfterAuth/WalletView/redux/walletSlice';

const RefundInvoiceDownload = ({
    openModal,
    invoiceClose,
    ocppTransactionId,
    invoiceId,
}) => {
    const { refundInvoiceDetails } = useSelector((store) => store.wallet);

    const dispatch = useDispatch();

    const handleDownloadInvoice = () => {      
        dispatch(
            downloadRefundInvoice({
                id:refundInvoiceDetails?.id,
                invoice_id: refundInvoiceDetails?.invoice_id,
            })
        );
    };
    return (
        <div>
            <Modal
                onClose={invoiceClose}
                isOpen={openModal}
                closeButtonClass="wallet-modal-close"
            >
                <div className="DownloadInvoice">
                    <p className="invoice-tag">Invoice</p>
                    <div className="invoice">
                        <div className="card status-name">
                            <p>Invoice Date</p>
                            <p>
                                {dayjs(refundInvoiceDetails?.createdAt).format(
                                    'DD MMMM YYYY'
                                )}
                            </p>
                        </div>
                        <div className="card station-rate">
                            <p>User Name</p>
                            <p>{refundInvoiceDetails?.customer?.user_name}</p>
                        </div>
                    </div>
                    <div className="card withchild">
                        <DividerCard>
                            <div className="left">
                                <p>Invoice Type</p>
                                <p>Payment Mode</p>
                                <p>Invoice Number</p>
                            </div>
                            <div className="right">
                                <p>{refundInvoiceDetails?.invoice_type}</p>
                                <p>{refundInvoiceDetails?.mode}</p>
                                <p>{refundInvoiceDetails?.invoice_id}</p>
                            </div>
                        </DividerCard>
                    </div>
                    <div className="card withchild">
                        <DividerCard>
                            <div className="left">
                                <p>Transfer fee</p>
                                <p>GST</p>
                                <p>Total Tax</p>
                            </div>
                            <div className="right">
                                <p>{'2%'}</p>
                                <p>{'0.36%'}</p>
                                <p>{'2.36%'}</p>
                            </div>
                        </DividerCard>
                    </div>
                    <div className="card withchild">
                        <DividerCard>
                            <div className="left">
                                <p>Initial Balance</p>
                                <p>Total Deductions</p>
                                <p>Refund Amount</p>
                                <p>Total Balance in wallet</p>
                            </div>
                            <div className="right">
                                <p>₹{refundInvoiceDetails?.previous_balance}</p>
                                <p>₹{refundInvoiceDetails?.tax_val}</p>
                                <p>₹{refundInvoiceDetails?.amount}</p>
                                <p>₹{refundInvoiceDetails?.current_balance}</p>
                            </div>
                        </DividerCard>
                    </div>
                    <div className="download--btn">
                        <Button
                            text={'Download Invoice'}
                            className={'download-btn'}
                              onClick={handleDownloadInvoice}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default RefundInvoiceDownload;
