import React from 'react';
import HomeView from '../../Views/AfterAuth/HomeView/HomeView';
import { ReactGA } from "../../Utils/ga4";

const Home = () => {
    React.useEffect(() => {
        ReactGA.sendPageView("Home page");
    }, [])
    return <HomeView />;
};

export default Home;
