// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomeHeader--bg {
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, #000 0%, #00AA74 35.89%); }

.logo {
  text-align: center;
  margin-top: -28rem;
  position: relative;
  z-index: 2; }

.HomeHeader--content {
  position: relative;
  z-index: 1;
  margin-top: -9.5rem;
  padding-top: 15rem;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  background-color: white; }

@media screen and (max-width: 360px) {
  .responsive--logo {
    margin-top: -34rem; } }
`, "",{"version":3,"sources":["webpack://./src/Components/Layout/HomeHeader/style.scss"],"names":[],"mappings":"AACA;EACI,aAAa;EACb,uBAAuB;EACvB,4DAA4D,EAAA;;AAEhE;EACI,kBAAkB;EAClB,kBAAkB;EAClB,kBAAkB;EAClB,UAAU,EAAA;;AAEd;EACI,kBAAkB;EAClB,UAAU;EACV,mBAAmB;EACnB,kBAAkB;EAClB,4BAA4B;EAC5B,6BAA6B;EAC7B,uBAAuB,EAAA;;AAI3B;EACI;IACI,kBAAkB,EAAA,EACrB","sourcesContent":["@import '../../../Styles/sass/variables';\n.HomeHeader--bg{\n    display: flex;\n    justify-content: center;\n    background: linear-gradient(180deg, #000 0%, #00AA74 35.89%);\n}\n.logo{\n    text-align: center;\n    margin-top: -28rem;\n    position: relative;\n    z-index: 2;\n}\n.HomeHeader--content{\n    position: relative;\n    z-index: 1;\n    margin-top: -9.5rem;\n    padding-top: 15rem;\n    border-top-left-radius: 24px;\n    border-top-right-radius: 24px;\n    background-color: white;\n    // height: 70vh;\n}\n\n@media screen and (max-width: 360px) {\n    .responsive--logo {\n        margin-top: -34rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
