import React, { useEffect, useState } from 'react';
import { Button } from '../../../Components/Common';
import './index.scss';
import Modal from '../../../Components/Common/Modal/Modal';
import Wallet2 from '../../../Assets/icons/Wallet2';
import BasicCard from '../../../Components/HomeCards/BasicCard/BasicCard';
import ModernCard from '../../../Components/HomeCards/ModernCard/ModernCard';
import PluginCard from '../../../Components/HomeCards/PluginCard/PluginCard';
import Plugin from '../../../Assets/icons/Plugin';
import TickIcon from '../../../Assets/icons/TickIcon';
import Flash from '../../../Assets/icons/Flash';
import Fan from '../../../Assets/icons/Fan';
import Tree from '../../../Assets/icons/Tree';
import CircularProgress from '../../../Components/Common/CircularProgressBar/CircularProgressBar';
import FilterTransactions from '../../../Components/Common/FilterTransactions/FilterTransactions';
import MyTransactioncard from '../../../Components/Common/MyTransactionCard/MyTransactioncard';
import StatusComponent from '../../../Components/Common/StatusComponent/StatusComponent';
import MyVehiclesCard from '../../../Components/Common/MyVehiclesCard/MyVehiclesCard';
import Input from '../../../Components/Common/Input/Input';
import ProgressBar from '../../../Components/Common/ProgressBar/ProgressBar';
import HorizontalProgressBar from '../../../Components/Common/HorizontalProgressBar/HorizontalProgressBar';

export const Components = () => {
    //for button
    const handleClick = () => {
        alert('Button clicked!');
    };

    // for modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => {
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };
    // cards
    // basic card
    const [selectedCard, setSelectedCard] = useState(null);

    const handleCardSelect = (cardId) => {
        setSelectedCard(cardId);
    };

    //   progress bar
    const [count, setCount] = useState(0);
    const [unit, setUnit] = useState(0);

    useEffect(() => {
        const time = setInterval(() => {
            setCount((prev) => prev + 1);
        }, 1000);
        return () => clearInterval(time);
    }, []);

    useEffect(() => {
        const time = setInterval(() => {
            setUnit((prev) => prev + 1);
        }, 1000);
        return () => clearInterval(time);
    }, []);

    // filter transaction
    const [openModal1, setOpenModal1] = useState(false);
    const handleClose = () => {
        setOpenModal1(false);
    };
    const handleopen = () => {
        setOpenModal1(true);
    };

    // input tag
    const [inputData, setInputData] = useState({
        field1: '',
        field2: '',
        // Add more fields as needed
    });

    const handleValueChange = (fieldName, value) => {
        setInputData((prevInputData) => ({
            ...prevInputData,
            [fieldName]: value,
        }));
    };

    return (
        <React.Fragment>
            {/* ------------------------Buttons-------------------------- */}
            <h1>Buttons</h1>
            <div className="ButtonContainer">
                {/* Buttons with function */}
                <Button text="Primary Button" onClick={handleClick} variant="primary" />

                {/* for adding icon */}
                <Button
                    text="Secondary Button"
                    variant="secondary"
                    // icon={<CarIcon/>}
                />

                {/* for glowing pass glow = true */}
                <Button text="Glowing Primary Button" glow={true} variant="primary" />
                {/* disabled button */}
                <Button text="Disable Button" variant={'disabled'} />

                {/* for creating custom button with custom style */}
                <Button text={'Custom styling'} className={'custom'} />
            </div>
            <hr />
            <h1>Modal</h1>
            {/* Modal */}
            <div>
                <Button text="Open Modal" className={'modalButton'} onClick={openModal} />
                <Modal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    title={<Wallet2 />}
                    modalClass="custom-modal"
                    overlayClass="custom-overlay"
                    closeButtonClass="custom-close-button"
                >
                    <div className="modalClass">
                        <p className="funds">Insufficient Funds</p>
                        <p className="fundInfo">
                            Please add money in wallet to charge your vehicle
                        </p>
                        <div>
                            <Button className={'addWallet'} text="Add money to wallet" />
                        </div>
                    </div>
                </Modal>
            </div>
            <hr />
            {/* ----------------Cards------------------- */}
            <h1>Cards</h1>
            <div>
                <h2>Basic Card </h2>
                <BasicCard
                    id="card1"
                    icon={<Flash />}
                    heading1="Card 1 Heading"
                    heading2="Card 1 Subheading"
                    isSelected={selectedCard === 'card1'}
                    onSelect={handleCardSelect}
                />
                <BasicCard
                    id="card2"
                    icon={<Flash />}
                    heading1="Card 2 Heading"
                    heading2="Card 3 Subheading"
                    isSelected={selectedCard === 'card2'}
                    onSelect={handleCardSelect}
                />
            </div>
            <div>
                <h2>Modern Card</h2>
                <ModernCard
                    heading="Total Units Consumed"
                    subheading="1234"
                    icon={<Fan />}
                    className="custom-class"
                />
                <ModernCard
                    heading="Carbon Emissions Saved"
                    subheading="Card Subheading"
                    icon={<Tree />}
                    className="modern-custom-class"
                />
            </div>
            <div>
                <h3>Plugin Card</h3>
                <PluginCard
                    subheading={'Pluged-in the charger'}
                    icon2={<Plugin />}
                    icon1={<TickIcon />}
                />
            </div>
            <hr />
            {/* Circular Loader */}
            <div>
                <h1>Circular Progress Bar</h1>
                <CircularProgress percentage={count} />
                <HorizontalProgressBar unitsConsumed={unit} totalUnits={35} />
            </div>
            {/* Drawer for filter tranasaction */}
            <div>
                <h1>Drawer for filter transaction </h1>
                <button onClick={handleopen}>open filter</button>
                <FilterTransactions modalOpen={openModal1} closeModal={handleClose} />
            </div>
            <div>
                <h1>My transaction Card</h1>
                <MyTransactioncard amount="-321" />
                <MyTransactioncard amount="321" />
            </div>
            <div>
                <h1>status Components</h1>
                <StatusComponent warning="Warning Showing" />
                <StatusComponent sucess="Success Message" />
            </div>
            <div style={{ background: 'white' }}>
                <h1>My Vehicles</h1>
                <MyVehiclesCard
                    vehicle={<Wallet2 />}
                    type={'Auto'}
                    details={'4 wheeler'}
                />
            </div>

            <div style={{ padding: '20px 0' }}>
                <h1>Input</h1>
                <div>
                    <Input
                        label="Field 1"
                        onValueChange={(value) => handleValueChange('field1', value)}
                    />
                </div>
            </div>

            <div>
                <h1>Progress bar</h1>
                <ProgressBar value={'00:00'} />
            </div>
            <div>
                <Badges props={'success'} />
            </div>
        </React.Fragment>
    );
};

export default Components;
