import React from 'react';
import './Badges.scss';
const Badges = ({ props, badge = true, style }) => {
    const getBadgeClass = () => {
        const classMap = {
            Faulted: 'faulted',
            Inoperative: 'faulted',
            Available: 'Available',
            pending: 'suspended',
            Preparing: 'suspended',
            Unavailable: 'unavailable',
            Finishing:'suspended'
        };

        return `Badges ${classMap[props] || ''}`;
    };

    return (
        <div className={getBadgeClass()} style={style}>
            {badge ? <span className="badge-dot"></span> : null}
            <span className="badge-text">{props}</span>
        </div>
    );
};

export default Badges;
