import React, { useEffect, useState } from 'react';
import './startcharging.scss';
import ArrowLeft from '../../../../Assets/icons/ArrowLeft';
import { useLocation, useNavigate } from 'react-router-dom';
import CircularProgress from '../../../../Components/Common/CircularProgressBar/CircularProgressBar';
import BasicCard from '../../../../Components/HomeCards/BasicCard/BasicCard';
import Flash from '../../../../Assets/icons/Flash';
import { Button } from '../../../../Components/Common';
import { Pathname } from '../../../../Router/Pathname';
import ModalSucessProgress from '../../../../Components/Common/ModalSucessProgress/ModalSucessProgress';
import Modal from '../../../../Components/Common/Modal/Modal';
import WarnlingModal from '../../../../Components/Common/WarningModal/WarnlingModal';
import ClockWithBg from '../../../../Assets/icons/ClockWithBg';
import FlashWithBg from '../../../../Assets/icons/FlashWithBg';
import { useSelector, useDispatch } from 'react-redux';
import {
    continousChargingStatus,
    getHomeDetails,
    setChargingValue,
    setStopChargingStationStatus,
    stopCharging,
} from '../../HomeView/redux/homeSlice';
import ProgressBar from '../../../../Components/Common/ProgressBar/ProgressBar';
import { ToastContainer, toast } from 'react-toastify';
import InvoiceDownload from '../../../../Components/Common/InvoiceDownload/InvoiceDownload';
import {
    getChargingInvoice,
    resetWalletTransactions,
} from '../../WalletView/redux/walletSlice';
import PreLoader from '../../../../Assets/icons/PreLoader/PreLoader';
import HorizontalProgressBar from '../../../../Components/Common/HorizontalProgressBar/HorizontalProgressBar';
import BatteryImg from '../../../../Assets/pngIcon/battery.png';
import { ReactGA } from "../../../../Utils/ga4";

const StartCharging = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [count, setCount] = useState(95);
    const [pause, setPause] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [isDisplayInvoice, setIsDisplayInvoice] = useState(false);
    const [transactionId, setTranactionId] = useState('');
    const [pointId, setPointId] = useState('');

    const [showPollingModal, setShowPollingModal] = useState(false);

    const [isChargingInitiated, setIsChargingInitiated] = useState(false);

    const [stopChargingDetails, setStopChargingDetails] = useState({
        isSuccess: false,
        message: '',
    });

    const [stopStatus, setStopStatus] = useState(null);

    const { invoiceDetails } = useSelector((store) => store.wallet);
    const { home, energy } = useSelector((store) => store);
    const { startChargingInfo, chargingValues, walletBalance, stopChargingStatus } = home;

    const { isZeroTarrif } = energy;
    const { homeInfo } = useSelector((store) => store.home);
    const chargingData = chargingValues?.payload;
    const dispatch = useDispatch();

    const ocpp_transaction_id = homeInfo?.payload?.last_sesh?.ocpp_transaction_id;
    const charging_point_id =
        homeInfo?.payload?.last_sesh?.chargingPoint?.charging_point_id;

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setPause(false);
    };

    const resetStopChargingStationState = () => {
        setStopChargingDetails({
            isSuccess: false,
            message: '',
        });
    };

    useEffect(() => {
        setTranactionId(startChargingInfo?.payload?.transactionId || ocpp_transaction_id);
        setPointId(
            startChargingInfo?.payload?.cpData?.charging_point_id || charging_point_id
        );
    }, [startChargingInfo, homeInfo]);

    // useEffect(() => {
    //     const charging = setInterval(() => {
    //         if (!pause) {
    //             setCount((pre) => pre + 1);
    //         }
    //     }, 2000);
    //     return () => clearInterval(charging);
    // }, [pause]);

    const handleStopModal = () => {
        if (count <= 99) {
            setModalOpen(true);
            setPause(true);
        } else {
            console.log('grater 100');
        }
    };

    const handleStopCharging = () => {
        dispatch(
            stopCharging({ transaction_id: transactionId, charging_point_id: pointId })
        );
        setStopStatus('stopped');

        // console.log('data payload', data.payload.success);

        // if (data?.payload?.success) {
        //     navigate('/home');
        // }
    };

    const handleCloseInvoice = () => {
        dispatch(setChargingValue(null));
        dispatch(setStopChargingStationStatus(null));
        dispatch(resetWalletTransactions());
        resetStopChargingStationState();
        setIsDisplayInvoice(false);
        navigate('/home');
    };

    let time;
    let invoiceInterval;

    useEffect(() => {
        ReactGA.sendPageView("Charging page");
    }, [])

    useEffect(() => {
        dispatch(getHomeDetails());

        if (startChargingInfo?.success || transactionId) {
            time = setInterval(() => {
                dispatch(continousChargingStatus(transactionId));
            }, 5000);
        }

        return () => clearInterval(time);
    }, [startChargingInfo, transactionId]);

    useEffect(() => {
        if (
            chargingValues?.payload?.chargingStatus?.status?.toLowerCase() ===
            'stopped' &&
            stopChargingStatus === null
        ) {
            toast(`Charging Stopped`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                style: {
                    fontSize: '16px',
                },
            });
            setTimeout(() => {
                navigate('/home', {
                    state: { transactionId: transactionId },
                });
                dispatch(setChargingValue(null));
            }, 2000);
        }
    }, [chargingValues]);

    useEffect(() => {
        if (startChargingInfo) {
            setIsChargingInitiated(true);

            setTimeout(() => {
                setIsChargingInitiated(false);
            }, 3000);
        }
    }, [startChargingInfo]);

    useEffect(() => {
        if (stopChargingStatus) {
            const status =
                stopChargingStatus?.payload?.remoteStopTransactionResp?.status?.toLowerCase();

            if (status === 'accepted') {
                setStopChargingDetails({
                    ...stopChargingDetails,
                    isSuccess: true,
                    message: stopChargingStatus?.message,
                });

                setTimeout(() => {
                    resetStopChargingStationState();
                    setShowPollingModal(true);
                    dispatch(getChargingInvoice({ id: transactionId }));
                }, 2000);
            } else {
                resetStopChargingStationState();
            }
        }
    }, [stopChargingStatus]);

    useEffect(() => {
        if (stopStatus === 'stopped' && invoiceDetails?.success === true) {
            console.log('suceeess ==> ');
            resetStopChargingStationState();
            setShowPollingModal(false);
            setIsDisplayInvoice(true);
        }
    }, [invoiceDetails]);

    useEffect(() => {
        clearInterval(time);
        if (showPollingModal && invoiceDetails?.success === false) {
            console.log('pollng ===>', invoiceDetails);

            invoiceInterval = setInterval(() => {
                console.log('pollng STARTS===>');
                // dispatch(resetWalletTransactions());

                dispatch(getChargingInvoice({ id: transactionId }));
            }, 3000);
            // Clean up the interval when the component unmounts
        } else if (invoiceDetails?.success === true) {
            console.log(' ===========> invoice success');

            clearInterval(invoiceInterval);
        }

        return () => {
            clearInterval(invoiceInterval);
        };
    }, [invoiceDetails]);

    console.log('test', startChargingInfo?.payload?.transactionId || ocpp_transaction_id);

    return (
        <div className="StartCharging">
            <section>
                <div className="StartCharging--Select">
                    <span
                        onClick={() => {
                            startChargingInfo?.success || transactionId
                                ? navigate(`${Pathname.HOME}`)
                                : navigate(`${Pathname.START_CHARGING}/intiate-charging`);
                        }}
                    >
                        <ArrowLeft />
                    </span>
                    <p>Charging in progress</p>
                </div>
            </section>

            {/* Refactor start */}
            <div className="contentContainer">
                {chargingValues?.payload?.chargingStatus?.current_soc ? (
                    <div style={{ marginBottom: '1rem' }}>
                        <section
                            style={{
                                borderRadius: '50%',
                                padding: '35px',
                                border: '1px solid #00AA7470',
                            }}
                        >
                            <div
                                style={{
                                    border: '2px solid #00AA7470',
                                    borderRadius: '50%',
                                    padding: '12px',
                                }}
                                className="charging-progress"
                            >
                                <CircularProgress
                                    percentage={
                                        chargingValues?.payload?.chargingStatus
                                            ?.current_soc
                                            ? chargingValues?.payload?.chargingStatus
                                                ?.current_soc
                                            : '0'
                                    }
                                />
                            </div>
                        </section>
                    </div>
                ) : (
                    <div
                        style={{ marginBottom: '1rem' }}
                        className="StartCharging--Container"
                    >
                        <div className="StartCharging--ImgContainer">
                            <div className="StartCharging--OuterCircle"></div>
                            {/* <div className="StartCharging--InnerCircle"></div> */}
                            <img src={BatteryImg} className="StartCharging--BatteryImg" />
                        </div>
                        <HorizontalProgressBar
                            unitsConsumed={
                                chargingValues?.payload?.chargingStatus?.units_consumed
                                    ? `${parseFloat(
                                        chargingValues?.payload?.chargingStatus
                                            ?.units_consumed
                                    ).toFixed(2)}`
                                    : 0
                            }
                            totalUnits={
                                chargingValues?.payload?.chargingStatus?.units_to_deliver
                                    ? `${parseFloat(
                                        chargingValues?.payload?.chargingStatus
                                            ?.units_to_deliver
                                    ).toFixed(2)}`
                                    : 0
                            }
                            rfid_tag={chargingData?.chargingStatus?.rfid_tag}
                        />
                    </div>
                )}

                <div>
                    <section>
                        <div className="StartCharging--card">
                            <div>
                                <BasicCard
                                    icon={<ClockWithBg />}
                                    heading1={
                                        chargingData?.chargingStatus?.rfid_tag
                                            ? 'Time Elapsed'
                                            : chargingValues?.payload?.formattedTime
                                                ? 'Time Remaining'
                                                : 'Time'
                                    }
                                    heading2={
                                        chargingData?.timeElapsed
                                            ? chargingData?.chargingStatus
                                                ?.charge_duration
                                            : chargingValues?.payload?.formattedTime
                                                ? chargingValues?.payload?.formattedTime
                                                : '-'
                                    }
                                />
                                <BasicCard
                                    icon={<FlashWithBg />}
                                    heading1="Charging Speed"
                                    heading2={
                                        chargingValues?.payload?.chargingStatus?.charging_speed > 999
                                            ? `${parseFloat(chargingValues?.payload?.chargingStatus?.charging_speed / 1000).toFixed(2)} kW`
                                            : (parseFloat(chargingValues?.payload?.chargingStatus
                                                ?.charging_speed)
                                                ? `${parseFloat(chargingValues?.payload?.chargingStatus
                                                    ?.charging_speed).toFixed(0)} W`
                                                : '-')
                                    }
                                />
                                <BasicCard
                                    icon={<FlashWithBg />}
                                    heading1="Units Consumed"
                                    heading2={
                                        chargingValues?.payload?.chargingStatus
                                            ?.units_consumed
                                            ? `${parseFloat(
                                                chargingValues?.payload?.chargingStatus
                                                    ?.units_consumed
                                            ).toFixed(2)} kW`
                                            : '-'
                                    }
                                />
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="StartCharging--btn">
                            <Button
                                className={'StartCharging-button'}
                                text={'Stop Charging'}
                                onClick={handleStopModal}
                            />
                        </div>
                    </section>
                </div>
            </div>
            {/* {count === 100 && (
                <ModalSucessProgress
                    isOpen={openModal}
                    onClose={closeModal}
                    text={'Charging Complete'}
                />
            )} */}

            {isChargingInitiated && location.state && (
                <ModalSucessProgress
                    isOpen={isChargingInitiated}
                    text={'Charging Initiated'}
                />
            )}

            {modalOpen && (
                <WarnlingModal
                    isOpen={modalOpen}
                    onClose={closeModal}
                    text="Are you sure you want to stop
                charging your vehicle?"
                    handleSubmit={handleStopCharging}
                />
            )}

            {stopChargingDetails?.isSuccess && (
                <ModalSucessProgress
                    isOpen={stopChargingDetails?.isSuccess}
                    text={stopChargingDetails?.message}
                />
            )}

            {showPollingModal && (
                <Modal isOpen={showPollingModal} disableClose={true}>
                    <div className="StartCharging--Polling">
                        <PreLoader />
                        Please wait while fetching invoice
                    </div>
                </Modal>
            )}

            {isDisplayInvoice && Boolean(invoiceDetails) && (
                <InvoiceDownload
                    openModal={isDisplayInvoice}
                    invoiceClose={handleCloseInvoice}
                    ocppTransactionId={transactionId}
                    invoiceId={''}
                />
            )}
            <ToastContainer />
        </div>
    );
};
export default StartCharging;
