import React, { useEffect, useState } from 'react';
import './userInfoView.scss';
import Input from '../../../Components/Common/Input/Input';
import Button from '../../../Components/Common/Button/Button';
import SelectableCard from './SelectedCard/SelectableCard';
import Scooter from '../../../Assets/scooter.png';
import Sedan from '../../../Assets/ElectricSedan.png';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from './redux/actionCreator';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { getHomeDetails } from '../../AfterAuth/HomeView/redux/homeSlice';

const UserInfoView = () => {
    const cqrScannedURL = sessionStorage.getItem('connector_id');
    const [inputData, setInputData] = useState({
        user_name: '',
        vehicle_type: '',
    });
    const { otp } = useSelector((store) => store);
    const { error, loading, otpInfo, otpVerification } = otp;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleValueChange = (fieldName, value) => {
        setInputData((prevInputData) => ({
            ...prevInputData,
            [fieldName]: value,
        }));
    };

    // useEffect(async () => {
    //     let data = await dispatch(getHomeDetails());
    //     if (data?.payload?.payload?.is_onboarded) {
    //         navigate('/home');
    //     } else if (otpVerification?.payload?.customer?.is_onboarded) {
    //         navigate('/home');
    //     }
    // }, []);
    // console.log('inputData', inputData?.user_name);

    const handleVehicleSelection = (vehicleType) => {
        handleValueChange('vehicle_type', vehicleType);
    };
    // console.log(inputData);

    const handleSubmit = async () => {
        const res = await dispatch(updateUser(inputData));
        //   console.log("res--",res)
        if (res?.payload?.success) {
            toast.success(`${res?.payload?.message}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                style: {
                    fontSize: '16px',
                },
            });
            cqrScannedURL
                ? navigate(
                      `/start-charging/select-connection?connector_id=${cqrScannedURL}`
                  )
                : navigate('/home');
        } else {
            toast.error(`${res?.payload?.message}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                style: {
                    fontSize: '16px',
                },
            });
        }
    };

    const isButtonDisabled = !inputData.user_name.trim() || !inputData.vehicle_type;
    return (
        <div className="UserInfoView">
            <div className="UserInfoView-container">
                <div>
                    <div className="UserInfoView-heading">
                        <p>Hi there! What should we call you?</p>
                    </div>
                    <div>
                        <Input
                            label="Enter full name"
                            onValueChange={(value) =>
                                handleValueChange('user_name', value)
                            }
                        />
                    </div>
                    <div className="UserInfoView-text">
                        <p>Select your vehicle type</p>
                        <div>
                            <div className="vehicle-card-container">
                                <div>
                                    <SelectableCard
                                        title="2-Wheeler"
                                        onSelect={handleVehicleSelection}
                                        selected={inputData.vehicle_type === '2-Wheeler'}
                                        image={Scooter}
                                    />
                                </div>
                                <div>
                                    <SelectableCard
                                        title="4-Wheeler"
                                        onSelect={handleVehicleSelection}
                                        selected={inputData.vehicle_type === '4-Wheeler'}
                                        image={Sedan}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="UserInfoView-btn">
                    <Button
                        onClick={handleSubmit}
                        text="Continue"
                        disabled={isButtonDisabled}
                    />
                </div>
            </div>
            <ToastContainer />
        </div>
    );
};

export default UserInfoView;
