import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Store } from './Redux/Store';

import App from './App.jsx';
import { register } from './service-worker'; // Import the register function from the service worker file

ReactDOM.render(
    <Provider store={Store}>
        <App />
    </Provider>,
    document.getElementById('root')
);
register();
