import React from 'react';
import './style.scss';
export default function FilterBtn({ text, handleClick, activeStatus, id }) {
    const handleButtonClick = () => {
        handleClick(id);
    };
    return (
        <>
            <button
                id={id}
                value={text}
                className={activeStatus ? 'filter-btn active' : 'filter-btn'}
                onClick={handleButtonClick}
            >
                {text}
            </button>
        </>
    );
}
