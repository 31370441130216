import React from 'react';
import './LoginLayout.scss';
import Header from '../../Components/Layout/Header/Header';
import Footer from '../../Components/Layout/Footer/Footer';

const LoginLayout = (props) => {
    const { children } = props;
    return (
        <main className="LoginLayout">
            <Header />
            <section>{children}</section>
            <Footer />
        </main>
    );
};

export default LoginLayout;
