// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomeView--Header {
  position: relative;
  z-index: 1;
  margin-top: -12rem;
  padding-top: 13rem;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  background-color: white;
  padding-inline: 2rem;
  height: 75vh; }
  .HomeView--Header .HomeView--profileName {
    font-size: 2.5rem;
    margin: 1.5rem 1rem; }
  .HomeView--Header .HomeView--Card {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    margin-bottom: 2rem; }

.HomeView {
  margin-bottom: 14rem; }

@media screen and (max-width: 360px) {
  .responsive--homeView {
    margin-bottom: 12rem; }
  .responsive--header {
    margin-top: -16rem; } }
`, "",{"version":3,"sources":["webpack://./src/Views/AfterAuth/HomeView/HomeView.scss"],"names":[],"mappings":"AACA;EACI,kBAAkB;EAClB,UAAU;EACV,kBAAkB;EAClB,kBAAkB;EAClB,4BAA4B;EAC5B,6BAA6B;EAC7B,uBAAuB;EACvB,oBAAoB;EACpB,YAAY,EAAA;EAThB;IAYQ,iBAAiB;IACjB,mBAAmB,EAAA;EAb3B;IAiBQ,aAAa;IACb,SAAS;IACT,8BAA8B;IAC9B,mBAAmB,EAAA;;AAI3B;EACI,oBAAoB,EAAA;;AAGxB;EACI;IACI,oBAAoB,EAAA;EAGxB;IACI,kBAAkB,EAAA,EAErB","sourcesContent":["@import '../../../Styles/sass/variables';\n.HomeView--Header {\n    position: relative;\n    z-index: 1;\n    margin-top: -12rem;\n    padding-top: 13rem;\n    border-top-left-radius: 24px;\n    border-top-right-radius: 24px;\n    background-color: white;\n    padding-inline: 2rem;\n    height: 75vh;\n\n    .HomeView--profileName {\n        font-size: 2.5rem;\n        margin: 1.5rem 1rem;\n    }\n\n    .HomeView--Card{\n        display: flex;\n        gap: 2rem;\n        justify-content: space-between;\n        margin-bottom: 2rem;\n    }\n}\n\n.HomeView{\n    margin-bottom: 14rem;\n}\n\n@media screen and (max-width: 360px) {\n    .responsive--homeView {\n        margin-bottom: 12rem;\n    }\n\n    .responsive--header {\n        margin-top: -16rem;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
