import React from 'react'
import './dividercard.scss'
export default function DividerCard(props) {
  return (
    <div>
        <div className="maincard">
            <div className="left-card"> {props.children[0]}</div>
            <div className="right-card"> {props.children[1]}</div>
        </div>
    </div>
  )
}
