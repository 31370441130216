import React from 'react'

const QRIcon = () => {
    return (
        <svg
            width="96"
            height="96"
            viewBox="0 0 96 96"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_5105_1842)">
                <circle cx="48" cy="46" r="32" fill="url(#paint0_linear_5105_1842)" />
            </g>
            <path
                d="M34.6665 41.9998V38.6665C34.6665 35.3465 37.3465 32.6665 40.6665 32.6665H43.9998"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M52 32.6665H55.3333C58.6533 32.6665 61.3333 35.3465 61.3333 38.6665V41.9998"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M61.3335 51.3335V53.3335C61.3335 56.6535 58.6535 59.3335 55.3335 59.3335H53.3335"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M43.9998 59.3333H40.6665C37.3465 59.3333 34.6665 56.6533 34.6665 53.3333V50"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M46.0002 39.3335V42.0002C46.0002 43.3335 45.3335 44.0002 44.0002 44.0002H41.3335C40.0002 44.0002 39.3335 43.3335 39.3335 42.0002V39.3335C39.3335 38.0002 40.0002 37.3335 41.3335 37.3335H44.0002C45.3335 37.3335 46.0002 38.0002 46.0002 39.3335Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M56.6667 39.3335V42.0002C56.6667 43.3335 56 44.0002 54.6667 44.0002H52C50.6667 44.0002 50 43.3335 50 42.0002V39.3335C50 38.0002 50.6667 37.3335 52 37.3335H54.6667C56 37.3335 56.6667 38.0002 56.6667 39.3335Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M46.0002 50V52.6667C46.0002 54 45.3335 54.6667 44.0002 54.6667H41.3335C40.0002 54.6667 39.3335 54 39.3335 52.6667V50C39.3335 48.6667 40.0002 48 41.3335 48H44.0002C45.3335 48 46.0002 48.6667 46.0002 50Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M56.6667 50V52.6667C56.6667 54 56 54.6667 54.6667 54.6667H52C50.6667 54.6667 50 54 50 52.6667V50C50 48.6667 50.6667 48 52 48H54.6667C56 48 56.6667 48.6667 56.6667 50Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <filter
                    id="filter0_d_5105_1842"
                    x="0"
                    y="0"
                    width="96"
                    height="96"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="8" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0.666667 0 0 0 0 0.454902 0 0 0 0.4 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_5105_1842"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_5105_1842"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_5105_1842"
                    x1="16"
                    y1="18"
                    x2="81.6685"
                    y2="19.8854"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00AA74" />
                    <stop offset="1" stopColor="#0BD393" />
                </linearGradient>
            </defs>
        </svg>
    );
}

export default QRIcon