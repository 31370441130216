import React from 'react';

const FlashWithBg = () => {
    return (
        <div>
            <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="40" height="40" rx="8" fill="#E6F7EF" />
                <path
                    d="M17.7664 21.0666H20.3414V27.0666C20.3414 27.95 21.4414 28.3666 22.0248 27.7L28.3331 20.5333C28.8831 19.9083 28.4414 18.9333 27.6081 18.9333H25.0331V12.9333C25.0331 12.05 23.9331 11.6333 23.3498 12.3L17.0415 19.4666C16.4998 20.0916 16.9414 21.0666 17.7664 21.0666Z"
                    stroke="#00AA74"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.0833 13.3333H11.25"
                    stroke="#00AA74"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.25 26.6667H11.25"
                    stroke="#00AA74"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M13.75 20H11.25"
                    stroke="#00AA74"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default FlashWithBg;
