import React from 'react';
import { Navigate } from 'react-router-dom';
import { Pathname } from '../Pathname';

const ProtectedRoute = (props) => {
    const { children, isProtected } = props;
    const token = localStorage.getItem('t_id');
    const isLoggedIn = !!token;

    if (isLoggedIn && Pathname.LOGIN === window.location.pathname) {
        return <Navigate to={Pathname.HOME} />;
    }

    return !isLoggedIn && isProtected ? (
        <Navigate to={Pathname.LOGIN} />
    ) : (
        <main>{children}</main>
    );
};

export default ProtectedRoute;
