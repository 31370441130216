import React from 'react'

const TickIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99984 1.66675C5.40817 1.66675 1.6665 5.40841 1.6665 10.0001C1.6665 14.5917 5.40817 18.3334 9.99984 18.3334C14.5915 18.3334 18.3332 14.5917 18.3332 10.0001C18.3332 5.40841 14.5915 1.66675 9.99984 1.66675ZM13.9832 8.08342L9.25817 12.8084C9.1415 12.9251 8.98317 12.9917 8.8165 12.9917C8.64984 12.9917 8.4915 12.9251 8.37484 12.8084L6.0165 10.4501C5.77484 10.2084 5.77484 9.80841 6.0165 9.56675C6.25817 9.32508 6.65817 9.32508 6.89984 9.56675L8.8165 11.4834L13.0998 7.20008C13.3415 6.95842 13.7415 6.95842 13.9832 7.20008C14.2248 7.44175 14.2248 7.83342 13.9832 8.08342Z"
        fill="#00AA74"
      />
    </svg>
  );
}

export default TickIcon