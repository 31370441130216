import React from 'react';
import './style.scss';
import LargeLogo from '../../../Assets/icons/LargeLogo';
import HomeHeaderBg from '../../../Assets/icons/HomeHeaderBg';
const HomeHeader = () => {
    return (
        <>
            <div className="HomeHeader--bg">
                <HomeHeaderBg />
            </div>
            <div className="logo  responsive--logo">
                <LargeLogo />
            </div>
        </>
    );
};

export default HomeHeader;
