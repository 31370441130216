// src/Payment.js
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const loadRazorpay = (src) => {
    return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        document.body.appendChild(script);

        script.onload = () => {
            resolve(true);
        };

        script.onload = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
};

const Payment = ({amount}) => {
    const [paymentOpen, setPaymentOpen] = useState(false);
    const [payment, setPayment] = useState();
    const dispatch=useDispatch()

    const fetchUser =async() => {
           try {
                const res = await fetch(
                    `https://razorpaytest12092023.loca.lt/api/v1/transaction/razorpay/order`,
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${localStorage.getItem('t_id')}`,
                        },
                        body: JSON.stringify({ amount: 1000 }),
                    }
                );
                const result = await res.json();
                // console.log("res",result)
                
                return result;
        } catch (error) {
            console.log('error', error);
        }
    };

    useEffect(async() => {
    const res = await fetchUser();
    setPayment(res)
    console.log("res--",res)
    }, []);

    const popupPaymentGateway = async () => {
        await loadRazorpay('https://checkout.razorpay.com/v1/checkout.js');

        const options = {
            key: 'rzp_test_AXdPlhkCAChHOg',
            // amount: 100000,
            currency: 'INR',
            name: 'Test Payment',
            // description: 'Donate for cause',
            // image: 'https://randomuser.me/api/portraits/men/34.jpg',
            order_id: payment?.payload?.order_id, //from backend
            handler: function (response) {
                alert(response.razorpay_payment_id);
                alert(response.razorpay_order_id);
                alert(response.razorpay_signature);
            },
            prefill: {
                name: 'Gaurav Kumar',
                email: 'gaurav.kumar@example.com',
                contact: '9999999999',
            },
        };

        const rzp1 = new window.Razorpay(options);
        rzp1.open();
    };
    return (
        <div>
            {!paymentOpen ? (
                <button onClick={() => popupPaymentGateway()}>Open Payment</button>
            ) : (
                <div>
                    Processing Payment...
                    {/* You can add a loading spinner or some other UI indication here */}
                </div>
            )}
        </div>
    );
};

export default Payment;
