import React, { useState, useRef, useEffect } from 'react';
import { QrReader } from 'react-qr-reader';
import { Button } from '../Common';
import './index.scss';
import topLeft from '../../Assets/svg/top-left.svg';
import topRight from '../../Assets/svg/top-right.svg';
import bottomRight from '../../Assets/svg/bottom-right.svg';
import bottomLeft from '../../Assets/svg/bottom-left.svg';
import FlashIconBox from '../../Assets/icons/FlashIconBox';
import { useNavigate } from 'react-router-dom';
import { Pathname } from '../../Router/Pathname';

const index = ({
    handleClick,
    setChargingPoint,
    chargingPoint,
    setStartScan,
    startScan,
}) => {
    const navigate = useNavigate();
    const errorInScan = useRef(false);
    const [reScan, setreScan] = useState(500);

    const handleScan = () => {
        setStartScan(false);
        window.navigator.vibrate ? window.navigator.vibrate([200]) : null;
        handleClick();
    };
    useEffect(() => {
        !chargingPoint === false && handleScan();
    }, [chargingPoint]);

    return (
        <div className="scanner">
            {startScan && (
                <QrReader
                    className="wrapper"
                    // onResult={(result, error) => handleScan(result, error)}
                    onResult={(result, error) => {
                        if (!!result) {
                            window.navigator.vibrate
                                ? window.navigator.vibrate([200])
                                : null;
                            setChargingPoint(result?.text);
                            errorInScan.current = false;
                        }

                        if (!!error) {
                            errorInScan.current = true;
                        }
                    }}
                    scanDelay={reScan}
                    constraints={{
                        facingMode: 'environment',
                    }}
                    style={{ width: '100%', height: '100% !important', padding: '0' }}
                />
            )}

            {/* <div className='overlay'></div> */}
            <div className="boxWrapper">
                <div className="boxWrapper__cornerWrapper">
                    <div className="boxWrapper__cornerWrapper__cornerLeftTop">
                        <img src={topLeft} />
                    </div>
                    <div className="boxWrapper__cornerWrapper__cornerLeftBottom">
                        <img src={bottomLeft} />
                    </div>
                    <div className="boxWrapper__cornerWrapper__cornerRightTop">
                        <img src={topRight} />
                    </div>
                    <div className="boxWrapper__cornerWrapper__cornerRightBottom">
                        <img src={bottomRight} />
                    </div>
                </div>

                <p className="boxWrapper__text">
                    Keep the code at the center of the frame
                </p>

                <div className="boxWrapper__flashIcon">
                    <FlashIconBox
                        color={!reScan && !errorInScan.current ? '#00AA74' : '#8C8C8C'}
                    />
                </div>
            </div>

            <div className="wrapper__button">
                {/* {errorInScan.current && ( */}
                <Button
                    text={'Can’t scan QR'}
                    onClick={() => navigate(Pathname.SEARCH_STATIONS)}
                    variant={'transparent'}
                />
                {/* )} */}
            </div>
        </div>
    );
};

export default index;
