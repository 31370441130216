import React from 'react';
import './Card.scss';

const Card = ({ children, className, style, ...props }) => {
    const customClassName = `Card ${className}`;

    return (
        <div className={customClassName} {...props}>
            {children}
        </div>
    );
};

export default Card;
