import React from 'react';
import './MiniConnectorCard.scss';
import GasIcon from '../../Assets/icons/GasIcon';
import Badges from '../Badges/Badges';
import GreyGasIcon from '../../Assets/icons/GreyGasIcon';
import ConnectorImg from '../../Assets/pngIcon/connector.png';
const MiniConnectorCard = ({
    disabled,
    onClick,
    status = 'Available',
    name,
    io_type,
}) => {
    return (
        <div
            className={`MiniConnectorCard${
                disabled ? ' MiniConnectorCard--disabled' : ''
            }`}
            onClick={onClick}
        >
            <div className="MiniConnectorCard__LeftContent">
                <img src={ConnectorImg} className="MiniConnectorCard__Img" />
                <div className="MiniConnectorCard__Texts">
                    <span className="MiniConnectorCard__Title">{name}</span>
                    <span className="MiniConnectorCard__SubTitle">{io_type}</span>
                </div>
            </div>
            <div className="MiniConnectorCard__RightContent">
                <div className="MiniConnectorCard__Badges">
                    <Badges props={status} />
                </div>
            </div>
        </div>
    );
};

export default MiniConnectorCard;
