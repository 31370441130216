import React, { useState, useEffect } from 'react';
import './HorizontalProgressBar.scss';

const HorizontalProgressBar = ({ unitsConsumed, totalUnits, rfid_tag }) => {
    const [style, setStyle] = useState({});
    const [progressText, setProgressText] = useState('');
    const [progressCompleted, setProgressCompleted] = useState(false);

    useEffect(() => {
        // Ensure that unitsConsumed does not exceed totalUnits
        const validUnitsConsumed = Math.min(unitsConsumed, totalUnits);

        // Check if totalUnits is greater than 0 to avoid division by zero
        const fractionCompleted = totalUnits > 0 ? validUnitsConsumed / totalUnits : 0;

        console.log('fractionCompleted: ', fractionCompleted);
        const newStyle = {
            opacity: 1,
            width: `${fractionCompleted * 100}%`,
        };

        setStyle(newStyle);

        // Update progress text and check if progress is completed
        if (fractionCompleted >= 1) {
            setProgressText('Charge Completed');
            setProgressCompleted(true);
        } else {
            if (rfid_tag !== undefined && rfid_tag !== null) {
                setProgressText('Charging in Progress');
            } else {
                if (unitsConsumed === 0 && totalUnits === 0) setProgressText('');
                else setProgressText(`${unitsConsumed} Kw/ ${totalUnits} Kw`);
            }
            setProgressCompleted(false);
        }
    }, [unitsConsumed, totalUnits]);

    return (
        <>
            <div
                className={`HorizontalProgressBar ${
                    progressCompleted ? 'completed' : ''
                }`}
            >
                <div className="HorizontalProgressBar__Text">{progressText}</div>
                <div
                    className={rfid_tag ? '' : 'HorizontalProgressBar__progressDone'}
                    style={style}
                ></div>
            </div>
        </>
    );
};

export default HorizontalProgressBar;
