import React from 'react';
import WalletView from '../../Views/AfterAuth/WalletView/WalletView';
import { ReactGA } from "../../Utils/ga4"

const Wallet = () => {
    React.useEffect(() => {
        ReactGA.sendPageView("Wallet page");
    }, [])
    return <WalletView />;
};

export default Wallet;
