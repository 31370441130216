// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  width: 90%; }
  .modal p {
    font-size: 16px;
    padding-inline: 2rem; }
  .modal .worning-icon {
    padding-block: 2rem; }

.WormimgModal--btn {
  padding-top: 4rem; }
  .WormimgModal--btn div {
    display: flex;
    width: 100%;
    column-gap: 2rem; }
`, "",{"version":3,"sources":["webpack://./src/Components/Common/WarningModal/worningmodal.scss"],"names":[],"mappings":"AAAA;EACI,UAAU,EAAA;EADd;IAGQ,eAAe;IACf,oBAAoB,EAAA;EAJ5B;IAOQ,mBAAmB,EAAA;;AAG3B;EACI,iBAAiB,EAAA;EADrB;IAGQ,aAAa;IACb,WAAW;IACX,gBAAgB,EAAA","sourcesContent":[".modal{\n    width: 90%;\n    p{\n        font-size: 16px;\n        padding-inline: 2rem;\n    }\n    .worning-icon{\n        padding-block: 2rem;\n    }\n}\n.WormimgModal--btn{\n    padding-top: 4rem;\n    div {\n        display: flex;\n        width: 100%;\n        column-gap: 2rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
