import React, { useState, useEffect } from 'react';

import './InDecrement.scss';
import PreLoader from '../../../Assets/icons/PreLoader/PreLoader';

const IncDecrement = ({
    onChange,
    disabled,
    handleDecrement,
    handleIncrement,
    value,
    placeholder,
    unit,
    label = 'text',
    loading,
}) => {
    const [focused, setFocused] = useState(!!value);

    const handleBlur = (e) => {
        setFocused(!!e.target.value);
    };

    const LoadingUI = () => (
        <div className="IncDecrement__loading__box">
            <PreLoader width={'47px'} height={'47px'} />
        </div>
    );

    return (
        <>
            <div className="IncDecrement">
                <button
                    onClick={disabled || loading ? () => {} : handleDecrement}
                    className={`IncDecrement__buttonLeft ${
                        loading ? 'IncDecrement__buttonDisabled' : ''
                    }`}
                >
                    -
                </button>

                <input
                    disabled={disabled}
                    id="IncDec"
                    type="number"
                    value={!disabled ? value : null}
                    className={`IncDecrement__input__box ${
                        loading ? 'IncDecrement__InputLoading' : ''
                    }`}
                    onChange={onChange}
                    onFocus={() => setFocused(true)}
                    onBlur={handleBlur}
                />
                {loading && <LoadingUI />}

                <button
                    onClick={disabled || loading ? () => {} : handleIncrement}
                    className={`IncDecrement__buttonRight ${
                        loading ? 'IncDecrement__buttonDisabled' : ''
                    }`}
                >
                    +
                </button>
            </div>
            <label className={'IncDecrement__placeholder'}>{label}</label>
        </>
    );
};

export default IncDecrement;
