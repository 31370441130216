import React, { useState } from 'react'
import "./BasicCard.scss"
import TickIcon from '../../../Assets/icons/TickIcon';
import FlashGreen from '../../../Assets/icons/FlashGreen';


const BasicCard = ({ id, icon, heading1, heading2, onSelect,isSelected }) => {
  
  // For multiple card selection
  // const [isSelected, setIsSelected] = useState(false);
  //  const toggleSelection = () => {
  //    setIsSelected(!isSelected);
  //    onSelect(id); // Call the callback with the selected card's ID
  //  };


  const handleClick = () => {
    onSelect(id);
  };

  return (
    <div
      className={`card ${isSelected ? "selected" : "IsNotSelected"}`}
      onClick={handleClick}
    >
      <div className="cardIcons">
        <div className="card-icon">{isSelected ? <FlashGreen/> : icon}</div>
        {isSelected && (
          <div className="tick-icon">
            <TickIcon />
          </div>
        )}
      </div>
      <div>
      <h2 className="card-heading">{heading1}</h2>
      </div>
      <div>
      <h3 className="card-subheading">{heading2}</h3>
      </div>
    </div>
  );
};

export default BasicCard