import React from 'react';
import './frame.scss';
const Frame = () => {
    return (
        <div className="frame-container">
            <svg viewBox="0 0 360 136" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_45_1667)">
                    <path
                        d="M0 0H360V104C360 121.673 345.673 136 328 136H32C14.3269 136 0 121.673 0 104V0Z"
                        fill="black"
                    />
                    <rect
                        x="-43"
                        y="-6"
                        width="446"
                        height="240"
                        rx="46"
                        fill="#00AA74"
                        fillOpacity="0.16"
                    />
                    <rect
                        y="15"
                        width="360"
                        height="185"
                        rx="46"
                        fill="#00AA74"
                        fillOpacity="0.06"
                    />
                    <rect
                        x="57"
                        y="65"
                        width="245"
                        height="106"
                        rx="46"
                        fill="#00AA74"
                        fillOpacity="0.08"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_45_1667">
                        <path
                            d="M0 0H360V104C360 121.673 345.673 136 328 136H32C14.3269 136 0 121.673 0 104V0Z"
                            fill="white"
                        />
                    </clipPath>
                </defs>
            </svg>
        </div>
    );
};

export default Frame;
