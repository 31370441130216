import React from 'react';
import './Header.scss';
import Frame from '../../../Assets/icons/Frame/Frame';
import Logo from '../../../Assets/icons/Logo/Logo';

const Header = () => {
    return (
        <header className="Header">
            <div className="frame-container">
                <Frame />
            </div>
            <div className="logo-container">
                <Logo />
            </div>
        </header>
    );
};

export default Header;
