import React from 'react'

const WhiteBack = () => {
  return (
      <div>
          <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
              <path
                  d="M21.5699 30.82C21.3799 30.82 21.1899 30.75 21.0399 30.6L14.9699 24.53C14.6799 24.24 14.6799 23.76 14.9699 23.47L21.0399 17.4C21.3299 17.11 21.8099 17.11 22.0999 17.4C22.3899 17.69 22.3899 18.17 22.0999 18.46L16.5599 24L22.0999 29.54C22.3899 29.83 22.3899 30.31 22.0999 30.6C21.9599 30.75 21.7599 30.82 21.5699 30.82Z"
                  fill="white"
              />
              <path
                  d="M32.4999 24.75H15.6699C15.2599 24.75 14.9199 24.41 14.9199 24C14.9199 23.59 15.2599 23.25 15.6699 23.25H32.4999C32.9099 23.25 33.2499 23.59 33.2499 24C33.2499 24.41 32.9099 24.75 32.4999 24.75Z"
                  fill="white"
              />
          </svg>
      </div>
  );
}

export default WhiteBack