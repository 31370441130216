import React, { useEffect, useState } from 'react';
import ArrowLeft from '../../../../Assets/icons/ArrowLeft';
import { Pathname } from '../../../../Router/Pathname';
import { useNavigate } from 'react-router-dom';
import TickCircleXl from '../../../../Assets/icons/TickCircleXl';
import './editprofileview.scss';
import Input from '../../../../Components/Common/Input/Input';
import { getProfileDetails, updateProfileDetails } from '../redux/profileSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import Form from '../../../../Components/Common/Form';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    //   user_name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    //   gst_in: Yup.string().required('GST IN is required'),
    //   address: Yup.string().required('Address is required'),
});

const EditProFileView = () => {
    const { profile } = useSelector((state) => state);
    const { error, loading, profileInfo } = profile;

    // console.log('profileInfo', profileInfo);
    // let defaultValues = {
    //     user_name: profileInfo?.payload?.customer?.user_name,
    //     gst_in: profileInfo?.payload?.customer?.gst_in,
    //     address: profileInfo?.payload?.customer?.address,
    //     email: profileInfo?.payload?.customer?.email,
    // };
    // const [inputData, setInputData] = useState({
    //     user_name: '',
    //     gst_in: '',
    //     address: '',
    //     email: '',
    // });

    // console.log("default",defaultValues)

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleValueChange = (fieldName, value) => {
        setInputData((prevInputData) => ({
            ...prevInputData,
            [fieldName]: value,
        }));
    };
    useEffect(() => {
        dispatch(getProfileDetails());
    }, []);

    // const handleSubmit = async () => {
    //     const updatedData = await mergeValues( defaultValues,inputData);
    //     console.log("updated",updatedData)
    //     const data = await dispatch(updateProfileDetails(updatedData));
    //     if (data?.payload?.success) {
    //         toast.success(`${data?.payload?.message}`, {
    //             position: toast.POSITION.TOP_CENTER,
    //             autoClose: 2000,
    //             style: {
    //                 fontSize: '20px',
    //             },
    //         });
    //         setTimeout(() => {
    //             navigate(`${Pathname.PROFILE}`);
    //         }, 1500);
    //     }else{
    //             toast.success(`${data?.payload?.message}`, {
    //                 position: toast.POSITION.TOP_CENTER,
    //                 autoClose: 2000,
    //                 style: {
    //                     fontSize: '20px',
    //                 },
    //             });
    //     }
    // };

    function mergeValues(obj1, obj2) {
        const merged = {};

        for (const key in obj1) {
            if (obj1[key] !== '') {
                merged[key] = obj1[key];
            }
        }

        for (const key in obj2) {
            if (obj2[key] !== '') {
                merged[key] = obj2[key];
            }
        }

        return merged;
    }

    const handleSubmit = async(values) => {
        // Perform form submission logic here
        console.log('Form submitted with values:', values);
            const data = await dispatch(updateProfileDetails(values));
            if (data?.payload?.success) {
                toast.success(`${data?.payload?.message}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    style: {
                        fontSize: '16px',
                    },
                });
                setTimeout(() => {
                    navigate(`${Pathname.PROFILE}`);
                }, 1500);
            }else{
                    toast.error(`${data?.payload?.message}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 2000,
                        style: {
                            fontSize: '16px',
                        },
                    });
            }
        dispatch(getProfileDetails());

    };

    return (
        <div>
            <main className="EditProFileView">
                <Form
                    initialValues={{
                        user_name: profileInfo?.payload?.customer?.user_name,
                        gst_in: profileInfo?.payload?.customer?.gst_in,
                        address: profileInfo?.payload?.customer?.address,
                        email: profileInfo?.payload?.customer?.email,
                    }}
                    validationSchema={validationSchema}
                    handleSubmit={handleSubmit}
                >
                    {(formik) => {
                        const {
                            values,
                            errors,
                            handleChange,
                            touched,
                            // handleBlur
                            setFieldValue,
                            dirty,
                            isValid,
                            resetForm,
                            getFieldProps,
                            isSubmitting,
                        } = formik;
                        return (
                            <>
                                <div className="EditProFileView--btn">
                                    <div className="back-btn">
                                        <span
                                            style={{ cursor: 'pointer' }}
                                            onClick={() =>
                                                navigate(`${Pathname.PROFILE}`)
                                            }
                                        >
                                            <ArrowLeft />
                                        </span>
                                        <p>Edit Profile</p>
                                    </div>
                                    <div
                                        onClick={() => handleSubmit(values)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <TickCircleXl />
                                    </div>
                                </div>
                                <div className="EditProFileView--content">
                                    <div className="profile-img">
                                        <div>
                                            <img
                                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQCCZTtKfjUliAayWuiDYubKn7IQ8GEv-ZwjugUwz-VAdV9e4QEEx2J0dBUVaXJ_6CZW1I&usqp=CAU"
                                                alt=""
                                            />
                                            <p>Upload profile picture</p>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <form
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '2rem',
                                        }}
                                    >
                                        <div>
                                            <Input
                                                label="Name"
                                                type="text"
                                                name="user_name"
                                                value={values.user_name}
                                                onValueChange={(value) =>
                                                    setFieldValue('user_name', value)
                                                }
                                                required
                                            />
                                            {touched.user_name && errors.user_name && (
                                                <div className="error-message">
                                                    {errors.user_name}
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <Input
                                                label="Email"
                                                type="email"
                                                name="email"
                                                value={values.email}
                                                onValueChange={(value) =>
                                                    setFieldValue('email', value)
                                                }
                                                // onBlur={handleBlur}
                                                // required
                                            />
                                            {touched.email && errors.email && (
                                                <div className="error-message">
                                                    {errors.email}
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <Input
                                                label="GST IN"
                                                type="text"
                                                name="gst_in"
                                                value={values.gst_in}
                                                onValueChange={(value) =>
                                                    setFieldValue('gst_in', value)
                                                }
                                                // onBlur={handleBlur}
                                            />
                                            {touched.gst_in && errors.gst_in && (
                                                <div className="error-message">
                                                    {errors.gst_in}
                                                </div>
                                            )}
                                        </div>
                                        <div>
                                            <Input
                                                label="Address"
                                                type="text"
                                                name="address"
                                                value={values.address}
                                                onValueChange={(value) =>
                                                    setFieldValue('address', value)
                                                }
                                                // onBlur={handleBlur}
                                            />
                                            {touched.address && errors.address && (
                                                <div className="error-message">
                                                    {errors.address}
                                                </div>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </>
                        );
                    }}
                </Form>
                {/* <div className="input-group-filed">
                    <span>
                        <Input
                            label={'Name'}
                            onValueChange={(value) =>
                                handleValueChange('user_name', value)
                            }
                            defaultValue={defaultValues.user_name}
                        />
                    </span>
                    <span>
                        <Input
                            label={'Enter Email id'}
                            type={'email'}
                            onValueChange={(value) => handleValueChange('email', value)}
                            defaultValue={defaultValues.email}
                            
                        />
                    </span>
                    <Input
                        label={'Enter GST IN'}
                        onValueChange={(value) => handleValueChange('gst_in', value)}
                        defaultValue={defaultValues.gst_in}
                    />
                    <Input
                        label={'Address'}
                        onValueChange={(value) => handleValueChange('address', value)}
                        defaultValue={defaultValues.address}
                    />
                </div> */}
                {/* <Form
                    initialValues={{
                        user_name: profileInfo?.payload?.customer?.user_name,
                        gst_in: profileInfo?.payload?.customer?.gst_in,
                        address: profileInfo?.payload?.customer?.address,
                        email: profileInfo?.payload?.customer?.email,
                    }}
                    validationSchema={validationSchema}
                    handleSubmit={handleSubmit}
                >
                    {(formik) => (
                        <form style={{display:"flex",flexDirection:"column",gap:"2rem"}} onSubmit={handleSubmit}>
                            <div>
                             
                                <Input
                                    label="Name"
                                    type="text"
                                    name="user_name"
                                    value={values.user_name}
                                    onValueChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.user_name && errors.user_name && (
                                    <div className="error-message">
                                        {errors.user_name}
                                    </div>
                                )}
                            </div>
                            <div>
                               
                                <Input
                                    label="Email"
                                    type="email"
                                    name="email"
                                    value={values.email}
                                    onValueChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.email && errors.email && (
                                    <div className="error-message">
                                        {errors.email}
                                    </div>
                                )}
                            </div>
                            <div>
                               
                                <Input
                                    label="GST IN"
                                    type="text"
                                    name="gst_in"
                                    value={values.gst_in}
                                    onValueChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.gst_in && errors.gst_in && (
                                    <div className="error-message">
                                        {errors.gst_in}
                                    </div>
                                )}
                            </div>
                            <div>
                               
                                <Input
                                    label="Address"
                                    type="text"
                                    name="address"
                                    value={values.address}
                                    onValueChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {touched.address && errors.address && (
                                    <div className="error-message">
                                        {errors.address}
                                    </div>
                                )}
                            </div>
                        
                        </form>
                    )}
                </Form> */}
            </main>
            <ToastContainer />
        </div>
    );
};
export default EditProFileView;
