import  LoginView  from '../Views/beforeAuth/LoginView/LoginView';

import {
    EmspView,
    HelpAndSupportView,
    HomeView,
    MyVehiclesView,
    SplashScreenView,
    // ProfileView,
    WalletView,
} from './AfterAuth';
export {
    EmspView,
    HelpAndSupportView,
    HomeView,
    LoginView,
    MyVehiclesView,
    // ProfileView,
    SplashScreenView,
    WalletView,
};
