// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonContainer {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem; }
  .ButtonContainer .custom {
    background-color: yellow;
    color: black;
    width: 150px; }

.funds {
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.02em;
  text-align: center; }

.fundInfo {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center; }

.modalClass {
  display: flex;
  flex-direction: column;
  justify-content: center; }

.addWallet {
  height: auto;
  margin: auto;
  margin-top: 20px; }

.modalButton {
  width: fit-content;
  height: auto; }

.modern-custom-class {
  background: yellow; }
`, "",{"version":3,"sources":["webpack://./src/Views/beforeAuth/Components/index.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,6BAA6B;EAC7B,eAAe;EACf,SAAQ,EAAA;EAJZ;IAMQ,wBAAwB;IACxB,YAAY;IACZ,YAAY,EAAA;;AAGpB;EAEA,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB,EAAA;;AAIlB;EACA,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB,EAAA;;AAElB;EACI,aAAa;EACb,sBAAsB;EACtB,uBAAuB,EAAA;;AAE3B;EACG,YAAY;EACZ,YAAY;EACZ,gBAAgB,EAAA;;AAGnB;EACI,kBAAkB;EAClB,YAAY,EAAA;;AAGhB;EACI,kBAAkB,EAAA","sourcesContent":[".ButtonContainer {\n    display: flex;\n    justify-content: space-around;\n    flex-wrap: wrap;\n    gap:2rem;\n    .custom {\n        background-color: yellow;\n        color: black;\n        width: 150px;\n    }\n}\n.funds{\n\nfont-size: 18px;\nfont-weight: 600;\nline-height: 22px;\nletter-spacing: 0.02em;\ntext-align: center;\n\n}\n\n.fundInfo{\nfont-size: 16px;\nfont-weight: 400;\nline-height: 20px;\nletter-spacing: 0em;\ntext-align: center;\n}\n.modalClass{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n.addWallet{\n   height: auto;\n   margin: auto;\n   margin-top: 20px;\n}\n\n.modalButton{\n    width: fit-content;\n    height: auto;\n}\n\n.modern-custom-class{\n    background: yellow;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
