import React from 'react';
import ArrowLeft from '../../../../Assets/icons/ArrowLeft';
import { useNavigate } from 'react-router-dom';
import { Pathname } from '../../../../Router/Pathname';

const StationHeader = () => {
    const navigate = useNavigate();
    return (
        <section className="SearchStations__Header">
            <div
                className="SearchStations__Left"
                onClick={() => navigate(Pathname.START_CHARGING)}
            >
                <ArrowLeft />
            </div>
            <div className="SearchStations__Right">
                <div className="SearchStations__Title">Search Stations</div>
            </div>
        </section>
    );
};

export default StationHeader;
