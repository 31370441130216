import React, { useEffect, useState } from 'react';
import './intiatechargingview.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowLeft from '../../../../Assets/icons/ArrowLeft';
import PluginCard from '../../../../Components/HomeCards/PluginCard/PluginCard';
import Plugin from '../../../../Assets/icons/Plugin';
import stopIcon from '../../../../Assets/pngIcon/stopIcon.png';
import { Button } from '../../../../Components/Common';
import BasicCard from '../../../../Components/HomeCards/BasicCard/BasicCard';
import { Pathname } from '../../../../Router/Pathname';
import Modal from '../../../../Components/Common/Modal/Modal';
import FlashGreen from '../../../../Assets/icons/FlashGreen';
import LocationIcon from '../../../../Assets/icons/LocationIcon';
import MoneyIcon from '../../../../Assets/icons/MoneyIcon';
import ElectricityGreen from '../../../../Assets/icons/ElectricityGreen';
import TickIconXL from '../../../../Assets/icons/TickIconXL';
import walleticon from '../../../../Assets/pngIcon/walletXl.png';
import PluginWave from '../../../../Assets/PluginWave.gif';
import { useDispatch, useSelector } from 'react-redux';
import {
    createWallet,
    getWalletBalance,
    initiateCharging,
    startCharging,
    startChargingStatus,
} from '../../HomeView/redux/homeSlice';
import { ToastContainer, toast } from 'react-toastify';
import { ReactGA } from '../../../../Utils/ga4';

const IntiatechargingView = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const calculatedEnergy = location?.state?.calculatedEnergy;
    const calculatedAmount = location?.state?.calculatedAmount;
    const isZeroTarrif = location?.state?.isZeroTarrif;
    const [timestore, settimeStore] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isChargingStarted, setIsChargingStarted] = useState(false); // New state variable
    const dispatch = useDispatch();
    const { home } = useSelector((store) => store);
    const {
        initiateChargingStatus,
        walletBalance,
        connectorData,
        startChargingPermission,
    } = home;

    // const connector_id = sessionStorage.getItem('connector_id');

    const isButtonDisabled = !timestore || isChargingStarted;

    const { state } = useLocation();
    const connector_id = state?.connector_id;

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    // wallet ? navigate(`${Pathname.START_CHARGING}/start-charging`) : openModal
    const handleclickmodal_charging = async () => {
        let res;
        setIsChargingStarted(true); // Disable the button after click

        if (walletBalance?.payload?.balance < 99 && !isZeroTarrif) {
            openModal();
        }
        if (isZeroTarrif) {
            res = await dispatch(
                startCharging({
                    connector_id: connectorData?.payload?.id || connector_id,
                    calculatedEnergy: calculatedEnergy, // Sending energy when isZeroTarrif is true
                })
            );
        } else {
            res = await dispatch(
                startCharging({
                    connector_id: connectorData?.payload?.id || connector_id,
                    calculatedAmount: calculatedAmount, // Sending amount when isZeroTarrif is false
                })
            );
        }

        if (res?.payload?.success) {
            if (initiateChargingStatus?.payload?.skipWalletBalChecking == false) {
                openModal();
            }
            navigate(`${Pathname.START_CHARGING}/start-charging`, {
                state: 'showModal',
            });
        } else {
            setIsChargingStarted(false); // Enable the button if the API call was not successful
            if (!res?.payload?.success) {
                toast.error(`${res?.payload?.message}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    style: {
                        fontSize: '16px',
                    },
                });
            }
        }
    };

    React.useEffect(() => {
        ReactGA.sendPageView('Initiate charging page');
    }, []);

    useEffect(async () => {
        // dispatch(createWallet());
        dispatch(getWalletBalance());
        dispatch(initiateCharging(connector_id));
    }, [connector_id]);

    useEffect(() => {
        let response;
        let time = setInterval(() => {
            response = dispatch(startChargingStatus(connector_id));
            if (startChargingPermission?.payload?.status?.toLowerCase() === 'preparing') {
                settimeStore(true);
            } else {
                settimeStore(false);
            }
        }, 8000);
        return () => clearInterval(time);
    }, [connector_id, startChargingPermission]);

    return (
        <div className="IntiateCharging">
            <div className="IntiateCharging--btn">
                <span
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <ArrowLeft />
                </span>
                <p>Initiate Charging</p>
            </div>
            <div className="IntiateCharging--prograss">
                <div>
                    <PluginCard
                        subheading={timestore ? 'Plugged in' : 'Plug-in the charger'}
                        icon1={<Plugin />}
                        icon2={
                            timestore ? <TickIconXL /> : <img src={stopIcon} alt="stop" />
                        }
                        className={
                            timestore ? 'IntiateCharging-true' : 'IntiateCharging-false'
                        }
                    />
                </div>
            </div>
            <div className="IntiateCharging--card">
                <div>
                    <BasicCard
                        heading1="Station name"
                        heading2={
                            initiateChargingStatus?.payload?.charging_station
                                ?.station_name
                        }
                        icon={<LocationIcon />}
                    />
                    <BasicCard
                        heading1={isZeroTarrif ? 'Recharge Energy' : 'Recharge Cost'}
                        heading2={
                            isZeroTarrif
                                ? ` ${calculatedEnergy} kW`
                                : `₹ ${calculatedAmount} (${calculatedEnergy} kW)` ?? '_'
                        }
                        icon={<MoneyIcon />}
                    />
                </div>
                <div>
                    <BasicCard
                        heading1="Charger ID"
                        heading2={initiateChargingStatus?.payload?.charging_point_id}
                        icon={<FlashGreen />}
                    />
                    <BasicCard
                        heading1="Connector type"
                        heading2={initiateChargingStatus?.payload?.standard}
                        icon={<ElectricityGreen />}
                    />
                </div>
            </div>
            <div className="SelectView--btn" onClick={handleclickmodal_charging}>
                <Button
                    // glow={timestore ? true : false}
                    // disabled={timestore ? false : true}
                    glow={!isButtonDisabled}
                    disabled={isButtonDisabled}
                    text="Start Charging"
                />
            </div>
            <Modal
                isOpen={isModalOpen}
                onClose={closeModal}
                title={<img src={walleticon} alt="wallet" />}
                modalClass="IntiateCharging--custom-modal"
                overlayClass="custom-overlay"
                closeButtonClass="custom-close-button"
            >
                <div className="IntiateCharging--content">
                    <p className="funds">Insufficient Funds</p>
                    <p className="fundInfo">
                        Please add money in wallet to charge your vehicle
                    </p>
                    <div
                        // onClick={() =>
                        //     navigate(`${Pathname.START_CHARGING}/add-money`, {
                        //         state: 'redirected to add funds after QR scanning',
                        //     })
                        // }
                        onClick={() =>
                            navigate(`${Pathname.START_CHARGING}/add-money`, {
                                state: location.state,
                            })
                        }
                    >
                        <Button className="addWallet" text="Add money to wallet" />
                    </div>
                </div>
            </Modal>
            <ToastContainer />
        </div>
    );
};
export default IntiatechargingView;
