import React, { useEffect, useState } from 'react';
import './SearchStations.scss';
import { useNavigate } from 'react-router-dom';
import Input from '../../../../Components/Common/Input/Input';
import FilterBtn from '../../../../Components/Common/FilterBtn/FilterBtn';
import MiniStationCard from '../../../../Components/MiniStationCard/MiniStationCard';
import { Pathname } from '../../../../Router/Pathname';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { filterStations, fetchConnectorStandards, getallStations } from '../redux/action';
import PreLoader from '../../../../Assets/icons/PreLoader/PreLoader';
import StationHeader from './StationHeader';

const SearchStations = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Destructuring props and state
    const { stations } = useSelector(({ stations }) => ({ stations }));
    const { allStations, loading, connectorStandards, hasMore, page } = stations;

    // State
    const [activeFilter, setActiveFilter] = useState(null);
    const [stationName, setStationName] = useState(null);

    // Event handlers
    const handleFilterClick = (id) => setActiveFilter(id);
    const handleStationName = (val) => setStationName(val);
    const handleCardNavigation = (chargingStation) => {
        if (!chargingStation?.available) return;

        navigate(Pathname.SEARCH_CONNECTOR, {
            state: {
                id: chargingStation.id,
                name: chargingStation.station_name,
            },
        })
    }

    // Components
    const Preloader = () => (
        <div className="SearchStations__Loader" key={0}>
            <PreLoader />
        </div>
    );

    // Rendering station cards
    const renderStationCards = () =>
        allStations?.map((chargingStation) => (
            <MiniStationCard
                key={chargingStation?.id}
                name={chargingStation?.station_name}
                address={`${chargingStation?.address}`}
                status={chargingStation?.available ? 'Available' : 'Unavailable'}
                onClick={() => handleCardNavigation(chargingStation)}
            />
        ));

    // Fetch more stations on scroll
    const loadMore = () => {
        if (!loading && hasMore && page > 0) {
            dispatch(
                getallStations({
                    connector_type: activeFilter,
                    station_name: stationName,
                    page: page,
                })
            );
        }
    };

    // Update stations by stationName or ConnectorType
    useEffect(() => {
        const debouncedStationName = setTimeout(() => {
            dispatch(
                filterStations({
                    connector_type: activeFilter,
                    station_name: stationName,
                })
            );
        }, 500);
        return () => clearTimeout(debouncedStationName);
    }, [activeFilter, stationName]);

    // Fetch all the connector Standards
    useEffect(() => {
        dispatch(fetchConnectorStandards());
    }, []);
    return (
        <div>
            <main className="SearchStations">
                <StationHeader />
                <div className="SearchStations__Input">
                    <Input
                        label={'Search Stations'}
                        value={stationName}
                        onValueChange={handleStationName}
                    />
                </div>
                <div className="SearchStations__FilterContainer">
                    <FilterBtn
                        text={'All'}
                        handleClick={() => handleFilterClick(null)}
                        activeStatus={activeFilter === null}
                    />
                    {connectorStandards?.map((item, index) => (
                        <FilterBtn
                            key={index}
                            text={item}
                            id={item}
                            handleClick={() => handleFilterClick(item)}
                            activeStatus={activeFilter === item}
                        />
                    ))}
                </div>

                <InfiniteScroll
                    pageStart={0}
                    loadMore={loadMore}
                    hasMore={hasMore}
                    loader={Preloader()}
                >
                    <div className="SearchStations__Cards">
                        {allStations?.length > 0 ? (
                            renderStationCards()
                        ) : loading ? (
                            <h1>Loading Stations....</h1>
                        ) : (
                            <h1>No Stations Available</h1>
                        )}
                    </div>
                </InfiniteScroll>
            </main>
        </div>
    );
};

export default SearchStations;
