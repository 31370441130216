import React from 'react';

const FuelIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={16}
                height={16}
                viewBox="0 0 16 16"
                fill="none"
            >
                <path
                    d="M14.8935 6.22065L13.5602 5.55398C13.3135 5.43398 13.0068 5.52732 12.8868 5.77398C12.7602 6.02732 12.8602 6.32732 13.1068 6.44732L14.1668 6.97398V10.1673L11.6668 10.174V3.33398C11.6668 2.00065 10.7735 1.33398 9.66683 1.33398H4.3335C3.22683 1.33398 2.3335 2.00065 2.3335 3.33398V14.1673H1.3335C1.06016 14.1673 0.833496 14.394 0.833496 14.6673C0.833496 14.9406 1.06016 15.1673 1.3335 15.1673H12.6668C12.9402 15.1673 13.1668 14.9406 13.1668 14.6673C13.1668 14.394 12.9402 14.1673 12.6668 14.1673H11.6668V11.174L14.6668 11.1673C14.9468 11.1673 15.1668 10.9406 15.1668 10.6673V6.66732C15.1668 6.48065 15.0602 6.30732 14.8935 6.22065ZM4.00016 4.59398C4.00016 3.66732 4.56683 3.33398 5.26016 3.33398H8.74683C9.43349 3.33398 10.0002 3.66732 10.0002 4.59398V5.41398C10.0002 6.33398 9.43349 6.66732 8.74016 6.66732H5.26016C4.56683 6.66732 4.00016 6.33398 4.00016 5.40732V4.59398ZM4.3335 8.16732H6.3335C6.60683 8.16732 6.8335 8.39398 6.8335 8.66732C6.8335 8.94065 6.60683 9.16732 6.3335 9.16732H4.3335C4.06016 9.16732 3.8335 8.94065 3.8335 8.66732C3.8335 8.39398 4.06016 8.16732 4.3335 8.16732Z"
                    fill="url(#paint0_linear_7876_22184)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_7876_22184"
                        x1="0.833496"
                        y1="2.19857"
                        x2="15.5396"
                        y2="2.63606"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#00AA74" />
                        <stop offset={1} stopColor="#0BD393" />
                    </linearGradient>
                </defs>
            </svg>
        </>
    );
};

export default FuelIcon;
