import React from 'react';
import { useRouteError } from 'react-router-dom';
import { Typography } from '../Common';
import './ErrorBoundary.scss';

const ErrorBoundary = () => {
    const error = useRouteError();

    const PageNotFound = () => {
        return (
            <React.Fragment>
                <Typography variant="h1" align="center" size="4rem">
                    <span>{error.status}: </span>
                    {error.statusText} !
                </Typography>
                <Typography variant="h1" align="center" color="#EC6A5E">
                    {error.error.message}
                </Typography>
            </React.Fragment>
        );
    };

    const UiErrorFallback = () => {
        return (
            <React.Fragment>
                <Typography variant="h1" align="center" size="4rem">
                    Something went wrong !
                </Typography>
                {/* <Button>Get Support</Button> */}

                <Typography variant="h1" align="center" color="#EC6A5E">
                    {error.message}
                </Typography>
            </React.Fragment>
        );
    };

    return (
        <div className="ErrorBoundary">
            <div className="ErrorBoundary--Support">
                {error.status ? <PageNotFound /> : <UiErrorFallback />}
            </div>
        </div>
    );
};

export default ErrorBoundary;
