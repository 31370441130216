import React, { useEffect, useState } from 'react';

const LargeScreenMessage = () => {

    return (
        <div className="large-screen-message">
            <p>
                This app is not intended for large screens. Please use a smaller screen
                size.
            </p>
        </div>
    );
};

export default LargeScreenMessage;
