import React from 'react';

export default function TickCircleXl() {
    return (
        <div>
            <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.9165 20.0008L17.6332 24.7175L27.0832 15.2842"
                    stroke="#00AA74"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
}
