import React from 'react';
import './profileaboutcard.scss';
import AboutBook from '../../Assets/icons/AboutBook';
import MessageQuestion from '../../Assets/icons/MessageQuestion';
import { useNavigate } from 'react-router-dom';
import { Pathname } from '../../Router/Pathname';
const ProfileAboutCard = () => {
    const navigate=useNavigate()
    return (
        <div className="ProfileAboutCard">
            <div className="text" onClick={()=>navigate(`${Pathname.PROFILE}/about`)}>
                <p>About LionCharge</p>
                <span>
                <AboutBook/>
                </span>
            </div>
            <div className="text" onClick={()=>navigate(`${Pathname.PROFILE}/help-and-support`)}>
                <p>Help & Support</p>
                <span>
                <MessageQuestion/>
                </span>
            </div>
            <div className="text" onClick={()=>navigate(`${Pathname.PROFILE}/privacy-policy`)}>
                <p>Privacy Policy</p>
                <span>
                <AboutBook/>
                </span>
            </div>
            <div className="text" onClick={()=>navigate(`${Pathname.PROFILE}/refund-policy`)}>
                <p>Refund policy</p>
                <span>
                <AboutBook/>
                </span>
            </div>
        </div>
    );
};
export default ProfileAboutCard;
