import React from 'react';
import './Modal.scss';
import Close from '../../../Assets/icons/Close';

const Modal = ({
    isOpen,
    onClose,
    title,
    children,
    modalClass,
    overlayClass,
    closeButtonClass,
    disableClose,
}) => {
    const closeModal = () => {
        if (onClose) {
            onClose();
        }
    };

    const handleOverlayClick = (event) => {
        if (event.target.classList.contains('modal-overlay')) {
            closeModal();
        }
    };

    return (
        <>
            {isOpen && (
                <div>
                    <div
                        className={`modal-overlay ${overlayClass || ''}`}
                        onClick={handleOverlayClick}
                    >
                        <div>
                            {!disableClose && (
                                <div
                                    className={`close-button ${closeButtonClass || ''}`}
                                    onClick={closeModal}
                                >
                                    <Close />
                                </div>
                            )}
                            <div className={`modal-center ${modalClass || ''}`}>
                                <div className="modal-header">
                                    <p>{title}</p>
                                </div>
                                <div className="modal-content">{children}</div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Modal;

// Method of using modal
// const [isModalOpen, setIsModalOpen] = useState(false);
// const openModal = () => {
//     setIsModalOpen(true);
// };
// const closeModal = () => {
//     setIsModalOpen(false);
// };
//    <div>
//        <button onClick={openModal}>Open Modal</button>
//        <Modal
//            isOpen={isModalOpen}
//            onClose={closeModal}
//            title={<CarIcon />}
//            modalClass="custom-modal"
//            overlayClass="custom-overlay"
//            closeButtonClass="custom-close-button"
//        >
//            <div>
//                <p>Insufficient Funds</p>
//                <p>Please add money in wallet to charge your vehicle</p>
//                <button>Add money to wallet</button>
//            </div>
//        </Modal>
//    </div>;
