// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  border: none;
  display: flex;
  flex-direction: column;
  margin-block: 10px;
  height: auto;
  padding: 16px;
  border-radius: 12px;
  cursor: pointer;
  background-color: white; }
  .card .card-icon {
    font-size: 24px;
    margin-bottom: 2.5rem; }
  .card .tick-icon {
    font-size: 20px;
    color: green;
    margin-bottom: 24px;
    display: flex;
    flex-direction: row-reverse; }
  .card .card-heading {
    color: #4c4c4c;
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em; }
  .card .card-subheading {
    font-size: 16px;
    font-weight: 5  00;
    line-height: 20px;
    color: #1e1e1e;
    margin: 10px 0; }
  .card .cardIcons {
    display: flex;
    justify-content: space-between;
    align-items: center; }

.selected {
  background-color: #e6f7ef;
  border: 1px solid #00AA74; }

.selected .card-heading {
  font-size: 13px; }

.selected .card-subheading {
  font-size: 17px; }

.IsNotSelected {
  pointer-events: none; }
`, "",{"version":3,"sources":["webpack://./src/Components/HomeCards/BasicCard/BasicCard.scss"],"names":[],"mappings":"AACA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAElB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,uBAAuB,EAAA;EAVzB;IAcE,eAAe;IACf,qBAAqB,EAAA;EAfvB;IAmBE,eAAe;IACf,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,2BAA2B,EAAA;EAvB7B;IA0BE,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB,EAAA;EA9BrB;IAkCE,eAAe;IACf,kBAAkB;IAClB,iBAAiB;IACjB,cAAc;IACd,cAAc,EAAA;EAtChB;IAyCI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB,EAAA;;AAIvB;EACE,yBAAwB;EACxB,yBAAyB,EAAA;;AAE3B;EAEI,eAAe,EAAA;;AAFnB;EAKI,eAAe,EAAA;;AAInB;EACE,oBAAoB,EAAA","sourcesContent":["\n.card {\n  border: none;\n  display: flex;\n  flex-direction: column;\n  margin-block: 10px;\n  // width: fit-content;\n  height: auto;\n  padding: 16px;\n  border-radius: 12px;\n  cursor: pointer;\n  background-color: white;\n\n\n.card-icon {\n  font-size: 24px;\n  margin-bottom: 2.5rem;\n}\n\n.tick-icon {\n  font-size: 20px;\n  color: green;\n  margin-bottom: 24px;\n  display: flex;\n  flex-direction: row-reverse;\n}\n.card-heading {\n  color: #4c4c4c;\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 15px;\n  letter-spacing: 0em;\n}\n\n.card-subheading {\n  font-size: 16px;\n  font-weight: 5  00;\n  line-height: 20px;\n  color: #1e1e1e;\n  margin: 10px 0;\n}\n.cardIcons{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n}\n\n.selected {\n  background-color:#e6f7ef; \n  border: 1px solid #00AA74;\n}\n.selected{\n  .card-heading{\n    font-size: 13px;\n  }\n  .card-subheading{\n    font-size: 17px;\n  }\n  \n}\n.IsNotSelected{\n  pointer-events: none;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
