import React from 'react'

const Warning = () => {
  return (
      <div>
          <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
          >
              <path
                  d="M8.00016 15.1667C4.04683 15.1667 0.833496 11.9534 0.833496 8.00004C0.833496 4.04671 4.04683 0.833374 8.00016 0.833374C11.9535 0.833374 15.1668 4.04671 15.1668 8.00004C15.1668 11.9534 11.9535 15.1667 8.00016 15.1667ZM8.00016 1.83337C4.60016 1.83337 1.8335 4.60004 1.8335 8.00004C1.8335 11.4 4.60016 14.1667 8.00016 14.1667C11.4002 14.1667 14.1668 11.4 14.1668 8.00004C14.1668 4.60004 11.4002 1.83337 8.00016 1.83337Z"
                  fill="#EC6A5E"
              />
              <path
                  d="M8 9.16671C7.72667 9.16671 7.5 8.94004 7.5 8.66671V5.33337C7.5 5.06004 7.72667 4.83337 8 4.83337C8.27333 4.83337 8.5 5.06004 8.5 5.33337V8.66671C8.5 8.94004 8.27333 9.16671 8 9.16671Z"
                  fill="#EC6A5E"
              />
              <path
                  d="M8.00016 11.3333C7.9135 11.3333 7.82683 11.3133 7.74683 11.28C7.66683 11.2466 7.5935 11.2 7.52683 11.14C7.46683 11.0733 7.42016 11.0066 7.38683 10.92C7.3535 10.84 7.3335 10.7533 7.3335 10.6666C7.3335 10.58 7.3535 10.4933 7.38683 10.4133C7.42016 10.3333 7.46683 10.26 7.52683 10.1933C7.5935 10.1333 7.66683 10.0866 7.74683 10.0533C7.90683 9.98663 8.0935 9.98663 8.2535 10.0533C8.3335 10.0866 8.40683 10.1333 8.4735 10.1933C8.5335 10.26 8.58016 10.3333 8.6135 10.4133C8.64683 10.4933 8.66683 10.58 8.66683 10.6666C8.66683 10.7533 8.64683 10.84 8.6135 10.92C8.58016 11.0066 8.5335 11.0733 8.4735 11.14C8.40683 11.2 8.3335 11.2466 8.2535 11.28C8.1735 11.3133 8.08683 11.3333 8.00016 11.3333Z"
                  fill="#EC6A5E"
              />
          </svg>
      </div>
  );
}

export default Warning