import React from 'react';
import './style.scss';
import InfoCircle from '../../../Assets/icons/InfoCircle';
import TickIcon from '../../../Assets/icons/TickIcon';
const StatusComponent = ({warning,fail,sucess}) => {
        const status=[ warning || fail || sucess ];
    return (
        <div>
            <div className={warning ? 'status-div warning' : ' status-div sucess'}>
                <div className="icon">
                    {sucess ? <TickIcon/> : <InfoCircle/> }
                </div>
                <div className="status">
                       {status}
                </div>
            </div>
        </div>
    );
};
export default StatusComponent;
