// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyVehivleView {
  padding-inline: 2rem;
  background-color: white;
  height: 90vh;
  overflow-y: auto; }
  .MyVehivleView .MyVehivleView--addVehicle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-block: 3rem; }
    .MyVehivleView .MyVehivleView--addVehicle p {
      font-size: 18px;
      font-weight: 600; }
`, "",{"version":3,"sources":["webpack://./src/Views/AfterAuth/MyVehiclesView/MyVehiclesView.scss"],"names":[],"mappings":"AAAA;EACI,oBAAoB;EACpB,uBAAuB;EACvB,YAAY;EACZ,gBAAgB,EAAA;EAJpB;IAOQ,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,mBAAmB,EAAA;IAV3B;MAYY,eAAe;MACf,gBAAgB,EAAA","sourcesContent":[".MyVehivleView {\n    padding-inline: 2rem;\n    background-color: white;\n    height: 90vh;   \n    overflow-y: auto;\n    // padding-bottom:3rem;\n    .MyVehivleView--addVehicle {\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n        padding-block: 3rem;\n        p {\n            font-size: 18px;\n            font-weight: 600;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
