import React, { Children, useEffect, useState } from 'react';
import './walletlayout.scss';
import Navbar from '../../Components/Common/Navbar/Navbar';
import { Button } from '../../Components/Common';
import { useNavigate } from 'react-router-dom';
import { Pathname } from '../../Router/Pathname';
import Modal from '../../Components/Common/Modal/Modal';
import Wallet2 from '../../Assets/icons/Wallet2';
import StatusComponent from '../../Components/Common/StatusComponent/StatusComponent';
import ModalSucessProgress from '../../Components/Common/ModalSucessProgress/ModalSucessProgress';
import { useDispatch, useSelector } from 'react-redux';
import {
    getWalletBalance,
    refundBalance,
} from '../../Views/AfterAuth/HomeView/redux/homeSlice';
const WalletLayout = (props) => {
    const { children } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [refundModal, setrefundModal] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { walletBalance } = useSelector((store) => store.home);
    const { razorpayRefresh } = useSelector((store) => store.wallet);
    // console.log('home', walletBalance);
    const handleAddMoney = () => {
        navigate(`${Pathname.START_CHARGING}/add-money`);
    };
    const openModal = () => {
        setIsModalOpen(true);
    };

    const handleOpenRefund = async () => {
        // console.log('check');
        const data = await dispatch(refundBalance());
        //   console.log("data--",data)
        setTimeout(() => {
            setIsModalOpen(false);
        }, 2500);
    };

    const handleCloseRefund = () => {
        setrefundModal(false);
    };

    useEffect(() => {
        dispatch(getWalletBalance());
    }, []);

    useEffect(() => {
        if (razorpayRefresh?.success === true) {
            dispatch(getWalletBalance());
        }
    }, [razorpayRefresh]);

    const closeModal = () => {
        setIsModalOpen(false);
    };
    return (
        <div>
            <main className="WalletLayout">
                <section>
                    <p>My Wallet</p>
                    <div className="wallet-card">
                        <p>Current Balance</p>
                        <p>₹{walletBalance?.payload?.balance}</p>
                        <div className="wallet-btn">
                            <Button
                                text={'Add Funds'}
                                className={'WalletLayout--btn'}
                                onClick={handleAddMoney}
                            />
                            {/* <Button
                                text={'Refund'}
                                variant="secondary"
                                className={'WalletLayout--btn WalletLayout-secondary'}
                                onClick={openModal}
                                disabled={walletBalance?.payload?.balance==0}
                            /> */}
                        </div>
                    </div>
                </section>
                {children}
                <Navbar />

                <Modal
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    modalClass="wallet-custom-modal"
                    overlayClass="custom-overlay"
                    closeButtonClass="custom-close-button"
                >
                    <div className="modalClass">
                        <div className="wallet-icon">
                            <Wallet2 />
                        </div>
                        <p className="funds">Refund Balance?</p>
                        <p className="fundInfo">
                            Are you sure you want to refund your wallet balance back to
                            your bank account?
                        </p>
                        <StatusComponent warning="Please note; transferring wallet balance to bank account incurs additional charges. " />
                        <div>
                            <div className="WalletLayout--warning-btn">
                                <Button
                                    className={'addWallet WalletLayout-btn-No '}
                                    text="No"
                                    onClick={closeModal}
                                />
                                <Button
                                    className={'addWallet WalletLayout-btn-Yes'}
                                    text="Yes"
                                    onClick={handleOpenRefund}
                                />
                            </div>
                        </div>
                    </div>
                </Modal>

                <ModalSucessProgress
                    isOpen={refundModal}
                    onClose={handleCloseRefund}
                    text={'Refund Initiated'}
                />
            </main>
        </div>
    );
};

export default WalletLayout;
