// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddMoney {
  padding-inline: 20px;
  position: relative;
  background-color: white; }

.AddMoney--btn {
  display: flex;
  gap: 2rem;
  padding-block: 24px;
  font-weight: 600; }
  .AddMoney--btn p {
    font-size: 18px;
    align-self: center; }

.AddMoney--Input {
  padding-block: 1.5rem; }

.AddMoney--tag {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
`, "",{"version":3,"sources":["webpack://./src/Views/AfterAuth/StartChargeingView/AddMoney/addmoney.scss"],"names":[],"mappings":"AAAA;EACI,oBAAoB;EACpB,kBAAkB;EAClB,uBAAuB,EAAA;;AAE3B;EACI,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,gBAAgB,EAAA;EAJpB;IAMQ,eAAe;IACf,kBAAkB,EAAA;;AAG1B;EACI,qBAAqB,EAAA;;AAEzB;EACI,aAAa;EACb,8BAA8B;EAC9B,eAAe,EAAA","sourcesContent":[".AddMoney {\n    padding-inline: 20px;\n    position: relative;\n    background-color: white;\n}\n.AddMoney--btn {\n    display: flex;\n    gap: 2rem;\n    padding-block: 24px;\n    font-weight: 600;\n    p {\n        font-size: 18px;\n        align-self: center;\n    }\n}\n.AddMoney--Input {\n    padding-block: 1.5rem;\n}\n.AddMoney--tag {\n    display: flex;\n    justify-content: space-between;\n    flex-wrap: wrap;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
