import React from 'react';

const FlashGreen = () => {
    return (
        <div>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7.76645 11.0666H10.3414V17.0666C10.3414 17.95 11.4414 18.3666 12.0248 17.7L18.3331 10.5333C18.8831 9.90831 18.4414 8.93331 17.6081 8.93331H15.0331V2.93331C15.0331 2.04997 13.9331 1.63331 13.3498 2.29997L7.04145 9.46664C6.49979 10.0916 6.94145 11.0666 7.76645 11.0666Z"
                    stroke="#00AA74"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.08333 3.33325H1.25"
                    stroke="#00AA74"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6.25 16.6667H1.25"
                    stroke="#00AA74"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M3.75 10H1.25"
                    stroke="#00AA74"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
}

export default FlashGreen;