import React from 'react';
import './helpandsupport.scss';
import { Pathname } from '../../../../Router/Pathname';
import { useNavigate } from 'react-router-dom';
import ArrowLeft from '../../../../Assets/icons/ArrowLeft';
import LocationIcon from '../../../../Assets/icons/LocationIcon';
import CallCalling from '../../../../Assets/icons/CallCalling';
import SmsIcon from '../../../../Assets/icons/SmsIcon';
const HelpandSupportView = () => {
    const navigate=useNavigate()
    return (
        <main className='HelpandSupportView'>
            <div className="HelpandSupportView--navigate">
                <span onClick={() => navigate(`${Pathname.PROFILE}`)}>
                    <ArrowLeft />
                </span>
                <p>Help and Support</p>
            </div>
                <div className="HelpandSupportView--content">
                <p>You can contact us anytime, from anywhere</p>
                <div>
                    <LocationIcon/>
                    <p>Plot No 171, Rd No 13, Jubilee Hills,Hyderabad, Telangana - 500033.</p>
                </div>
                <div>
                    <CallCalling/>
                    <p>+91 8886064848</p>
                </div>
                <div>
                    <SmsIcon/>
                    <p>support@lioncharge.tech</p>
                </div>
            </div>
        </main>
    );
};

export default HelpandSupportView;
