// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RefundPolicyView {
  padding-inline: 1.5rem;
  height: 100vh;
  background-color: white; }
  .RefundPolicyView-navigate {
    display: flex;
    gap: 2rem;
    padding: 24px 24px 24px 8px;
    font-weight: 600; }
    .RefundPolicyView-navigate p {
      font-size: 18px;
      align-self: center; }
  .RefundPolicyView-content p, .RefundPolicyView-title, .RefundPolicyView ul {
    font-size: 15px;
    line-height: 28px;
    color: #414042;
    text-align: justify;
    padding: 10px 0px; }
  .RefundPolicyView-title strong {
    font-size: 17px; }
  .RefundPolicyView-criteria ul {
    padding: 0 0 0 24px; }
    .RefundPolicyView-criteria ul li {
      padding: 4px 0; }
`, "",{"version":3,"sources":["webpack://./src/Views/AfterAuth/MyProfileView/RefundPolicyView/refundpolicyview.scss"],"names":[],"mappings":"AAAA;EACI,sBAAsB;EACtB,aAAa;EACb,uBAAuB,EAAA;EACvB;IACI,aAAa;IACb,SAAS;IACT,2BAA2B;IAC3B,gBAAgB,EAAA;IAJnB;MAMO,eAAe;MACf,kBAAkB,EAAA;EAG1B;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,mBAAmB;IACnB,iBAAiB,EAAA;EAEpB;IAEO,eAAe,EAAA;EAGvB;IACI,mBAAmB,EAAA;IADtB;MAGO,cAAc,EAAA","sourcesContent":[".RefundPolicyView{\n    padding-inline: 1.5rem;\n    height: 100vh;\n    background-color: white;\n    &-navigate{\n        display: flex;\n        gap: 2rem;\n        padding: 24px 24px 24px 8px;\n        font-weight: 600;\n        p{\n            font-size: 18px;\n            align-self: center;\n        }\n    }\n    &-content p, &-title, ul{\n        font-size: 15px;\n        line-height: 28px;\n        color: #414042;\n        text-align: justify;\n        padding: 10px 0px;\n    }\n    &-title {\n        strong {\n            font-size: 17px;\n        }\n    }\n    &-criteria ul {\n        padding: 0 0 0 24px;\n        li {\n            padding: 4px 0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
