// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserInfoView {
  margin: 4rem 2rem; }
  .UserInfoView-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90dvh; }
  .UserInfoView-heading {
    color: #1e1e1e;
    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
    letter-spacing: 0.02em;
    margin-bottom: 3rem; }
  .UserInfoView-text {
    font-family: Syne;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #1e1e1e;
    margin-top: 2rem; }
`, "",{"version":3,"sources":["webpack://./src/Views/beforeAuth/LoginView/userInfoView.scss"],"names":[],"mappings":"AAAA;EACI,iBAAiB,EAAA;EAEjB;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,aAAa,EAAA;EAEjB;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,mBAAmB,EAAA;EAEvB;IACI,iBAAiB;IACjB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;IACtB,cAAc;IACd,gBAAgB,EAAA","sourcesContent":[".UserInfoView {\n    margin: 4rem 2rem;\n    // background-color: white;\n    &-container{\n        display: flex;\n        flex-direction: column;\n        justify-content: space-between;\n        height: 90dvh;\n    }\n    &-heading {\n        color: #1e1e1e;\n        font-size: 24px;\n        font-weight: 700;\n        line-height: 29px;\n        letter-spacing: 0.02em;\n        margin-bottom: 3rem;\n    }\n    &-text {\n        font-family: Syne;\n        font-size: 18px;\n        font-weight: 700;\n        line-height: 22px;\n        letter-spacing: 0.02em;\n        color: #1e1e1e;\n        margin-top: 2rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
