// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-btn {
  padding: 8px 12px 8px 12px;
  background-color: white;
  outline: none;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  color: #8c8c8c;
  font-size: 12px; }

.active {
  border: none;
  background-color: #e6f7ef;
  color: #00aa74; }
`, "",{"version":3,"sources":["webpack://./src/Components/Common/FilterBtn/style.scss","webpack://./src/Styles/sass/_variables.scss"],"names":[],"mappings":"AACA;EACI,0BAA0B;EAC1B,uBAAuB;EACvB,aAAa;EACb,yBCWoB;EDVpB,mBAAmB;EACnB,cCOoB;EDNpB,eAAe,EAAA;;AAEnB;EACI,YAAY;EACZ,yBAAyB;EACzB,cAAc,EAAA","sourcesContent":["@import '../../../Styles/sass/variables';\n.filter-btn {\n    padding: 8px 12px 8px 12px;\n    background-color: white;\n    outline: none;\n    border: 1px solid $color_grey_200;\n    border-radius: 12px;\n    color: $color_grey_400;\n    font-size: 12px;\n}\n.active {\n    border: none;\n    background-color: #e6f7ef;\n    color: #00aa74;\n}\n","//Primary shades\n$primary_green: #00aa74;\n$primary_light: #e6f7ef;\n$primary_gradient: linear-gradient(109.42deg, #00aa74 0%, #16eba6 100%, #16eba6 100%);\n$primary_gradient1: linear-gradient(108deg, #00AA74 -78.7%, #1E1E1E 59.58%);\n\n//secondary shades\n$secondary_dark: #012515;\n\n//semantic\n$semantic_red: #ec6a5e;\n$semantic_orange: #dfa102;\n\n//Grey Shades\n$color_grey_400: #8c8c8c;\n$color_grey_300: #b3b3b3;\n$color_grey_200: #d9d9d9;\n$color_grey_100: #f2f2f2;\n\n//Black Shades\n$color_black_400: #000000;\n$color_black_300: #1e1e1e;\n$color_black_200: #333333;\n$color_black_100: #4c4c4c;\n\n//green shades\n$color_green_100: #e6f7ef;\n$color_green_150: #c2ead8;\n$color_green_200: #9adcbf;\n$color_green_300: #6ecfa6;\n$color_green_400: #48c493;\n$color_green_500: #09ba80;\n$color_green_600: #009766;\n$color_green_700: #00865a;\n$color_green_800: #006643;\n\n//Fontstyles\n$main_font: 'Syne', sans-serif;\n$secondary_font: 'Outfit', sans-serif;\n$tertiary_font: 'Space Grotesk', sans-serif;\n$text_size: 1rem;\n$dashboardtext: #575757;\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
