import React from 'react';

const ClockIcon = () => {
    return (
        <div>
            <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.99984 13.2707C3.54067 13.2707 0.729004 10.459 0.729004 6.99984C0.729004 3.54067 3.54067 0.729004 6.99984 0.729004C10.459 0.729004 13.2707 3.54067 13.2707 6.99984C13.2707 10.459 10.459 13.2707 6.99984 13.2707ZM6.99984 1.604C4.02484 1.604 1.604 4.02484 1.604 6.99984C1.604 9.97484 4.02484 12.3957 6.99984 12.3957C9.97484 12.3957 12.3957 9.97484 12.3957 6.99984C12.3957 4.02484 9.97484 1.604 6.99984 1.604Z"
                    fill="#8C8C8C"
                />
                <path
                    d="M9.16421 9.29253C9.08837 9.29253 9.01254 9.27503 8.94254 9.22836L7.13421 8.14919C6.68504 7.88086 6.35254 7.29169 6.35254 6.77253V4.38086C6.35254 4.14169 6.55087 3.94336 6.79004 3.94336C7.02921 3.94336 7.22754 4.14169 7.22754 4.38086V6.77253C7.22754 6.98253 7.40254 7.29169 7.58337 7.39669L9.39171 8.47586C9.60171 8.59836 9.66587 8.86669 9.54337 9.07669C9.45587 9.21669 9.31004 9.29253 9.16421 9.29253Z"
                    fill="#8C8C8C"
                />
            </svg>
        </div>
    );
};
export default ClockIcon;
