export function register() {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker
                .register('/service-worker.js')
                .then((registration) => {
                    console.log(
                        'Service Worker registered with scope:',
                        registration.scope
                    );

                    if (registration.installing || registration.waiting) {
                        return;
                    }

                    if (registration.active) {
                        registration.update();
                    } else {
                        showNotification();
                    }
                })
                .catch((error) => {
                    console.error('Service Worker registration failed:', error);
                });
        });
    }
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then((registration) => {
            registration.unregister();
        });
    }
}

export function showNotification() {
    if (Notification.permission === 'granted') {
        new Notification('Welcome to Your App!', {
            body: 'Thank you for installing the app.',
            icon: '/favicon.png',
        });
    } else if (Notification.permission !== 'denied') {
        Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
                showNotification();
            }
        });
    }
}
