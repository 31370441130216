import React, { useEffect, useState } from 'react';
import './WalletView.scss';
import WalletLayout from '../../../Layouts/WalletLayout/WalletLayout';
import ThreeLine from '../../../Assets/icons/ThreeLine';
import MyTransactioncard from '../../../Components/Common/MyTransactionCard/MyTransactioncard';
import FilterTransactions from '../../../Components/Common/FilterTransactions/FilterTransactions';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    getWalletTransactions,
    razorpayStatusRefresh,
    resetWalletTransactions,
    setRazorpayRefresh,
    setWalletTransactions,
} from './redux/walletSlice';
import { Button } from '../../../Components/Common';
import InfiniteScroll from 'react-infinite-scroller';
import EmptyTrans from '../../../Assets/icons/EmptyTrans';
import { ToastContainer, toast } from 'react-toastify';
import { getHomeDetails } from '../HomeView/redux/homeSlice';

const WalletView = () => {
    const { walletTransactions, hasMore, loading, page, success } = useSelector(
        (store) => store.wallet
    );
    // console.log('wallet', walletTransactions);
    const [openFilter, setOpenFilter] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);

    const [refetchPaymentId, setRefetchPaymentId] = useState(null);
    const dispatch = useDispatch();
    const handleFilterOpen = () => {
        setOpenFilter(true);
    };
    const handlefilterClose = () => {
        setOpenFilter(false);
    };

    useEffect(async () => {
        let data = await dispatch(getHomeDetails());
        //   if (!data?.payload?.payload?.is_onboarded) {
        //         navigate('/userinfo');
        //     }
    }, []);

    useEffect(() => {
        //    if (hasMore) {
        dispatch(getWalletTransactions({ page: 0 }));
        dispatch(resetWalletTransactions());
        //    }
    }, [dispatch]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const loadMore = () => {
        if (!loading && hasMore) {
            dispatch(getWalletTransactions({ page: page }));
        }
    };

    const handleReload = async (id) => {
        // dispatch(resetWalletTransactions());

        setRefetchPaymentId(id);
        dispatch(setRazorpayRefresh(null));

        let res = await dispatch(razorpayStatusRefresh(id));
        console.log('res-->', res);
        if (res?.payload?.success) {
            setRefetchPaymentId(null);

            dispatch(setRazorpayRefresh(res?.payload));
            toast(`Payment Status - ${res?.payload?.payload?.paymentStatus}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                style: {
                    fontSize: '20px',
                },
            });

            const response = walletTransactions.map((transaction) => {
                console.log('transaction===>', transaction, res);
                if (transaction.order_id === id) {
                    return res?.payload?.payload?.tran;
                } else {
                    return transaction;
                }
            });

            console.log('res =====>', response);

            dispatch(setWalletTransactions([...response]));
            // dispatch(getWalletTransactions({ page: 0 }));
            // dispatch(resetWalletTransactions());
            // window.location.reload();
            // dispatch(getWalletTransactions({ page: 0 }));
        } else {
            setRefetchPaymentId(null);
            toast(res?.payload?.message, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                style: {
                    fontSize: '20px',
                },
            });
        }
    };

    return (
        <WalletLayout>
            <section className="WalletView">
                <div className="WalletView--filter-btn">
                    <h1>My Transactions</h1>
                    <span onClick={handleFilterOpen}>{/* <ThreeLine /> */}</span>
                </div>
                <div className="WalletView--transaction-history">
                    {/* <h2>Today</h2> */}
                    {!walletTransactions.length ? (
                        <>
                            <div style={{ textAlign: 'center', marginTop: '4rem' }}>
                                <EmptyTrans />
                            </div>
                        </>
                    ) : (
                        <>
                            <InfiniteScroll
                                pageStart={0}
                                loadMore={loadMore}
                                hasMore={hasMore}
                                loader={
                                    <div className="loader" key={0}>
                                        {/* Loading... */}
                                    </div>
                                }
                            >
                                {walletTransactions?.map((el, ind) => {
                                    // console.log('el--', el);
                                    return (
                                        <div key={ind}>
                                            <MyTransactioncard
                                                check_invoice={el.invoice_type}
                                                transac_mode={el.mode}
                                                handleReload={() =>
                                                    handleReload(el.order_id)
                                                }
                                                requestedReloadPaymentId={
                                                    refetchPaymentId
                                                }
                                                paymentId={el?.external_payment_id}
                                                orderId={el?.order_id}
                                                amount={el?.amount}
                                                station={
                                                    el?.chargingSession?.chargingStation
                                                        ?.station_name
                                                }
                                                mode={
                                                    el?.chargingSession?.charge_duration
                                                        ? el?.chargingSession
                                                              ?.charge_duration
                                                        : '-'
                                                }
                                                lastChargingTime={el?.updatedAt}
                                                element={
                                                    el?.chargingSession
                                                        ?.ocpp_transaction_id
                                                }
                                                txn_status={el.txn_status}
                                                transactionTime={el?.updatedAt}
                                                id={el?.id}
                                            />
                                        </div>
                                    );
                                })}
                            </InfiniteScroll>
                        </>
                    )}

                    {/* <MyTransactioncard amount={'878'} /> */}
                </div>
                <ToastContainer />
            </section>
            <FilterTransactions modalOpen={openFilter} modalClose={handlefilterClose} />
        </WalletLayout>
    );
};

export default WalletView;
