import React, { Suspense, useEffect } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ToastContainer } from 'react-toastify';

import { router } from './Router';
import LargeScreenMessage from './Components/LargeScreen/LargeScreenMessage';
import MobileView from './Components/MobileView/MobileView';
import { ReactGA } from "./Utils/ga4";

import './app.scss';

const App = () => {
    // React.useEffect(() => {
    //     ReactGA.sendEvent({
    //         label: "test app",
    //         action: "button click",
    //         category: "test"
    //     })
    // }, [])

    const webURL = new URL(window.location.href);
    const connector_id = webURL.searchParams.get('connector_id');
    if (connector_id) {
        sessionStorage.setItem('connector_id', connector_id);
    }
    // console.log('connector_id: ', connector_id);

    // const navigate = useNavigate()
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    });

    //   useEffect(() => {
    //     if (window.innerWidth >= 768) {
    //       navigate.push('/largescreen');
    //     }
    //   }, [navigate]);

    // const isMobileOnly =

    return (
        <QueryClientProvider client={queryClient}>
            <div>
                <LargeScreenMessage />
            </div>
            <Suspense fallback={<h1>Loading....</h1>}>
                <MobileView>
                    <RouterProvider router={router} />
                    <ToastContainer />
                </MobileView>
            </Suspense>
            {/* <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} /> */}
        </QueryClientProvider>
    );
};

export default App;
