// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MiniStationCard {
  display: flex;
  padding: 16px 16px 16px 12px;
  align-items: center;
  border-radius: 12px;
  gap: 8px;
  border: 1px solid var(--neutral-7, #f2f2f2); }
  .MiniStationCard--disabled {
    background-color: #f2f2f2; }
  .MiniStationCard__RightContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%; }
  .MiniStationCard__RightTop {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .MiniStationCard__Badges {
    margin-bottom: 12px; }
  .MiniStationCard__Title {
    color: var(--neutral-1, #1e1e1e);
    font-family: Outfit;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal; }
  .MiniStationCard__SubTitle {
    overflow: hidden;
    color: #333;
    text-overflow: ellipsis;
    font-family: Outfit;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal; }
  .MiniStationCard__Img {
    width: 68px;
    height: 56px; }
`, "",{"version":3,"sources":["webpack://./src/Components/MiniStationCard/MiniStationCard.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,4BAA4B;EAC5B,mBAAmB;EACnB,mBAAmB;EACnB,QAAQ;EACR,2CAA2C,EAAA;EAE3C;IACI,yBAAyB,EAAA;EAG7B;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,WAAW,EAAA;EAEf;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB,EAAA;EAEvB;IACI,mBAAmB,EAAA;EAEvB;IACI,gCAAgC;IAChC,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB,EAAA;EAEvB;IACI,gBAAgB;IAChB,WAAW;IACX,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB,EAAA;EAEvB;IACI,WAAW;IACX,YAAY,EAAA","sourcesContent":[".MiniStationCard {\n    display: flex;\n    padding: 16px 16px 16px 12px;\n    align-items: center;\n    border-radius: 12px;\n    gap: 8px;\n    border: 1px solid var(--neutral-7, #f2f2f2);\n    // height: 95px;\n    &--disabled {\n        background-color: #f2f2f2;\n    }\n\n    &__RightContent {\n        display: flex;\n        flex-direction: column;\n        gap: 10px;\n        width: 100%;\n    }\n    &__RightTop {\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n    }\n    &__Badges {\n        margin-bottom: 12px;\n    }\n    &__Title {\n        color: var(--neutral-1, #1e1e1e);\n        font-family: Outfit;\n        font-size: 1.4rem;\n        font-style: normal;\n        font-weight: 400;\n        line-height: normal;\n    }\n    &__SubTitle {\n        overflow: hidden;\n        color: #333;\n        text-overflow: ellipsis;\n        font-family: Outfit;\n        font-size: 1.2rem;\n        font-style: normal;\n        font-weight: 300;\n        line-height: normal;\n    }\n    &__Img {\n        width: 68px;\n        height: 56px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
