import React, { useEffect, useState } from 'react';
import './selectview.scss';
import ArrowLeft from '../../../../Assets/icons/ArrowLeft';
import BasicCard from '../../../../Components/HomeCards/BasicCard/BasicCard';
import Flash from '../../../../Assets/icons/Flash';
import { Button } from '../../../../Components/Common';
import { useLocation, useNavigate } from 'react-router-dom';
import { Pathname } from '../../../../Router/Pathname';
import FlashGreen from '../../../../Assets/icons/FlashGreen';
import FlasGray from '../../../../Assets/icons/FlasGray';
import { useDispatch, useSelector } from 'react-redux';
import { getConnector } from '../../HomeView/redux/homeSlice';
import { useSearchParams } from 'react-router-dom';
import { ReactGA } from "../../../../Utils/ga4";

const SelectView = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { connectorData } = useSelector((store) => store?.home);

    /*************** Code for redirection ************* */

    // CASE 1 extract the connector id from url
    const [searchParam] = useSearchParams();
    const URLconnector_id = searchParam.get('connector_id');
    console.log('cpId: ', URLconnector_id);

    // CASE 2 if we are connector id directly
    const scannedURL = (chargingPointId) => {
        var urlObject = new URL(chargingPointId);
        return urlObject.searchParams.get('connector_id');
    };

    const chargingPointId = state?.chargingPointId?.includes('connector_id')
        ? scannedURL(state?.chargingPointId)
        : state?.chargingPointId;

    //selecting a case
    const cpId = URLconnector_id ? URLconnector_id : chargingPointId;
    console.log('cpId: ', cpId);

    //setting the default select card
    const [selectedCard, setSelectedCard] = useState(cpId);

    const handleCardSelect = (cardId) => {
        setSelectedCard(cardId);
    };

    useEffect(() => {
        ReactGA.sendPageView("Connector selection page");
    }, [])

    useEffect(() => {
        dispatch(getConnector(cpId));
        sessionStorage.setItem('connector_id', cpId);
    }, [cpId]);

    /************************************************************** */

    return (
        <section className="SelectView">
            <div className="SelectView--Select">
                <span onClick={() => navigate(`${Pathname.START_CHARGING}`)}>
                    <ArrowLeft />
                </span>{' '}
                <p>Select Connector</p>
            </div>
            {connectorData?.payload?.length ? (
                <div className="SelectView--text">
                    <p>
                        Please select the type of connector your EV has to start charging
                    </p>
                </div>
            ) : (
                <div className="SelectView--text">
                    <p>Please scan the QR code again</p>
                </div>
            )}
            <div className="SelectView--card">
                <div className="connectors">
                    {connectorData?.payload?.length
                        ? connectorData?.payload?.map((el) => {
                              return (
                                  <div key={el.id}>
                                      <div>
                                          <BasicCard
                                              icon={<FlasGray />}
                                              id={el.id}
                                              isSelected={selectedCard === el.id}
                                              onSelect={handleCardSelect}
                                              heading1={el?.connectorName}
                                              heading2={el?.standard}
                                          />
                                      </div>
                                  </div>
                              );
                          })
                        : null}
                </div>
            </div>
            {connectorData?.payload?.length ? (
                <div
                    style={{ position: 'absolute', bottom: '2rem' }}
                    className="SelectView--btn"
                    onClick={() =>
                        // navigate(`${Pathname.START_CHARGING}/intiate-charging`)
                        navigate(Pathname.ENERGY_BASED, {
                            state: {
                                connector_id:cpId 
                            },
                        })
                    }
                >
                    <Button text="Next" />
                </div>
            ) : null}
        </section>
    );
};

export default SelectView;
