import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Server from '../../../../Server';

const initialState = {
    loading: false,
    profileInfo: null,
    error: null,
    updatedDetails: null,
};

export const getProfileDetails = createAsyncThunk('profile/userInformation', async () => {
    try {
        const res = await Server.get('/api/v1/csms/ocpp/customer/get', true);
        return res
    } catch (error) {
        console.log('error', error);
    }
});

export const updateProfileDetails = createAsyncThunk(
    'profile/updateUserInformation',
    async (data) => {
        try {
            const response = await Server.post('/api/v1/csms/ocpp/customer/update', data, true);
            return response
        } catch (error) {
            console.log('error', error);
        }
    }
);

const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProfileDetails.pending, (state) => {
                state.error = '';
                state.loading = true;
            })
            .addCase(getProfileDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.profileInfo = action.payload;
                state.error = '';
            })
            .addCase(getProfileDetails.rejected, (state, action) => {
                state.loading = false;
                state.otpInfo = null;
                state.error = action.error.message;
            })
            .addCase(updateProfileDetails.pending, (state) => {
                state.error = '';
                state.loading = true;
            })
            .addCase(updateProfileDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.profileInfo = action.payload;
                state.updatedDetails = action.payload;
                state.error = '';
            })
            .addCase(updateProfileDetails.rejected, (state, action) => {
                state.loading = false;
                state.updatedDetails = null;
                state.error = action.error.message;
            });
    },
});

export default profileSlice.reducer;
