// SelectableCard.js
import React from 'react';
import './SelectableCard.scss';

function SelectableCard({ title, onSelect, selected,icon,image }) {
    const handleClick = () => {
        onSelect(title);
    };

    return (
        <div
            className={`selectable-card ${selected ? 'selectedBox' : ''}`}
            onClick={handleClick}
        >
            <div>
                <p>{title}</p>
            </div>
            <div>
                <div>{icon}</div>
                <div><img src={image} alt="" srcset="" /></div>
            </div>
            
        </div>
    );
}

export default SelectableCard;
