import React from 'react';
import Navbar from '../../Components/Common/Navbar/Navbar';
import HomeHeader from '../../Components/Layout/HomeHeader/HomeHeader';

const HomeLayout = (props) => {
    const { children } = props;
    return (
        <main className="HomeLayout">
            <HomeHeader/>
            {children}
            <Navbar />
        </main>
    );
};

export default HomeLayout;
