import React from 'react';
import Navbar from '../../Components/Common/Navbar/Navbar';
import './myprofilelayout.scss';
    import EditBtn from '../../Assets/icons/EditBtn';
import { useNavigate } from 'react-router-dom';
import { Pathname } from '../../Router/Pathname';
const MyProfilelayout = (props) => {
    const { children } = props;
    const navigate=useNavigate()
    return (
        <main className="MyProfilelayout--main-div">
            <section className='MyProfilelayout--header'>
            <div className="MyProfilelayout--addVehicle">
                <p>My Profile</p>
                <span style={{cursor:"pointer"}} onClick={() => navigate(`${Pathname.PROFILE}/edit-profile`)}>
                    <EditBtn/>
                </span>
            </div>
            </section>
            <section className='MyProfilelayout--body'>
            {children}
            </section>
            <Navbar />
        </main>
    );
};

export default MyProfilelayout;
