import React from 'react'
import Card from '../../Common/Card/Card';
import './PluginCard.scss'

const PluginCard = ({ subheading, icon1, icon2, className, ...props }) => {
    return (
        <Card className={`PluginCard ${className}`}>
            <div >

            <div className="header">
                <h4 className="heading">{icon2}</h4>
                <p className="subheading">{subheading}</p>
            </div>
            <div className="icon">{icon1}</div>
            </div>
        </Card>
    );
};

export default PluginCard