import React from 'react';

const PaymentReceiptIcon = () => {
    return (
        <div>
            <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.99984 19.4582C9.34984 19.4582 8.7165 19.1248 8.28317 18.5415L7.4415 17.4165C7.2665 17.1832 7.03317 17.0498 6.78317 17.0332C6.53317 17.0248 6.28317 17.1332 6.08317 17.3415L5.60817 16.9165L6.0665 17.3415C4.8665 18.6248 3.9415 18.5248 3.49984 18.3498C3.04984 18.1748 2.2915 17.5998 2.2915 15.7498V6.3665C2.2915 2.6665 3.35817 1.5415 6.84984 1.5415H13.1498C16.6415 1.5415 17.7082 2.6665 17.7082 6.3665V15.7498C17.7082 17.5915 16.9498 18.1665 16.4998 18.3498C16.0582 18.5248 15.1415 18.6248 13.9332 17.3415C13.7332 17.1248 13.4915 17.0082 13.2248 17.0332C12.9748 17.0498 12.7332 17.1832 12.5582 17.4165L11.7165 18.5415C11.2832 19.1248 10.6498 19.4582 9.99984 19.4582ZM6.73317 15.7748C6.7665 15.7748 6.80817 15.7748 6.8415 15.7748C7.45817 15.8082 8.0415 16.1332 8.43317 16.6582L9.27484 17.7832C9.68317 18.3248 10.3082 18.3248 10.7165 17.7832L11.5582 16.6582C11.9582 16.1332 12.5332 15.8082 13.1582 15.7748C13.7748 15.7415 14.3915 15.9998 14.8415 16.4832C15.4748 17.1582 15.8915 17.2415 16.0332 17.1832C16.2332 17.0998 16.4498 16.6165 16.4498 15.7498V6.3665C16.4498 3.35817 15.9248 2.7915 13.1415 2.7915H6.84984C4.0665 2.7915 3.5415 3.35817 3.5415 6.3665V15.7498C3.5415 16.6248 3.75817 17.1082 3.95817 17.1832C4.09984 17.2415 4.5165 17.1582 5.14984 16.4832C5.59984 16.0248 6.15817 15.7748 6.73317 15.7748Z"
                    fill="#00AA74"
                />
                <path
                    d="M13.3332 6.9585H6.6665C6.32484 6.9585 6.0415 6.67516 6.0415 6.3335C6.0415 5.99183 6.32484 5.7085 6.6665 5.7085H13.3332C13.6748 5.7085 13.9582 5.99183 13.9582 6.3335C13.9582 6.67516 13.6748 6.9585 13.3332 6.9585Z"
                    fill="#00AA74"
                />
                <path
                    d="M12.5 10.2915H7.5C7.15833 10.2915 6.875 10.0082 6.875 9.6665C6.875 9.32484 7.15833 9.0415 7.5 9.0415H12.5C12.8417 9.0415 13.125 9.32484 13.125 9.6665C13.125 10.0082 12.8417 10.2915 12.5 10.2915Z"
                    fill="#00AA74"
                />
            </svg>
        </div>
    );
};

export default PaymentReceiptIcon;
