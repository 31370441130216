import React from 'react';

export default function SmsIcon() {
    return (
        <div>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M14.1665 17.7087H5.83317C2.7915 17.7087 1.0415 15.9587 1.0415 12.917V7.08366C1.0415 4.04199 2.7915 2.29199 5.83317 2.29199H14.1665C17.2082 2.29199 18.9582 4.04199 18.9582 7.08366V12.917C18.9582 15.9587 17.2082 17.7087 14.1665 17.7087ZM5.83317 3.54199C3.44984 3.54199 2.2915 4.70033 2.2915 7.08366V12.917C2.2915 15.3003 3.44984 16.4587 5.83317 16.4587H14.1665C16.5498 16.4587 17.7082 15.3003 17.7082 12.917V7.08366C17.7082 4.70033 16.5498 3.54199 14.1665 3.54199H5.83317Z"
                    fill="#00AA74"
                />
                <path
                    d="M9.99998 10.725C9.29998 10.725 8.59165 10.5083 8.04998 10.0666L5.44164 7.98331C5.17498 7.76664 5.12498 7.37497 5.34165 7.10831C5.55831 6.84164 5.94998 6.79164 6.21665 7.00831L8.82497 9.09164C9.45831 9.59998 10.5333 9.59998 11.1666 9.09164L13.775 7.00831C14.0416 6.79164 14.4416 6.83331 14.65 7.10831C14.8666 7.37497 14.825 7.77498 14.55 7.98331L11.9416 10.0666C11.4083 10.5083 10.7 10.725 9.99998 10.725Z"
                    fill="#00AA74"
                />
            </svg>
        </div>
    );
}
