import React, { useEffect } from 'react';
import GasStation from '../../../../../Assets/pngIcon/GasStation.png';
import './Stationbanner.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getConnectorDetails } from '../Redux/action';
import { useLocation } from 'react-router';

const Stationbanner = () => {
    const dispatch = useDispatch();
    const { energy } = useSelector((store) => store);
    const { ConnectorDetails } = energy;
    const { state } = useLocation(); 
    const connector_id = state?.connector_id;
    const charging_point_name = state?.charging_point_name;

    useEffect(() => {
        if (connector_id) {
            dispatch(getConnectorDetails(connector_id));
        }
    }, [connector_id]);
    return (
        <div className="EnergyCalculation__Banner">
            <div className="EnergyCalculation__LeftContent">
                <img src={GasStation} className="EnergyCalculation__Img" />
            </div>
            <div className="EnergyCalculation__MiddleContent">
                <div className="EnergyCalculation__Title">
                    {
                        ConnectorDetails?.connector_data?.charging_point?.charging_station
                            ?.station_name
                    }
                </div>
                <div className="EnergyCalculation__SubTitle">
                    {
                        // ConnectorDetails?.connector_data?.connector_meta_model
                        //     ?.connector_meta?.io_type
                        charging_point_name
                    }{' '}
                    |{' '}
                    {
                        ConnectorDetails?.connector_data?.connector_meta_model
                            ?.connector_meta?.standard
                    }
                </div>
            </div>
            <div className="EnergyCalculation__RightContent">
                <div className="EnergyCalculation__Amount">
                    <span className="EnergyCalculation__SubTitle">Tariff</span>
                    <span>{`₹${
                        ConnectorDetails?.connector_data?.charging_point?.tariff
                            ?.value_per_kwh ?? 0
                    }/kW`}</span>
                    <span className="EnergyCalculation__SubTitle">+GST</span>
                </div>
            </div>
        </div>
    );
};

export default Stationbanner;
