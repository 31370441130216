import React from 'react';
const CloseIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                fill="#8C8C8C"
            />
            <path
                d="M12.7063 11.6463L12.3528 11.9999L12.7063 12.3534L15.0063 14.6534C15.101 14.7482 15.101 14.9116 15.0063 15.0063C14.955 15.0576 14.8944 15.0799 14.8299 15.0799C14.7653 15.0799 14.7047 15.0576 14.6534 15.0063L12.3534 12.7063L11.9999 12.3528L11.6463 12.7063L9.34631 15.0063C9.29502 15.0576 9.23441 15.0799 9.16986 15.0799C9.10532 15.0799 9.04471 15.0576 8.99342 15.0063C8.89868 14.9116 8.89868 14.7482 8.99342 14.6534L11.2934 12.3534L11.647 11.9999L11.2934 11.6463L8.99342 9.34631C8.89868 9.25157 8.89868 9.08815 8.99342 8.99342C9.08815 8.89868 9.25157 8.89868 9.34631 8.99342L11.6463 11.2934L11.9999 11.647L12.3534 11.2934L14.6534 8.99342C14.7482 8.89868 14.9116 8.89868 15.0063 8.99342C15.101 9.08815 15.101 9.25157 15.0063 9.34631L12.7063 11.6463Z"
                fill="white"
                stroke="white"
            />
        </svg>
    );
};
export default CloseIcon;
