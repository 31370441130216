import React from 'react';
import './ProgressBar.scss'; // Create a CSS file for styling

const ProgressBar = ({ percentage = 0, value, gifUrl }) => {
    // Ensure that the percentage value does not exceed 100%
    const limitedPercentage = Math.min(100, Math.max(0, percentage));

    const radius = 40; // Radius of the circular progress bar
    const circumference = 2 * Math.PI * radius; // Calculate the circumference

    // Calculate the stroke-dashoffset based on the percentage, starting from the top
    const strokeDashoffset = circumference * (1 - limitedPercentage / 100);

    // Apply the glowing class when the percentage is 100% or when a value is provided
    const glowingClass = limitedPercentage === 100 || value || gifUrl ? 'glow' : '';

    return (
        <div className="circular-progress-bar">
            {/* <div className={`tooltip`}>Charging in progress</div>  */}
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 100 100"
                className="ProgressBar__Svg"
            >
                <circle
                    className="progress-background"
                    cx="50"
                    cy="50"
                    r={radius}
                    fill="#00AA74"
                />
                <circle
                    className={`progress ${glowingClass}`}
                    cx="50"
                    cy="50"
                    r={radius}
                    fill="none"
                    stroke={limitedPercentage === 100 ? '#00AA74' : '#ff8635'} // Change the color of progress
                    strokeWidth="8" // Change the stroke width as needed
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                />
                {/* Center the text element horizontally */}
                <text
                    style={{ fontSize: '14px', transform: 'rotate(90deg)' }}
                    x="50%"
                    y="-50%"
                    textAnchor="middle" // Center horizontally
                    dy=".3em" // Adjust the vertical alignment
                    fill="white" // Text color
                    // transform="rotate(270 50 50)"
                >
                    {value ? (
                        <tspan className="value">{value}</tspan>
                    ) : percentage ? (
                        <tspan>{limitedPercentage}%</tspan>
                    ) : null}
                </text>
                {/* Add an image in the center */}
                {gifUrl && (
                    <image
                        x="25%" // Adjust the x-coordinate to center the image
                        y="25%" // Adjust the y-coordinate to center the image
                        width="50%" // Adjust the width of the image
                        height="50%" // Adjust the height of the image
                        href={gifUrl}
                        transform="rotate(90 50 50)" // Provide the URL of the GIF
                    />
                )}
            </svg>
        </div>
    );
};

export default ProgressBar;

// use
//    <CircularProgressBar
//        // percentage={count}
//        value={'00:00'}
//    />;
