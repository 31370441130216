import React from 'react';

const InformationXL = () => {
    return (
        <div>
            <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M14.3333 3.26676C15.2667 2.48009 16.7733 2.48009 17.68 3.26676L19.7867 5.06676C20.1867 5.40009 20.9467 5.68009 21.48 5.68009H23.7467C25.16 5.68009 26.32 6.84009 26.32 8.25342V10.5201C26.32 11.0534 26.6 11.8001 26.9333 12.2001L28.7333 14.3068C29.52 15.2401 29.52 16.7468 28.7333 17.6534L26.9333 19.7601C26.6 20.1601 26.32 20.9068 26.32 21.4401V23.7068C26.32 25.1201 25.16 26.2801 23.7467 26.2801H21.48C20.9467 26.2801 20.2 26.5601 19.8 26.8934L17.6933 28.6934C16.76 29.4801 15.2533 29.4801 14.3467 28.6934L12.24 26.8934C11.84 26.5601 11.08 26.2801 10.56 26.2801H8.22667C6.81334 26.2801 5.65334 25.1201 5.65334 23.7068V21.4268C5.65334 20.9068 5.38667 20.1468 5.05334 19.7601L3.25334 17.6401C2.48001 16.7201 2.48001 15.2268 3.25334 14.3068L5.05334 12.1868C5.38667 11.7868 5.65334 11.0401 5.65334 10.5201V8.26676C5.65334 6.85342 6.81334 5.69342 8.22667 5.69342H10.5333C11.0667 5.69342 11.8133 5.41342 12.2133 5.08009L14.3333 3.26676Z"
                    stroke="#EC6A5E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16 10.8398V17.2798"
                    stroke="#EC6A5E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.9927 21.3335H16.0047"
                    stroke="#EC6A5E"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default InformationXL;
