import React, { useState, useEffect } from 'react';

const ReverseTimer = ({ onResendOTP, startTimer }) => {
    const [timer, setTimer] = useState(null);

    useEffect(() => {
        let countdown;

        if (startTimer && timer !== null && timer > 0) {
            countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            clearInterval(countdown);
        }

        return () => {
            clearInterval(countdown);
        };
    }, [timer, startTimer]);

    useEffect(() => {
        if (startTimer) {
            setTimer(60);
        }
    }, [startTimer]);

    const handleResendOTP = () => {
        if (timer === 0) {
            setTimer(60);
            onResendOTP();
        }
    };

    return (
        <div>
            {timer === 0 ? (
                <p
                    style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '18px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        color: '#00AA74',
                        cursor:"pointer"
                    }}
                    onClick={handleResendOTP}
                >
                    Resend OTP
                </p>
            ) : (
                <p
                    style={{
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '18px',
                        letterSpacing: '0em',
                        textAlign: 'left',
                        color: '#333333',
                    }}
                >
                    Resend code in {timer}
                </p>
            )}
        </div>
    );
};

export default ReverseTimer;
