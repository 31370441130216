import React from 'react';

const GreyGasIcon = () => {
    return (
        <>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={36}
                height={36}
                viewBox="0 0 36 36"
                fill="none"
            >
                <rect width={36} height={36} rx={18} fill="#F2F2F2" />
                <path
                    d="M26.6165 15.7753L24.9498 14.942C24.6415 14.792 24.2582 14.9087 24.1082 15.217C23.9498 15.5337 24.0748 15.9087 24.3832 16.0587L25.7082 16.717V20.7087L22.5832 20.717V12.167C22.5832 10.5003 21.4665 9.66699 20.0832 9.66699H13.4165C12.0332 9.66699 10.9165 10.5003 10.9165 12.167V25.7087H9.6665C9.32484 25.7087 9.0415 25.992 9.0415 26.3337C9.0415 26.6753 9.32484 26.9587 9.6665 26.9587H23.8332C24.1748 26.9587 24.4582 26.6753 24.4582 26.3337C24.4582 25.992 24.1748 25.7087 23.8332 25.7087H22.5832V21.967L26.3332 21.9587C26.6832 21.9587 26.9582 21.6753 26.9582 21.3337V16.3337C26.9582 16.1003 26.8248 15.8837 26.6165 15.7753ZM12.9998 13.742C12.9998 12.5837 13.7082 12.167 14.5748 12.167H18.9332C19.7915 12.167 20.4998 12.5837 20.4998 13.742V14.767C20.4998 15.917 19.7915 16.3337 18.9248 16.3337H14.5748C13.7082 16.3337 12.9998 15.917 12.9998 14.7587V13.742ZM13.4165 18.2087H15.9165C16.2582 18.2087 16.5415 18.492 16.5415 18.8337C16.5415 19.1753 16.2582 19.4587 15.9165 19.4587H13.4165C13.0748 19.4587 12.7915 19.1753 12.7915 18.8337C12.7915 18.492 13.0748 18.2087 13.4165 18.2087Z"
                    fill="#B3B3B3"
                />
            </svg>
        </>
    );
};

export default GreyGasIcon;
