import React, { useEffect, useState } from 'react';
import './input.scss';

const Input = ({
    label,
    setAmount,
    sumValue,
    onValueChange,
    type,
    maxLength,
    defaultValue,
    value,
    required,
    suggestAm,
    errorMessage,
    validate,
}) => {
    const [isFocused, setIsFocused] = useState(false);

    const [inputValue, setInputValue] = useState(value || defaultValue || '');

    useEffect(() => {
        setInputValue(value || defaultValue || '');
    }, [value, defaultValue]);

    useEffect(() => {
        const handleKeyPress = (e) => {
            if (setAmount && e.key === 'Backspace') {
                // setInputValue("");
                setAmount('');
            }
        };
        window.addEventListener('keydown', handleKeyPress);
        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = (e) => {
        setIsFocused(e.target.value !== '');
    };

    const handleChange = (e) => {
        if (maxLength && e.target.value.length > maxLength) {
            return;
        }
        if (type === 'number') {
            const regex = /^[0-9\b]+$/;
            if (e.target.value === '' || regex.test(e.target.value)) {
                setInputValue(e.target.value);
                if (onValueChange) {
                    onValueChange(e.target.value);
                }
            }
            return;
        }
        setInputValue(e.target.value);
        if (onValueChange) {
            onValueChange(e.target.value);
        }
    };

    return (
        <div
            className={`Input__input-container ${
                isFocused || inputValue
                    ? 'Input__focused'
                    : '' || suggestAm
                    ? 'Input__focused'
                    : ''
            }`}
        >
            <div>
                <label
                    className={`Input__placeholder-label ${
                        isFocused || (inputValue && isFocused) || suggestAm
                            ? 'Input__focused'
                            : ''
                    }`}
                >
                    {label} {required && <span style={{ color: '#ec6a5e' }}>*</span>}
                </label>
            </div>
            <input
                type={type}
                value={sumValue ? sumValue : inputValue}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={handleChange}
                maxLength={maxLength}
                required={required}
                className="Input__InputField"
                inputMode={type === 'number' ? 'numeric' : ''}
            />
            {required && inputValue === '' && (
                <span
                    style={{ color: '#ec6a5e', margin: '5px 0' }}
                    className="Input__required-message"
                >
                    This field is required.
                </span>
            )}
            <div className="Input__validation">
                {errorMessage && (
                    <span
                        style={{ color: '#ec6a5e', margin: '5px 0' }}
                        className="Input__required-message"
                    >
                        {errorMessage ? errorMessage : 'Please Enter Required Field'}
                    </span>
                )}
            </div>
        </div>
    );
};

export default Input;

// how to use

// const [inputData, setInputData] = useState({
//   field1: "",
//   field2: "",
//   // Add more fields as needed
// });

// const handleValueChange = (fieldName, value) => {
//   setInputData((prevInputData) => ({
//     ...prevInputData,
//     [fieldName]: value,
//   }));
// };

//     <div>
//     <Input
//       label="Field 1"
//       onValueChange={(value) => handleValueChange("field1", value)}
//     />

//     </div>
//     <div>
//     <Input
//       label="Field 2"
//       onValueChange={(value) => handleValueChange("field2", value)}
//     />
//     </div>
//     {/* Add more PlaceholderInput components as needed */}
//     <div className="data-display">
//       <h2>Data from Input Fields</h2>
//       <p>Field 1: {inputData.field1}</p>
//       <p>Field 2: {inputData.field2}</p>
//       {/* Display data from other fields */}
//     </div>
