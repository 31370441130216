import React from 'react';

const ElectricityIcon = () => {
    return (
        <div>
            <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M6.12489 9.33317H7.87489C9.33322 9.33317 10.2082 8.28317 10.2082 6.99984V4.03067C10.2082 3.41817 9.70655 2.9165 9.09405 2.9165H4.91156C4.29906 2.9165 3.79739 3.41817 3.79739 4.03067V6.99984C3.79156 8.28317 4.66655 9.33317 6.12489 9.33317Z"
                    stroke="#8C8C8C"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.5415 1.1665V2.9165"
                    stroke="#8C8C8C"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.4585 1.1665V2.9165"
                    stroke="#8C8C8C"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7 12.8335V9.3335"
                    stroke="#8C8C8C"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default ElectricityIcon;
