import React from 'react';
import './modalsucessprogress.scss'
import loader from '../../../Assets/checkout.gif';
import { useNavigate } from 'react-router-dom';
import { Pathname } from '../../../Router/Pathname';
const ModalSucessProgress = ({ isOpen, onClose ,amount,data,text}) => {
    const navigate=useNavigate()
    if (!isOpen) {
        return null;
      }
    //   setTimeout(() => {
    //     onClose()
    //     navigate(amount? `${Pathname.START_CHARGING}/intiate-charging` : `${Pathname.HOME}`)
    //   }, 2000);
    return (
        <div className="modal-overlay">
            <div className="modal" style={{borderRadius:"20px"}}>
            <div className="loading-icon">
                    <img src={loader} alt="loader-icon" />
                </div>
                <h1 className="modal--amount">{amount ? amount : data}</h1>
                <p>{text}</p>
            </div>
        </div>
    );
};

export default ModalSucessProgress;
