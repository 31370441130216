// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otp-input-container {
  display: flex;
  gap: 10px; }

.otp-input {
  width: 100%;
  height: 56px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
  padding: 10px 16px 10px 16px;
  border-radius: 12px;
  border: 1px solid #d9d9d9; }

.otp-input:focus {
  box-shadow: 0 0 5px rgba(0, 170, 116, 0.5); }

.otp-input.success {
  border-color: #00aa74;
  box-shadow: 0 0 5px rgba(0, 170, 116, 0.5); }

.otp-input.error {
  border-color: #ff0000;
  box-shadow: 0 0 5px rgba(255, 0, 0, 0.5); }
`, "",{"version":3,"sources":["webpack://./src/Components/Common/OTP/OTPInput.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,SAAS,EAAA;;AAGb;EACI,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,sBAAsB;EACtB,kBAAkB;EAClB,aAAa;EACb,8CAA8C;EAC9C,4BAA4B;EAC5B,mBAAmB;EACnB,yBAAyB,EAAA;;AAG7B;EACI,0CAA0C,EAAA;;AAG9C;EACI,qBAAqB;EACrB,0CAA0C,EAAA;;AAG9C;EACI,qBAAqB;EACrB,wCAAwC,EAAA","sourcesContent":[".otp-input-container {\n    display: flex;\n    gap: 10px;\n}\n\n.otp-input {\n    width: 100%;\n    height: 56px;\n    text-align: center;\n    font-size: 18px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    outline: none;\n    transition: border-color 0.3s, box-shadow 0.3s;\n    padding: 10px 16px 10px 16px;\n    border-radius: 12px;\n    border: 1px solid #d9d9d9;\n}\n\n.otp-input:focus {\n    box-shadow: 0 0 5px rgba(0, 170, 116, 0.5);\n}\n\n.otp-input.success {\n    border-color: #00aa74;\n    box-shadow: 0 0 5px rgba(0, 170, 116, 0.5);\n}\n\n.otp-input.error {\n    border-color: #ff0000;\n    box-shadow: 0 0 5px rgba(255, 0, 0, 0.5);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
