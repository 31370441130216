// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EnergyCalculation__Banner {
  height: 98px;
  display: flex;
  padding: 11px 16px 0px 16px;
  align-items: center;
  border-radius: 12px;
  gap: 8px;
  border: 1px solid #f2f2f2;
  border-radius: 8px;
  background: #e6f7ef;
  flex: 4; }

.EnergyCalculation__LeftContent {
  display: flex;
  flex: 1; }

.EnergyCalculation__Middle {
  display: flex;
  flex-direction: column;
  gap: 8px; }
`, "",{"version":3,"sources":["webpack://./src/Views/AfterAuth/StartChargeingView/EnergyCalculations/StationBanner/Stationbanner.scss"],"names":[],"mappings":"AACI;EACI,YAAY;EACZ,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,mBAAmB;EACnB,QAAQ;EACR,yBAAyB;EACzB,kBAAkB;EAClB,mBAAmB;EACnB,OAAO,EAAA;;AAEX;EACI,aAAa;EACb,OAAO,EAAA;;AAEX;EACI,aAAa;EACb,sBAAsB;EACtB,QAAQ,EAAA","sourcesContent":[".EnergyCalculation {\n    &__Banner {\n        height: 98px;\n        display: flex;\n        padding: 11px 16px 0px 16px;\n        align-items: center;\n        border-radius: 12px;\n        gap: 8px;\n        border: 1px solid #f2f2f2;\n        border-radius: 8px;\n        background: #e6f7ef;\n        flex: 4;\n    }\n    &__LeftContent {\n        display: flex;\n        flex: 1;\n    }\n    &__Middle {\n        display: flex;\n        flex-direction: column;\n        gap: 8px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
