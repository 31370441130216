import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Server from '../../../../Server';
import { Buffer } from 'buffer';
import { updateRazorpayRefresh, updateWalletTransactions } from './action';

const initialState = {
    loading: false,
    walletTransactions: [],
    hasMore: true,
    page: 0,
    invoiceDetails: null,
    error: null,
    success: false,
    razorpayStatus: null,
    razorpayRefresh: null,
    refundInvoiceDetails: null,
    topupInvoiceDetails: null,
};

const getCustomerId = (token) => {
    const parts = token.split('.');
    const payload =
        parts && parts.length && Buffer.from(parts[1], 'base64').toString('utf8');
    const payloadObj = JSON.parse(payload);
    return payloadObj?.customerId;
};

export const getWalletTransactions = createAsyncThunk(
    'wallet/transactions',
    async ({ page }) => {
        try {
            const res = await Server.post(
                `/api/v1/transaction/customer/account-transactions?page=${page}`,
                {
                    customer_id: getCustomerId(localStorage.getItem('t_id')),
                },
                true
            );
            return res;
        } catch (error) {
            console.log('error', error);
        }
    }
);
export const getChargingInvoice = createAsyncThunk(
    'wallet/chargingInvoice',
    async ({ id }) => {
        try {
            const res = await Server.get(
                `/api/v1/csms/wallet/invoice/?ocpp_transaction_id=${id}`,
                true
            );
            console.log('res--', res);
            return res;
        } catch (error) {
            console.log('error', error);
        }
    }
);
export const downloadInvoice = createAsyncThunk(
    'wallet/downloadInvoicePdf',
    async ({ ocpp_transaction_id, invoice_id }) => {
        try {
            const response = await Server.generatePDF(
                `/api/v1/csms/wallet/downloadinvoice?ocpp_transaction_id=${ocpp_transaction_id}`,
                true,
                null
            );
            const blob = new Blob([response], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${invoice_id}`;
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            return response;
        } catch (error) {
            console.log('error', error);
        }
    }
);

export const addMoney = createAsyncThunk(
    'wallet/addMoneyToWallet',
    async ({ amount }, thunkAPI) => {
        try {
            const res = await Server.post(
                '/api/v1/transaction/razorpay/order',
                {
                    amount: amount,
                },
                true
            );
            await thunkAPI.dispatch(getWalletTransactions({ page: 0 }));
            return res;
        } catch (error) {
            console.log('error', error);
        }
    }
);
export const razorpayStatusInfo = createAsyncThunk(
    'wallet/razorpayStatus',
    async ({ payment_id, order_id }) => {
        // console.log('order_id', payment_id, order_id);
        try {
            const res = await Server.post(
                '/api/v1/transaction/razorpay/update',
                {
                    payment_id: payment_id,
                    order_id: order_id,
                },
                true
            );
            console.log('res--', res);
            return res;
        } catch (error) {
            console.log('error', error);
        }
    }
);
export const razorpayStatusRefresh = createAsyncThunk(
    'wallet/razorpayStatus',
    async (order_id) => {
        try {
            const res = await Server.get(
                `/api/v1/transaction/razorpay/order-status?order_id=${order_id}`,
                true
            );
            console.log('res--', res);
            return res;
        } catch (error) {
            console.log('error', error);
        }
    }
);

export const getRefundInvoice = createAsyncThunk(
    'wallet/RefundInvoice',
    async ({ id }) => {
        try {
            const res = await Server.get(
                `/api/v1/csms/wallet/invoice/refund/?id=${id}`,
                true
            );
            console.log('res--refund', res);
            return res;
        } catch (error) {
            console.log('error', error);
        }
    }
);

export const downloadRefundInvoice = createAsyncThunk(
    'wallet/downloadInvoicePdf',
    async ({ id, invoice_id }) => {
        try {
            const response = await Server.generatePDF(
                `/api/v1/csms/wallet/downloadinvoice/refund?id=${id}`,
                true,
                null
            );
            const blob = new Blob([response], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${invoice_id}`;
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            return response;
        } catch (error) {
            console.log('error', error);
        }
    }
);

export const getTopupInvoice = createAsyncThunk('wallet/TopupInvoice', async ({ id }) => {
    try {
        const res = await Server.get(`/api/v1/csms/wallet/invoice/topup/?id=${id}`, true);
        return res;
    } catch (error) {
        console.log('error', error);
    }
});

export const downloadTopupInvoice = createAsyncThunk(
    'wallet/downloadInvoicePdf',
    async ({ id, invoice_id }) => {
        try {
            const response = await Server.generatePDF(
                `/api/v1/csms/wallet//downloadinvoice/topup?id=${id}`,
                true,
                null
            );
            const blob = new Blob([response], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = `${invoice_id}`;
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(url);
            return response;
        } catch (error) {
            console.log('error', error);
        }
    }
);

const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    reducers: {
        resetWalletTransactions: (state) => {
            // Reset wallet-related state properties
            state.loading = false;
            state.walletTransactions = [];
            state.hasMore = true;
            state.page = 0;
            state.invoiceDetails = null;
            state.error = null;
            state.success = false;
            state.razorpayStatus = null;
            state.razorpayRefresh = null;
            state.refundInvoiceDetails = null;
        },
        setWalletTransactions: updateWalletTransactions,
        setRazorpayRefresh: updateRazorpayRefresh,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getWalletTransactions.pending, (state) => {
                state.error = '';
                state.loading = true;
                state.success = false;
            })
            .addCase(getWalletTransactions.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;

                if (
                    action.payload &&
                    action.payload.payload.resp &&
                    Array.isArray(action.payload.payload.resp)
                ) {
                    state.walletTransactions = [
                        ...state?.walletTransactions,
                        ...action?.payload?.payload?.resp,
                    ];
                    if (action.payload?.payload?.resp?.length === 0) {
                        state.hasMore = false;
                    } else {
                        state.page += 1;
                    }
                } else {
                    state.hasMore = false;
                    console.error('Invalid response data:', action.payload);
                }
            })
            .addCase(getWalletTransactions.rejected, (state, action) => {
                state.loading = false;
                state.walletTransactions = null;
                state.hasMore = false;
                state.error = action.error.message;
                state.success = false;
            })
            .addCase(getChargingInvoice.pending, (state) => {
                state.error = '';
                state.loading = true;
                state.invoiceDetails = null;
            })
            .addCase(getChargingInvoice.fulfilled, (state, action) => {
                console.log('action--', action.payload);
                state.loading = false;
                state.invoiceDetails = action.payload;
                state.error = '';
            })
            .addCase(getChargingInvoice.rejected, (state, action) => {
                state.loading = false;
                state.invoiceDetails = null;
                state.error = action.error.message;
            })
            .addCase(razorpayStatusInfo.pending, (state) => {
                state.error = '';
                state.loading = true;
                state.razorpayStatus = null;
            })
            .addCase(razorpayStatusInfo.fulfilled, (state, action) => {
                state.loading = false;
                state.razorpayStatus = action.payload;
                state.error = '';
            })
            .addCase(razorpayStatusInfo.rejected, (state, action) => {
                state.loading = false;
                state.razorpayStatus = null;
                state.error = action.error.message;
            })
            // .addCase(razorpayStatusRefresh.pending, (state) => {
            //     state.error = '';
            //     state.loading = true;
            //     state.razorpayRefresh = null;
            // })
            // .addCase(razorpayStatusRefresh.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.razorpayRefresh = action.payload;
            //     state.error = '';
            // })
            // .addCase(razorpayStatusRefresh.rejected, (state, action) => {
            //     state.loading = false;
            //     state.razorpayRefresh = null;
            //     state.error = action.error.message;
            // });
            .addCase(getRefundInvoice.pending, (state) => {
                state.error = '';
                state.loading = true;
                state.refundInvoiceDetails = null;
            })
            .addCase(getRefundInvoice.fulfilled, (state, action) => {
                state.loading = false;
                state.refundInvoiceDetails = action?.payload?.payload?.payload;
                state.error = '';
            })
            .addCase(getRefundInvoice.rejected, (state, action) => {
                state.loading = false;
                state.refundInvoiceDetails = null;
                state.error = action.error.message;
            })
            .addCase(getTopupInvoice.pending, (state) => {
                state.error = '';
                state.loading = true;
                state.topupInvoiceDetails = null;
            })
            .addCase(getTopupInvoice.fulfilled, (state, action) => {
                state.loading = false;
                state.topupInvoiceDetails = action?.payload?.payload?.payload;
                state.error = '';
            })
            .addCase(getTopupInvoice.rejected, (state, action) => {
                state.loading = false;
                state.topupInvoiceDetails = null;
                state.error = action.error.message;
            });
    },
});
export const { resetWalletTransactions, setWalletTransactions, setRazorpayRefresh } =
    walletSlice.actions;
export default walletSlice.reducer;
