import React from 'react';
import Header from '../../../Components/Layout/Header/Header';
import './termsCondn.scss';
import { Button } from '../../../Components/Common';
import { Pathname } from '../../../Router/Pathname';
import { useNavigate } from 'react-router';

const TermsAndCondn = () => {

    const navigate = useNavigate();
    const handleBackToLogin = () => {
        navigate(Pathname.LOGIN);
    }

    return (
        <div className="TermsandCondn">
            <div className="TermsandCondn-header">
                <Header />
            </div>
            <div className="TermsandCondn-content">
                <section className="TermsandCondn-terms">
                    <div>
                        <div>
                            <div>
                                <div className="TermsandCondn-title-container">
                                    <h1 className="TermsandCondn-title-section">
                                        <span>Terms &amp; Condtions</span>
                                    </h1>
                                </div>
                                <p>
                                    <strong>AGREEMENT TO TERMS</strong>
                                </p>
                                <p>
                                    These Terms and Conditions constitute a legally
                                    binding agreement made between you, whether personally
                                    or on behalf of an entity ("you") and LIONCHARGE
                                    E-MOBILITY PRIVATE LIMITED ("we," "us" or "our"),
                                    concerning your access to and use of our mobile
                                    application Lion Charge. You agree that by accessing
                                    the Application, you have read, understood, and agree
                                    to be bound by all of these Terms and Conditions Use.
                                    IF YOU DO NOT AGREE WITH ALL OF THESE TERMS AND
                                    CONDITIONS, THEN YOU ARE EXPRESSLY PROHIBITED FROM
                                    USING THE APPLICATION AND YOU MUST DISCONTINUE USE
                                    IMMEDIATELY.
                                </p>
                                <p>
                                    Supplemental terms and conditions or documents that
                                    may be posted on the Application from time to time are
                                    hereby expressly incorporated herein by reference. We
                                    reserve the right, in our sole discretion, to make
                                    changes or modifications to these Terms and Conditions
                                    at any time and for any reason. We will alert you
                                    about any changes by updating the "Last Updated" date
                                    of these Terms and Conditions and you waive any right
                                    to receive specific notice of each such change. It is
                                    your responsibility to periodically review these Terms
                                    and Conditions to stay informed of updates. You will
                                    be subject to and will be deemed to have been made
                                    aware of and to have accepted, the changes in any
                                    revised Terms and Conditions by your continued use of
                                    the Application after the date such revised Terms are
                                    posted.
                                </p>
                                <p>
                                    The information provided on the Application is not
                                    intended for distribution to or use by any person or
                                    entity in any jurisdiction or country where such
                                    distribution or use would be contrary to law or
                                    regulation or which would subject us to any
                                    registration requirement within such jurisdiction or
                                    country. Accordingly, those persons who choose to
                                    access the Application from other locations do so on
                                    their own initiative and are solely responsible for
                                    compliance with local laws, if and to the extent local
                                    laws are applicable.
                                </p>
                                <p>
                                    The Application is intended for users who own an
                                    Electric Vehicle and is used for charging the car
                                    battery. All users who are minors in the jurisdiction
                                    in which they reside (generally under the age of 18)
                                    must have the permission of, and be directly
                                    supervised by, their parent or guardian to use the
                                    Application. If you are a minor, you must have your
                                    parent or guardian read and agree to these Terms of
                                    Use prior to you using the Application.
                                </p>
                                <p>
                                    <strong>INTELLECTUAL PROPERTY RIGHTS</strong>
                                </p>
                                <p>
                                    Unless otherwise indicated, the Application is
                                    Numocity Technologies Pvt. Ltd proprietary property
                                    and all source code, databases, functionality,
                                    software, website designs, audio, video, text,
                                    photographs, and graphics on the Application
                                    (collectively, the "Content") and the trademarks,
                                    service marks, and logos contained therein (the
                                    "Marks") are owned or controlled by us or licensed to
                                    us, and are protected by copyright and trademark laws
                                    and various other intellectual property rights and
                                    unfair competition laws of India, foreign
                                    jurisdictions, and international conventions. The
                                    Content and the Marks are provided on the Application
                                    "AS IS" for your information and personal use only.
                                    Except as expressly provided in these Terms of Use, no
                                    part of the Application and no Content or Marks may be
                                    copied, reproduced, aggregated, republished, uploaded,
                                    posted, publicly displayed, encoded, translated,
                                    transmitted, distributed, sold, licensed, or otherwise
                                    exploited for any commercial purpose whatsoever,
                                    without our express prior written permission.
                                </p>
                                <p>
                                    Provided that you are eligible to use the Application,
                                    you are granted a limited license to access and use
                                    the Application and to download or print a copy of any
                                    portion of the Content to which you have properly
                                    gained access solely for your personal, non-commercial
                                    use. We reserve all rights not expressly granted to
                                    you in and to the Application, Content, and the Marks.
                                </p>
                                <p>
                                    <strong>USER REPRESENTATIONS</strong>
                                </p>
                                <p>
                                    By using the Application, you represent and warrant
                                    that: (1) all registration information you submit will
                                    be true, accurate, current, and complete; (2) you will
                                    maintain the accuracy of such information and promptly
                                    update such registration information as necessary; (3)
                                    you have the legal capacity and you agree to comply
                                    with these Terms of Use; (4) you are not under the age
                                    of 13; (5) not a minor in the jurisdiction in which
                                    you reside, or if a minor, you have received parental
                                    permission to use the Site; (6) you will not access
                                    the Application through automated or non-human means,
                                    whether through a bot, script or otherwise; (7) you
                                    will not use the Application for any illegal or
                                    unauthorized purpose; and (8) your use of the
                                    Application will not violate any applicable law or
                                    regulation.
                                </p>
                                <p>
                                    If you provide any information that is untrue,
                                    inaccurate, not current, or incomplete, we have the
                                    right to suspend or terminate your account and refuse
                                    any and all current or future use of the
                                    Application(or any portion thereof).
                                </p>
                                <p>
                                    <strong>USER REGISTRATION</strong>
                                </p>
                                <p>
                                    You may be required to register with the Application.
                                    You agree to keep your password confidential and will
                                    be responsible for all use of your account and
                                    password. We reserve the right to remove, reclaim, or
                                    change a username you select if we determine, in our
                                    sole discretion, that such username is inappropriate,
                                    obscene, or otherwise objectionable.
                                </p>
                                <p>
                                    <strong>PROHIBITED ACTIVITIES</strong>
                                </p>
                                <p>
                                    You may not access or use the Application for any
                                    purpose other than that for which we make the
                                    Application available. The Application may not be used
                                    in connection with any commercial endeavors except
                                    those that are specifically endorsed or approved by
                                    us.
                                </p>
                                <p>As a user of the Application, you agree not to:</p>
                                <p>
                                    (1) Systematically retrieve data or other content from
                                    the Application to create or compile, directly or
                                    indirectly, a collection, compilation, database, or
                                    directory without written permission from us.
                                </p>
                                <p>
                                    (2)Make any unauthorized use of the Application,
                                    including collecting usernames and/or email addresses
                                    of users by electronic or other means for the purpose
                                    of sending unsolicited email, or creating user
                                    accounts by automated means or under false pretenses.
                                </p>
                                <p>
                                    (3)Use the Application to advertise or offer to sell
                                    goods and services.
                                </p>
                                <p>
                                    (4)Circumvent, disable, or otherwise interfere with
                                    security-related features of the Application,
                                    including features that prevent or restrict the use or
                                    copying of any Content or enforce limitations on the
                                    use of the Application and/or the Content contained
                                    therein.
                                </p>
                                <p>
                                    (5) Engage in unauthorized framing of or linking to
                                    the Application.
                                </p>
                                <p>
                                    (6) Trick, defraud, or mislead us and other users,
                                    especially in any attempt to learn sensitive account
                                    information such as user passwords;
                                </p>
                                <p>
                                    (7) Make improper use of our support services or
                                    submit false reports of abuse or misconduct.
                                </p>
                                <p>
                                    (8)Engage in any automated use of the system, such as
                                    using scripts to send comments or messages, or using
                                    any data mining, robots, or similar data gathering and
                                    extraction tools.
                                </p>
                                <p>
                                    (9) Interfere with, disrupt, or create an undue burden
                                    on the Application or the networks or services
                                    connected to the Application.
                                </p>
                                <p>
                                    (10) Attempt to impersonate another user or person or
                                    use the username of another user.
                                </p>
                                <p>(11) Sell or otherwise transfer your profile.</p>
                                <p>
                                    (12) Use any information obtained from the Application
                                    in order to harass, abuse, or harm another person.
                                </p>
                                <p>
                                    (13) Use the Application as part of any effort to
                                    compete with us or otherwise use the Application
                                    and/or the Content for any revenue-generating endeavor
                                    or commercial enterprise.
                                </p>
                                <p>
                                    (14) Decipher, decompile, disassemble, or reverse
                                    engineer any of the software comprising or in any way
                                    making up a part of the Application.
                                </p>
                                <p>
                                    (15) Attempt to bypass any measures of the Application
                                    designed to prevent or restrict access to the
                                    Application, or any portion of the Application.
                                </p>
                                <p>
                                    (16) Delete the copyright or other proprietary rights
                                    notice from any Content.
                                </p>
                                <p>
                                    (17) Copy or adapt the Application’s software,
                                    including but not limited to Flash, PHP, HTML,
                                    JavaScript, or other code.
                                </p>
                                <p>
                                    (18) Upload or transmit (or attempt to upload or to
                                    transmit) viruses, Trojan horses, or other material,
                                    including excessive use of capital letters and
                                    spamming (continuous posting of repetitive text), that
                                    interferes with any party’s uninterrupted use and
                                    enjoyment of the Application or modifies, impairs,
                                    disrupts, alters, or interferes with the use,
                                    features, functions, operation, or maintenance of the
                                    Application.
                                </p>
                                <p>
                                    (19) Upload or transmit (or attempt to upload or to
                                    transmit) any material that acts as a passive or
                                    active information collection or transmission
                                    mechanism, including without limitation, clear
                                    graphics interchange formats ("gifs"), 1×1 pixels, web
                                    bugs, cookies, or other similar devices (sometimes
                                    referred to as "spyware" or "passive collection
                                    mechanisms" or "pcms").
                                </p>
                                <p>
                                    (20)Except as may be the result of standard search
                                    engine or Internet browser usage, use, launch,
                                    develop, or distribute any automated system, including
                                    without limitation, any spider, robot, cheat utility,
                                    scraper, or offline reader that accesses the
                                    Application, or using or launching any unauthorized
                                    script or other software.
                                </p>
                                <p>
                                    Disparage, tarnish, or otherwise harm, in our opinion,
                                    us and/or the Application.
                                </p>
                                <p>
                                    (21) Use the Application in a manner inconsistent with
                                    any applicable laws or regulations.
                                </p>
                                <p>
                                    <strong>GUIDELINES FOR REVIEWS</strong>
                                </p>
                                <p>
                                    We may provide you areas on the Application to leave
                                    reviews or ratings. When posting a review, you must
                                    comply with the following criteria: (1) you should
                                    have firsthand experience with the person/entity being
                                    reviewed; (2) your reviews should not contain
                                    offensive profanity, or abusive, racist, offensive, or
                                    hate language; (3) your reviews should not contain
                                    discriminatory references based on religion, race,
                                    gender, national origin, age, marital status, sexual
                                    orientation, or disability; (4) your reviews should
                                    not contain references to illegal activity; (5) you
                                    should not be affiliated with competitors if posting
                                    negative reviews; (6) you should not make any
                                    conclusions as to the legality of conduct; (7) you may
                                    not post any false or misleading statements; and (8)
                                    you may not organize a campaign encouraging others to
                                    post reviews, whether positive or negative.
                                </p>
                                <p>
                                    We may accept, reject, or remove reviews in our sole
                                    discretion. We have absolutely no obligation to screen
                                    reviews or to delete reviews, even if anyone considers
                                    reviews objectionable or inaccurate. Reviews are not
                                    endorsed by us, and do not necessarily represent our
                                    opinions or the views of any of our affiliates or
                                    partners. We do not assume liability for any review or
                                    for any claims, liabilities, or losses resulting from
                                    any review. By posting a review, you hereby grant to
                                    us a perpetual, non-exclusive, worldwide,
                                    royalty-free, fully-paid, assignable, and
                                    sublicensable right and license to reproduce, modify,
                                    translate, transmit by any means, display, perform,
                                    and/or distribute all content relating to reviews.
                                </p>
                                <p>
                                    <strong>MOBILE APPLICATION LICENSE</strong>
                                </p>
                                <p>
                                    <strong>Use License</strong>
                                </p>
                                <p>
                                    If you access the Application via a mobile
                                    application, then we grant you a revocable,
                                    non-exclusive, non-transferable, limited right to
                                    install and use the mobile application on wireless
                                    electronic devices owned or controlled by you, and to
                                    access and use the mobile application on such devices
                                    strictly in accordance with the terms and conditions
                                    of this mobile application license contained in these
                                    Terms of Use. You shall not: (1) decompile, reverse
                                    engineer, disassemble, attempt to derive the source
                                    code of, or decrypt the application; (2) make any
                                    modification, adaptation, improvement, enhancement,
                                    translation, or derivative work from the application;
                                    (3) violate any applicable laws, rules, or regulations
                                    in connection with your access or use of the
                                    application; (4) remove, alter, or obscure any
                                    proprietary notice (including any notice of copyright
                                    or trademark) posted by us or the licensors of the
                                    application; (5) use the application for any revenue
                                    generating endeavor, commercial enterprise, or other
                                    purpose for which it is not designed or intended; (6)
                                    make the application available over a network or other
                                    environment permitting access or use by multiple
                                    devices or users at the same time; (7) use the
                                    application for creating a product, service, or
                                    software that is, directly or indirectly, competitive
                                    with or in any way a substitute for the application;
                                    (8) use the application to send automated queries to
                                    any website or to send any unsolicited commercial
                                    e-mail; or (9) use any proprietary information or any
                                    of our interfaces or our other intellectual property
                                    in the design, development, manufacture, licensing, or
                                    distribution of any applications, accessories, or
                                    devices for use with the application.
                                </p>
                                <p>
                                    <strong>Apple and Android Devices</strong>
                                </p>
                                <p>
                                    The following terms apply when you use a mobile
                                    application obtained from either the Apple Store or
                                    Google Play (each an "App Distributor") to access the
                                    Application: (1) the license granted to you for our
                                    mobile application is limited to a non-transferable
                                    license to use the application on a device that
                                    utilizes the Apple iOS or Android operating systems,
                                    as applicable, and in accordance with the usage rules
                                    set forth in the applicable App Distributor’s terms of
                                    service; (2) we are responsible for providing any
                                    maintenance and support services with respect to the
                                    mobile application as specified in the terms and
                                    conditions of this mobile application license
                                    contained in these Terms of Use or as otherwise
                                    required under applicable law, and you acknowledge
                                    that each App Distributor has no obligation whatsoever
                                    to furnish any maintenance and support services with
                                    respect to the mobile application; (3) in the event of
                                    any failure of the mobile application to conform to
                                    any applicable warranty, you may notify the applicable
                                    App Distributor, and the App Distributor, in
                                    accordance with its terms and policies, may refund the
                                    purchase price, if any, paid for the mobile
                                    application, and to the maximum extent permitted by
                                    applicable law, the App Distributor will have no other
                                    warranty obligation whatsoever with respect to the
                                    mobile application; (4) you must comply with
                                    applicable third-party terms of agreement when using
                                    the mobile application, e.g., if you have a VoIP
                                    application, then you must not be in violation of
                                    their wireless data service agreement when using the
                                    mobile application; and (6) you acknowledge and agree
                                    that the App Distributors are third-party
                                    beneficiaries of the terms and conditions in this
                                    mobile application license contained in these Terms of
                                    Use, and that each App Distributor will have the right
                                    (and will be deemed to have accepted the right) to
                                    enforce the terms and conditions in this mobile
                                    application license contained in these Terms of Use
                                    against you as a third-party beneficiary thereof.
                                </p>
                                <p>
                                    <strong>SUBMISSIONS</strong>
                                </p>
                                <p>
                                    You acknowledge and agree that any questions,
                                    comments, suggestions, ideas, feedback, or other
                                    information regarding the Application ("Submissions")
                                    provided by you to us are non-confidential and shall
                                    become our sole property. We shall own exclusive
                                    rights, including all intellectual property rights,
                                    and shall be entitled to the unrestricted use and
                                    dissemination of these Submissions for any lawful
                                    purpose, commercial or otherwise, without
                                    acknowledgment or compensation to you. You hereby
                                    waive all moral rights to any such Submissions, and
                                    you hereby warrant that any such Submissions are
                                    original with you or that you have the right to submit
                                    such Submissions. You agree there shall be no recourse
                                    against us for any alleged or actual infringement or
                                    misappropriation of any proprietary right in your
                                    Submissions.
                                </p>
                                <p>
                                    <strong>ADVERTISERS</strong>
                                </p>
                                <p>
                                    We allow advertisers to display their advertisements
                                    and other information in certain areas of the
                                    Application, such as sidebar advertisements or banner
                                    advertisements. If you are an advertiser, you shall
                                    take full responsibility for any advertisements you
                                    place on the Application and any services provided on
                                    the Application or products sold through those
                                    advertisements. Further, as an advertiser, you warrant
                                    and represent that you possess all rights and
                                    authority to place advertisements on the Application,
                                    including, but not limited to, intellectual property
                                    rights, publicity rights, and contractual rights. As
                                    an advertiser, you agree that such advertisements are
                                    subject to our Digital Millennium Copyright Act
                                    ("DMCA") Notice and Policy provisions as described
                                    below, and you understand and agree there will be no
                                    refund or other compensation for DMCA takedown-related
                                    issues. We simply provide the space to place such
                                    advertisements, and we have no other relationship with
                                    advertisers.
                                </p>
                                <p>
                                    <strong>APP MANAGEMENT</strong>
                                </p>
                                <p>
                                    We reserve the right, but not the obligation, to: (1)
                                    monitor the Application for violations of these Terms
                                    of Use; (2) take appropriate legal action against
                                    anyone who, in our sole discretion, violates the law
                                    or these Terms of Use, including without limitation,
                                    reporting such user to law enforcement authorities;
                                    (3) in our sole discretion and without limitation,
                                    refuse, restrict access to, limit the availability of,
                                    or disable (to the extent technologically feasible)
                                    any of your Contributions or any portion thereof; (4)
                                    in our sole discretion and without limitation, notice,
                                    or liability, to remove from the Application or
                                    otherwise disable all files and content that are
                                    excessive in size or are in any way burdensome to our
                                    systems; and (5) otherwise manage the Application in a
                                    manner designed to protect our rights and property and
                                    to facilitate the proper functioning of the
                                    Application.
                                </p>
                                <p>
                                    <strong>COPYRIGHT INFRINGEMENTS</strong>
                                </p>
                                <p>
                                    We respect the intellectual property rights of others.
                                    If you believe that any material available on or
                                    through the Application infringes upon any copyright
                                    you own or control, please immediately notify us using
                                    the contact information provided below (a
                                    "Notification"). A copy of your Notification will be
                                    sent to the person who posted or stored the material
                                    addressed in the Notification. Please be advised that
                                    pursuant to federal law you may be held liable for
                                    damages if you make material misrepresentations in a
                                    Notification. Thus, if you are not sure that material
                                    located on or linked to by the Application infringes
                                    your copyright, you should consider first contacting
                                    an attorney.
                                </p>
                                <p>
                                    <strong>TERM AND TERMINATION</strong>
                                </p>
                                <p>
                                    These Terms of Use shall remain in full force and
                                    effect while you use the Application. WITHOUT LIMITING
                                    ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE
                                    THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
                                    NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE
                                    SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY
                                    PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING
                                    WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
                                    WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE
                                    OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
                                    TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR
                                    DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION
                                    THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR
                                    SOLE DISCRETION.
                                </p>
                                <p>
                                    If we terminate or suspend your account for any
                                    reason, you are prohibited from registering and
                                    creating a new account under your name, a fake or
                                    borrowed name, or the name of any third party, even if
                                    you may be acting on behalf of the third party. In
                                    addition to terminating or suspending your account, we
                                    reserve the right to take appropriate legal action,
                                    including without limitation pursuing civil, criminal,
                                    and injunctive redress.
                                </p>
                                <p>
                                    <strong>MODIFICATIONS AND INTERRUPTIONS</strong>
                                </p>
                                <p>
                                    We reserve the right to change, modify, or remove the
                                    contents of the Application at any time or for any
                                    reason at our sole discretion without notice. However,
                                    we have no obligation to update any information on our
                                    Application. We also reserve the right to modify or
                                    discontinue all or part of the Application without
                                    notice at any time. We will not be liable to you or
                                    any third party for any modification, price change,
                                    suspension, or discontinuance of the Application.
                                </p>
                                <p>
                                    We cannot guarantee the Application will be available
                                    at all times. We may experience hardware, software, or
                                    other problems or need to perform maintenance related
                                    to the Application, resulting in interruptions,
                                    delays, or errors. We reserve the right to change,
                                    revise, update, suspend, discontinue, or otherwise
                                    modify the Application at any time or for any reason
                                    without notice to you. You agree that we have no
                                    liability whatsoever for any loss, damage, or
                                    inconvenience caused by your inability to access or
                                    use the Application during any downtime or
                                    discontinuance of the Application. Nothing in these
                                    Terms of Use will be construed to obligate us to
                                    maintain and support the Application or to supply any
                                    corrections, updates, or releases in connection
                                    therewith.
                                </p>
                                <p>
                                    <strong>CORRECTIONS</strong>
                                </p>
                                <p>
                                    There may be information on the Application that
                                    contains typographical errors, inaccuracies, or
                                    omissions that may relate to the Application,
                                    including descriptions, pricing, availability, and
                                    various other information. We reserve the right to
                                    correct any errors, inaccuracies, or omissions and to
                                    change or update the information on the Application at
                                    any time, without prior notice.
                                </p>
                                <p>
                                    <strong>USER DATA</strong>
                                </p>
                                <p>
                                    We will maintain certain data that you transmit to the
                                    Site for the purpose of managing the Site, as well as
                                    data relating to your use of the Site. Although we
                                    perform regular routine backups of data, you are
                                    solely responsible for all data that you transmit or
                                    that relates to any activity you have undertaken using
                                    the Site. You agree that we shall have no liability to
                                    you for any loss or corruption of any such data, and
                                    you hereby waive any right of action against us
                                    arising from any such loss or corruption of such data.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="TermsandCondn-btn-container">
                    <Button text="Back to Login" 
                            className="TermsandCondn-btn" 
                            onClick={() => handleBackToLogin()}
                            />
                </div>
            </div>
        </div>
    );
};

export default TermsAndCondn;
