import React from 'react';
import ArrowLeft from '../../../Assets/icons/ArrowLeft';
import { useNavigate } from 'react-router-dom';
import './SearchStationView.scss';
import SearchStations from './SearchStations/SearchStations';
const SearchStationView = () => {
    const navigate = useNavigate();
    return (
        <div className="SearchStationsView">
            <SearchStations />
        </div>
    );
};

export default SearchStationView;
