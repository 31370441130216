import React, { useEffect, useState } from 'react';
import './invoicedownload.scss';
import Modal from '../Modal/Modal';
import DividerCard from '../DividerCard/DividerCard';
import Button from '../Button/Button';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { downloadInvoice } from '../../../Views/AfterAuth/WalletView/redux/walletSlice';

const InvoiceDownload = ({ openModal, invoiceClose, ocppTransactionId, invoiceId }) => {
    const { invoiceDetails } = useSelector((store) => store.wallet);
    const dispatch = useDispatch();
    const ocpp_transaction_id = invoiceDetails?.payload?.session?.ocpp_transaction_id;
    const invoice_id = invoiceDetails?.payload?.transaction?.invoice_id;

    const totalSum =
        Number(invoiceDetails?.payload?.transaction?.tax_amount) +
        Number(invoiceDetails?.payload?.transaction?.service_fee);
    const ChargingFee = Number(invoiceDetails?.payload?.charging_fee);

    const handleDownloadInvoice = () => {
        const getTransactionId = ocpp_transaction_id || ocppTransactionId;
        const getInvoiceId = invoice_id || invoiceId;
        dispatch(
            downloadInvoice({
                ocpp_transaction_id: getTransactionId,
                invoice_id: getInvoiceId,
            })
        );
    };
    return (
        <div>
            <Modal
                onClose={invoiceClose}
                isOpen={openModal}
                closeButtonClass="wallet-modal-close"
            >
                <div className="DownloadInvoice">
                    <p className="invoice-tag">Invoice</p>
                    <div className="invoice">
                        <div className="card status-name">
                            <p>Station name</p>
                            <p>
                                {
                                    invoiceDetails?.payload?.session?.chargingPoint
                                        ?.charging_station?.station_name
                                }
                            </p>
                        </div>
                        <div className="card station-rate">
                            <p>Rate </p>
                            <p>
                                {invoiceDetails?.payload?.transaction?.tariff_rate +
                                    '/- per kWh'}
                            </p>
                        </div>
                    </div>
                    <div className="card withchild">
                        <DividerCard>
                            <div className="left">
                                <p>Charge Point</p>
                                <p>Connector Type</p>
                                <p>Connector ID</p>
                            </div>
                            <div className="right">
                                <p>
                                    {
                                        invoiceDetails?.payload?.session?.chargingPoint
                                            ?.charging_point_name
                                    }
                                </p>
                                <p>{invoiceDetails?.payload?.connectorType}</p>
                                <p>{invoiceDetails?.payload?.session?.connector_id}</p>
                            </div>
                        </DividerCard>
                    </div>
                    <div className="card withchild">
                        <DividerCard>
                            <div className="left">
                                <p>Date</p>
                                <p>Charging Started at</p>
                                <p>Charging Ended At</p>
                                <p>Total Duration</p>
                            </div>
                            <div className="right">
                                <p>
                                    {dayjs(
                                        invoiceDetails?.payload?.session?.createdAt
                                    ).format('DD MMMM YYYY')}
                                </p>
                                <p>
                                    {dayjs(
                                        invoiceDetails?.payload?.session?.charging_start
                                    ).format('hh:mm a')}
                                </p>
                                <p>
                                    {dayjs(
                                        invoiceDetails?.payload?.session?.charging_end
                                    ).format('hh:mm a')}
                                </p>
                                <p>{invoiceDetails?.payload?.session?.charge_duration}</p>
                            </div>
                        </DividerCard>
                    </div>
                    <div className="card withchild">
                        <DividerCard>
                            <div className="left">
                                <p>Units consumed</p>
                                <p>Tax Rate</p>
                                <p>GST IN</p>
                                <p>Service Fee</p>
                                {/* <p>Total tax amount</p> */}
                                <p>Charging Fee</p>
                                <p>Total amount</p>
                            </div>
                            <div className="right">
                                <p>
                                    {
                                        invoiceDetails?.payload?.session
                                            ?.energy_delivered_kwh
                                    }
                                    Kw
                                </p>
                                <p>{invoiceDetails?.payload?.transaction?.tax_val}%</p>
                                <p>
                                    {invoiceDetails?.payload?.transaction?.gst_in
                                        ? invoiceDetails?.payload?.transaction?.gst_in
                                        : '-'}
                                </p>
                                <p>{invoiceDetails?.payload?.transaction?.service_fee}</p>
                                <p>{ChargingFee}</p>
                                {/* <p>{invoiceDetails?.payload?.transaction?.tax_amount}</p> */}
                                <p className="total-amount">
                                    {Number(
                                        invoiceDetails?.payload?.transaction?.amount
                                    ).toFixed(2)}
                                </p>
                            </div>
                        </DividerCard>
                    </div>
                    <div className="download--btn">
                        <Button
                            text={'Download Invoice'}
                            className={'download-btn'}
                            onClick={handleDownloadInvoice}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default InvoiceDownload;
