// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MyProfilelayout--main-div {
  height: 122vh; }
  .MyProfilelayout--main-div .MyProfilelayout--header {
    padding-inline: 2rem;
    background-color: #1E1E1E;
    height: 25vh; }
    .MyProfilelayout--main-div .MyProfilelayout--header .MyProfilelayout--addVehicle {
      display: flex;
      justify-content: space-between;
      padding-block: 3rem;
      align-items: center; }
      .MyProfilelayout--main-div .MyProfilelayout--header .MyProfilelayout--addVehicle p {
        font-size: 18px;
        font-weight: 600;
        color: white; }
  .MyProfilelayout--main-div .MyProfilelayout--body {
    height: 80vh;
    margin-top: -5vh;
    padding-inline: 2rem;
    background-color: white;
    border-radius: 20px 20px 0 0; }
`, "",{"version":3,"sources":["webpack://./src/Layouts/MyProfileLayout/myprofilelayout.scss"],"names":[],"mappings":"AACA;EACI,aAAa,EAAA;EADjB;IAIQ,oBAAoB;IACpB,yBAAyB;IACzB,YAAY,EAAA;IANpB;MAUY,aAAa;MACb,8BAA8B;MAC9B,mBAAmB;MACnB,mBAAmB,EAAA;MAb/B;QAegB,eAAe;QACf,gBAAgB;QAChB,YAAY,EAAA;EAjB5B;IAsBQ,YAAY;IACZ,gBAAgB;IAChB,oBAAoB;IACpB,uBAAuB;IACvB,4BAA6B,EAAA","sourcesContent":["\n.MyProfilelayout--main-div{\n    height: 122vh;\n    // background-color: ;\n    .MyProfilelayout--header{\n        padding-inline: 2rem;\n        background-color: #1E1E1E;\n        height: 25vh;\n        // background-image: url('../../Assets/pngIcon/profilebg.png');\n        // background-repeat: no-repeat;\n        .MyProfilelayout--addVehicle {\n            display: flex;\n            justify-content: space-between;\n            padding-block: 3rem;\n            align-items: center;\n            p {\n                font-size: 18px;\n                font-weight: 600;\n                color: white;\n            }\n        }\n    }\n    .MyProfilelayout--body{\n        height: 80vh;\n        margin-top: -5vh;\n        padding-inline: 2rem;\n        background-color: white;\n        border-radius: 20px 20px 0 0 ;\n\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
