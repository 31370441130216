// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddMoney--Div p {
  background: #F2F2F2;
  padding: 8px 12px 8px 12px;
  width: 72px;
  text-align: center;
  font-size: 12px;
  border-radius: 12px;
  color: #333333; }
`, "",{"version":3,"sources":["webpack://./src/Components/Common/AddMoneySuggests/addmoneysuggests.scss"],"names":[],"mappings":"AAAA;EAEM,mBAAmB;EACjB,0BAAyB;EACzB,WAAW;EACX,kBAAkB;EAClB,eAAe;EACf,mBAAmB;EACnB,cAAc,EAAA","sourcesContent":[".AddMoney--Div{\n    p{\n      background: #F2F2F2;\n        padding:8px 12px 8px 12px;\n        width: 72px;\n        text-align: center;\n        font-size: 12px;\n        border-radius: 12px;\n        color: #333333;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
