import React from 'react'
import './Footer.scss'
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
      <footer className="Footer">
          <p className="heading">
              By continuing, you agree to our{' '}
              <span className="terms">
                  <Link to="/termsandcondtions">
                      {' '}
                      <span>terms and conditions</span>
                  </Link>
              </span>{' '}
              and{" "}
              <Link to="/privacy-policy">
                  <span className="terms">{" "} privacy policy.</span>
              </Link>
          </p>
      </footer>
  );
}

export default Footer