import React from 'react';
import './Button.scss';

const Button = ({ text, onClick, variant, disabled, className, icon, glow = false }) => {
    const handleClick = () => {
        if (!disabled && onClick) {
            onClick();
        }
    };

    const buttonClassName = `custom-button ${className} ${
        disabled ? 'disabled' : ''
    } ${variant} ${glow ? `glow-${variant}` : ''}`;

    return (
        <button className={buttonClassName} onClick={handleClick} disabled={disabled}>
            {icon && <span className="button-icon">{icon}</span>}
            {text}
        </button>
    );
};

Button.defaultProps = {
    onClick: () => {},
    variant: 'primary',
    size: 'medium',
    disabled: false,
    className: '',
    icon: null,
};

export default Button;
// how to use
//   const handleClick = () => {
//       alert('Button clicked!');
//   };
// <div style={{ display: "flex", gap: "2rem", flexDirection: "column",alignItems:"center" }}>
//   <Button
//     text="Click me"
//     onClick={handleClick}
//     variant="primary"
//     icon={<CarIcon />}
//     // glow={true}
//   />
//   <Button
//     text="Intense Glowing Primary Button"
//     // glow={true}
//     variant="primary"
//     icon={<CarIcon />}
//   />
//   <Button
//     text="Glowing Secondary Button"
//     glow={true}
//     variant="secondary"
//     icon={<CarIcon />}
//   />
// </div>
