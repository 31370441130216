import React from 'react';

const AddIcon = () => {
    return (
        <div>
            <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M15 20H25"
                    stroke="#00AA74"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20 25V15"
                    stroke="#00AA74"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <rect x="0.5" y="0.5" width="39" height="39" rx="7.5" stroke="#00AA74" />
            </svg>
        </div>
    );
};

export default AddIcon;
