import React from 'react';
import './aboutview.scss';
import { Pathname } from '../../../../Router/Pathname';
import { useNavigate } from 'react-router-dom';
import ArrowLeft from '../../../../Assets/icons/ArrowLeft';
import AboutFlashIcon from '../../../../Assets/icons/AboutFlashIcon';
import bgfooter from '../../../../Assets/pngIcon/bgfooter.png';
const AboutView = () => {
    const navigate = useNavigate();
    return (
        <main className="AboutView">
            <section className='SubAboutView'>
            <div className="AboutView--navigate">
                <span onClick={() => navigate(`${Pathname.PROFILE}`)}>
                    <ArrowLeft />
                </span>
                <p>About Us</p>
            </div>
            <div className="AboutView--content">
                <p>We provide modern electric vehicle charging systems</p>
                <span>
                    <AboutFlashIcon/>
                </span>
                <p>
                    We're committed to building a clean and green India by increasing the
                    EV adoption rate by building accessible infrastructure and technology.
                    We understand the electric vehicles completely and can connect you
                    with the appropriate charging solutions for retail, commercial,
                    residential and work place.
                </p>
            </div>
            </section>
            <div className="AboutView--bgconver">
                <img src={bgfooter} alt="" />
            </div>
        </main>
    );
};

export default AboutView;
