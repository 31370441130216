// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProfileAboutCard {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem; }
  .ProfileAboutCard .text {
    display: flex;
    justify-content: space-between;
    width: 46%;
    padding-left: 2rem;
    height: 80px;
    border-radius: 12px;
    background-color: #e6f7ef; }
    .ProfileAboutCard .text p {
      align-self: center;
      font-size: 14px;
      text-align: start;
      font-weight: 400;
      width: 60%; }
    .ProfileAboutCard .text span {
      padding-right: 2rem;
      width: 40%;
      text-align: end; }
      .ProfileAboutCard .text span svg {
        margin-top: 2rem; }
`, "",{"version":3,"sources":["webpack://./src/Components/ProfileCard/profileaboutcard.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,eAAe;EACf,8BAA8B;EAC9B,SAAS,EAAA;EAJb;IAMQ,aAAa;IACb,8BAAgC;IAChC,UAAU;IAEV,kBAAkB;IAElB,YAAY;IACZ,mBAAmB;IACnB,yBAAyB,EAAA;IAdjC;MAgBY,kBAAkB;MAClB,eAAe;MACf,iBAAiB;MACjB,gBAAgB;MAChB,UAAU,EAAA;IApBtB;MAuBY,mBAAmB;MACnB,UAAU;MACV,eAAe,EAAA;MAzB3B;QA2BgB,gBAAgB,EAAA","sourcesContent":[".ProfileAboutCard {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-between;\n    gap: 2rem;\n    .text {\n        display: flex;\n        justify-content: space-between  ;\n        width: 46%;\n        // padding: 2rem;\n        padding-left: 2rem;\n        // padding-top: 2rem;\n        height: 80px;\n        border-radius: 12px;\n        background-color: #e6f7ef;\n        p{\n            align-self: center;\n            font-size: 14px;\n            text-align: start;\n            font-weight: 400;\n            width: 60%;\n        }\n        span{\n            padding-right: 2rem;\n            width: 40%;\n            text-align: end;\n            svg{\n                margin-top: 2rem;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
