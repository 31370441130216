import { createSlice } from '@reduxjs/toolkit';
import { state } from './state';
import { getVehicle } from './actionCreator';

export const vehicleSlice = createSlice({
    name: 'vehicle',
    initialState: state,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(getVehicle.fulfilled, (state, action) => {
            state.isLoading = false;
            state.isError = '';
            state.isSuccess = true;
            state.vehicleDetail=action?.payload?.vehicles;
        });

    }
});
export default vehicleSlice.reducer;