import ga4 from 'react-ga4';

const TRACKING_ID = 'G-TWZLR4E682'
const isProduction = process.env.NODE_ENV === 'production'
console.log("Env", process.env.NODE_ENV);

export const ReactGA = {
    init: () => ga4.initialize(TRACKING_ID, {
        // testMode: !isProduction
        testMode: !isProduction,
        // debug: !isProduction
    }),
    // sendPageView: () => ga4.pageview(window.location.pathname + window.location.search),
    sendPageView: (title) => ga4.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search,
        title: title
    }),
    sendEvent: (data) => ga4.event(data)
}