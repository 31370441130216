import EmspView from './EmspView/EmspView';
import HelpAndSupportView from './HelpAndSupportView/HelpAndSupportView';
import HomeView from './HomeView/HomeView';
import MyVehiclesView from './MyVehiclesView/MyVehiclesView';
import SplashScreenView from './SplashScreenView/SplashScreenView';
import WalletView from './WalletView/WalletView';

export {
    EmspView,
    HelpAndSupportView,
    HomeView,
    MyVehiclesView,
    SplashScreenView,
    WalletView,
};
