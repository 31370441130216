import React, { useEffect, useState } from 'react';
import './LoginView.scss';
import LoginLayout from '../../../Layouts/LoginLayout/LoginLayout';
import Input from '../../../Components/Common/Input/Input';
import Button from '../../../Components/Common/Button/Button';
import OTPInput from '../../../Components/Common/OTP/OTPInput';
import Warning from '../../../Assets/icons/Warning/Warning';
import EditIcon from '../../../Assets/edit.png';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sendOtpToMobile } from './redux/actionCreator';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReverseTimer from '../../../Components/Common/OTP/ReverseTimer';
import LoadingImg from '../../../Assets/loading.gif';
import WhiteBack from '../../../Assets/icons/WhiteBack';

const LoginView = () => {
    const cqrScannedURL = sessionStorage.getItem('connector_id');
    console.log('cqrScannedURL: ', cqrScannedURL);
    const [inputData, setInputData] = useState({
        mobile: '',
    });
    const { otp } = useSelector((store) => store);
    const { error, loading, otpInfo, otpVerification } = otp;
    // console.log('error', otpInfo);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [optScreen, setOtpScreen] = useState(false);
    const [isOtpCorrect, setIsOtpCorrect] = useState(null);
    const [showResendTimer, setShowResendTimer] = useState(false);
    const [countdown, setCountdown] = useState(60);

    const handleOtpChange = async (isCorrect) => {
        setIsOtpCorrect(isCorrect);
        if (!isCorrect) {
            setShowResendTimer(true);
        }
    };

    useEffect(() => {
        if (otpVerification?.success) {
            if (isOtpCorrect && !otpVerification?.payload?.customer?.is_onboarded) {
                navigate('/userinfo');
            } else if (isOtpCorrect && otpVerification?.payload?.customer?.is_onboarded) {
                cqrScannedURL
                    ? navigate(
                          `/start-charging/select-connection?connector_id=${cqrScannedURL}`
                      )
                    : navigate('/home');
            }
        }
    }, [isOtpCorrect, cqrScannedURL]);

    const handleValueChange = (fieldName, value) => {
        setInputData((prevInputData) => ({
            ...prevInputData,
            [fieldName]: value,
        }));
    };

    const handleSubmit = async () => {
        const res = await dispatch(sendOtpToMobile(inputData?.mobile));
        if (res?.payload?.success) {
            toast.success(`${res?.payload?.message}`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                style: {
                    fontSize: '16px',
                },
            });
            setOtpScreen(true);
            setShowResendTimer(true);
        } else {
            toast.error(`Failed to send OTP`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                style: {
                    fontSize: '16px',
                },
            });
        }
    };

    const handleGoBack = () => {
        setOtpScreen(false);
        setInputData(inputData?.mobile.length === 0);
    };

    const handleResendOTP = () => {
        dispatch(sendOtpToMobile(inputData?.mobile));
        console.log('Resending OTP...');
    };

    return (
        <LoginLayout className="LoginView">
            {!optScreen && (
                <div className="LoginView-container">
                    <div>
                        <p className="LoginView-heading">Let’s get Started</p>
                    </div>
                    <div>
                        <p className="LoginView-line">
                            Please enter your mobile number for OTP verification
                        </p>
                    </div>
                    <div>
                        <Input
                            label="Enter mobile no."
                            maxLength={10}
                            type="number"
                            onValueChange={(value) => handleValueChange('mobile', value)}
                        />
                    </div>

                    <div>
                        <Button
                            text="Continue"
                            onClick={handleSubmit}
                            variant={
                                inputData?.mobile?.length !== 10 ? 'disabled' : 'primary'
                            }
                            disabled={
                                inputData?.mobile?.length !== 10 ? true : false || loading
                            }
                        />
                    </div>
                    <ToastContainer />
                </div>
            )}
            {optScreen && (
                <div className="LoginView-container">
                    {isOtpCorrect !== null && (
                        <div>
                            {!isOtpCorrect && (
                                <span
                                    onClick={handleGoBack}
                                    style={{
                                        position: 'absolute',
                                        zIndex: '1000',
                                        top: '1rem',
                                    }}
                                >
                                    <WhiteBack />
                                </span>
                            )}
                        </div>
                    )}

                    <div>
                        <p className="LoginView-heading">OTP Verification</p>
                    </div>
                    <div>
                        <p className="LoginView-line">
                            We have sent a verification code to{' '}
                            {otpInfo?.payload?.payload?.to}{' '}
                            <span style={{ cursor: 'pointer' }} onClick={handleGoBack}>
                                <img src={EditIcon} alt="" srcset="" />
                            </span>
                        </p>
                    </div>
                    <div className="">
                        <OTPInput otpLength={4} onOtpChange={handleOtpChange} />
                        {loading ? (
                            <div
                                style={{
                                    width: '4rem',
                                    margin: 'auto',
                                }}
                            >
                                <img
                                    width={'300%'}
                                    style={{ position: 'relative', right: '100%' }}
                                    src={LoadingImg}
                                    alt="loading"
                                    srcset=""
                                />
                            </div>
                        ) : (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        marginTop: '10px',
                                    }}
                                >
                                    <div>
                                        <ReverseTimer
                                            onResendOTP={handleResendOTP}
                                            startTimer={true}
                                        />
                                    </div>
                                    <div>
                                        {isOtpCorrect !== null && (
                                            <div
                                                className={
                                                    isOtpCorrect ? 'correct' : 'incorrect'
                                                }
                                            >
                                                <div className="LoginView-warning">
                                                    <div className="LoginView-warningMsg">
                                                        <div>
                                                            {!isOtpCorrect && (
                                                                <span>
                                                                    <Warning />
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className="LoginView-msg">
                                                            {isOtpCorrect
                                                                ? ''
                                                                : 'Incorrect OTP'}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                        {/* <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '10px',
                            }}
                        >
                            <div>
                                <ReverseTimer
                                    onResendOTP={handleResendOTP}
                                    startTimer={true}
                                />
                            </div>
                            <div>
                                {isOtpCorrect !== null && (
                                    <div
                                        className={isOtpCorrect ? 'correct' : 'incorrect'}
                                    >
                                        <div className="LoginView-warning">
                                            <div className="LoginView-warningMsg">
                                                <div>
                                                    {!isOtpCorrect && (
                                                        <span>
                                                            <Warning />
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="LoginView-msg">
                                                    {isOtpCorrect ? '' : 'Incorrect OTP'}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div> */}
                    </div>
                </div>
            )}
            <ToastContainer />
        </LoginLayout>
    );
};

export default LoginView;
