import React from 'react';

export default function AboutBook() {
    return (
        <div>
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M2.3335 12.0002L2.3335 4.66683C2.3335 2.00016 3.00016 1.3335 5.66683 1.3335L10.3335 1.3335C13.0002 1.3335 13.6668 2.00016 13.6668 4.66683L13.6668 11.3335C13.6668 11.4268 13.6668 11.5202 13.6602 11.6135"
                    stroke="#00AA74"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.2335 10L13.6668 10L13.6668 12.3333C13.6668 13.62 12.6202 14.6667 11.3335 14.6667L4.66683 14.6667C3.38016 14.6667 2.3335 13.62 2.3335 12.3333L2.3335 11.9C2.3335 10.8533 3.18683 10 4.2335 10Z"
                    stroke="#00AA74"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.3335 4.6665L10.6668 4.6665"
                    stroke="#00AA74"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5.3335 7L8.66683 7"
                    stroke="#00AA74"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
}
