import { createSlice } from '@reduxjs/toolkit';
import {
    filterStations,
    getallStations,
    fetchConnectorStandards,
    getConnectorById,
} from './action';

const initialState = {
    loading: false,
    allStations: [],
    hasMore: true,
    page: 0,
    error: null,
    connectorStandards: [],
    connectorDetails: [],
};

const searchStationsSlice = createSlice({
    name: 'searchStations',
    initialState,
    reducers: {
        setStations(state, action) {
            state.allStations = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Fetch all stations
            .addCase(getallStations.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getallStations.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                const chargingStations = action.payload?.payload?.finalData || [];
                //stations reset on page 0
                if (state.page === 0) {
                    state.allStations = chargingStations;
                }
                //stations get added with next pages
                else {
                    state.allStations = [...state.allStations, ...chargingStations];
                }
                //payload has more stations to display
                state.hasMore = chargingStations.length > 0;
                state.page += 1;
            })
            .addCase(getallStations.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                state.allStations = [];
                state.hasMore = false;
            })

            // Filter stations
            .addCase(filterStations.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.allStations = [];
                state.page = 0;
            })
            .addCase(filterStations.fulfilled, (state, action) => {
                state.loading = false;
                state.error = null;
                state.allStations = action.payload?.payload?.finalData || [];
                //payload has more stations to display
                state.hasMore = action.payload?.payload?.finalData?.length > 0;
                state.page += 1;
            })
            .addCase(filterStations.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
                state.allStations = [];
                state.hasMore = false;
            })

            // Fetch connector standards
            .addCase(fetchConnectorStandards.pending, (state) => {
                state.error = null;
            })
            .addCase(fetchConnectorStandards.fulfilled, (state, action) => {
                state.connectorStandards =
                    action.payload?.payload?.connectorStandards || [];
                state.error = null;
            })
            .addCase(fetchConnectorStandards.rejected, (state, action) => {
                state.connectorStandards = [];
                state.error = action.error.message;
            })
            //getConnectorsById

            .addCase(getConnectorById.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.stationName = null;
                state.connectorDetails = [];
            })
            .addCase(getConnectorById.fulfilled, (state, action) => {
                state.loading = false;
                state.connectorDetails = [];
                const payloadData = action.payload?.payload?.finalData[0];
                // Nested Connnectors Data
                payloadData?.charging_point?.forEach((point) => {
                    point?.connector_meta_cp?.map((connector) => {
                        const details = {
                            charging_point_name: point?.charging_point_name,
                            connector_meta_cp_id: connector.id,
                            connector_meta_cp_status: connector.status,
                            connector_meta_cp_name: connector.standard,
                            connector_io_type: connector?.io_type,
                            id: connector?.connector_id,
                        };

                        state.connectorDetails.push(details);
                    });
                });

                state.error = null;
            })

            .addCase(getConnectorById.rejected, (state, action) => {
                state.loading = false;
                state.connectorDetails = [];
                state.error = action.error.message;
            });
    },
});

export const { setStations } = searchStationsSlice.actions;
export default searchStationsSlice.reducer;
