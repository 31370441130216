import React from 'react';

const HomeHeaderBg = () => {
    return (
        <div>
            <svg
                width="360"
                height="364"
                viewBox="0 0 360 364"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g filter="url(#filter0_f_73_2721)">
                    <circle
                        cx="178"
                        cy="181"
                        r="101.5"
                        stroke="url(#paint0_linear_73_2721)"
                    />
                </g>
                <g opacity="0.44" filter="url(#filter1_f_73_2721)">
                    <circle
                        cx="180"
                        cy="188"
                        r="147.5"
                        stroke="url(#paint1_linear_73_2721)"
                    />
                </g>
                <g opacity="0.22" filter="url(#filter2_f_73_2721)">
                    <circle
                        cx="180"
                        cy="180"
                        r="179.5"
                        stroke="url(#paint2_linear_73_2721)"
                    />
                </g>
                <defs>
                    <filter
                        id="filter0_f_73_2721"
                        x="72"
                        y="75"
                        width="212"
                        height="212"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                        />
                        <feGaussianBlur
                            stdDeviation="2"
                            result="effect1_foregroundBlur_73_2721"
                        />
                    </filter>
                    <filter
                        id="filter1_f_73_2721"
                        x="28"
                        y="36"
                        width="304"
                        height="304"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                        />
                        <feGaussianBlur
                            stdDeviation="2"
                            result="effect1_foregroundBlur_73_2721"
                        />
                    </filter>
                    <filter
                        id="filter2_f_73_2721"
                        x="-4"
                        y="-4"
                        width="368"
                        height="368"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                    >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="BackgroundImageFix"
                            result="shape"
                        />
                        <feGaussianBlur
                            stdDeviation="2"
                            result="effect1_foregroundBlur_73_2721"
                        />
                    </filter>
                    <linearGradient
                        id="paint0_linear_73_2721"
                        x1="76"
                        y1="91.75"
                        x2="285.318"
                        y2="97.7598"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#00AA74" />
                        <stop offset="1" stopColor="#0BD393" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_73_2721"
                        x1="32"
                        y1="58.5"
                        x2="335.717"
                        y2="67.22"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#00AA74" />
                        <stop offset="1" stopColor="#0BD393" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_73_2721"
                        x1="-1.00321e-06"
                        y1="22.5"
                        x2="369.385"
                        y2="33.1054"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#00AA74" />
                        <stop offset="1" stopColor="#0BD393" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
}

export default HomeHeaderBg;