export const Pathname = {
    SPLASH_SCREEN: '/',
    LOGIN: '/login',
    HOME: '/home',
    EMSP: '/emsp',
    SELECT_CONNECTION: '/select-connection',
    INTIATE_CHARGING: '/start-charging/intiate-charging',
    HELP_AND_SUPPORT: '/help-and-support',
    MY_VEHICLES: '/my-vehicles',
    PROFILE: '/profile',
    WALLET: '/wallet',
    COMPONENTS: '/components',
    USERINFO: '/userinfo',
    TERMS: '/termsandcondtions',
    PRIVACY: '/privacy-policy',
    START_CHARGING: '/start-charging',
    ENERGY_BASED: '/start-charging/energy-based',
    LARGESCREEN: '/largescreen',
    SEARCH_STATIONS: '/search-stations',
    SEARCH_CONNECTOR: '/search-stations/connectors',
};
