import React from 'react';
import { Button } from '../../../../../Components/Common';
import { useNavigate } from 'react-router-dom';
import { Pathname } from '../../../../../Router/Pathname';

const EnergyModal = ({ connector_id }) => {
    const navigate = useNavigate();
    return (
        <div className="IntiateCharging--content">
            <p className="funds">Insufficient Funds</p>
            <p className="fundInfo">Please add money in wallet to charge your vehicle</p>
            <div
                onClick={() =>
                    navigate(`${Pathname.START_CHARGING}/add-money`, {
                        state: connector_id,
                    })
                }
            >
                <Button className="addWallet" text="Add money to wallet" />
            </div>
        </div>
    );
};

export default EnergyModal;
