import React from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './CircularProgress.scss';
import Flash from '../../../Assets/icons/Flash';


const CircularProgress = ({ percentage,gifUrl }) => {
    // console.log("gif",gifUrl)
    // Ensure the percentage doesn't go above 100
    const clampedPercentage = Math.min(percentage, 100);

    return (
        <div className="circular-progress-container">
            <CircularProgressbarWithChildren
                value={clampedPercentage} // Use the clamped percentage value
                maxValue={100}
                minValue={0}
                strokeWidth={10}
                background={true}
                backgroundPadding={5}
                text={clampedPercentage ? `${clampedPercentage}%`:null}
                styles={buildStyles({
                    pathColor: `#00AA74`,
                    textColor: '#00AA74',
                    backgroundColor: '#ffffff',
                })}
            >
                {gifUrl ? (
                    <img width={"35%"} src={gifUrl} alt="logo" srcset="" />
                ) : (
                    <div className="progress-content">
                        <div className="doge-image">
                            <Flash />
                        </div>

                        {/* Change "Charged" to "Full Charged" at 100% */}
                        <div className="mate-text">
                            {clampedPercentage === 100 ? 'Full Charged' : 'Charged'}
                        </div>
                    </div>
                )}
            </CircularProgressbarWithChildren>
        </div>
    );
};

export default CircularProgress;
