// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PluginCard {
  padding: 4rem 3rem;
  height: auto;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  background-image: url("https://i.postimg.cc/wTySK2c6/Plug-In-Wave-with-BG.gif");
  background-size: 120% 120%;
  background-repeat: no-repeat;
  background-position: center center; }

.charger {
  background-color: #fff1e8; }

.plugin {
  background-color: #e6f7ef; }

.icon {
  text-align: center; }

.header {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin: 20px 0; }

.subheading {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left; }

.IntiateCharging-true {
  background: #e6f7ef; }
`, "",{"version":3,"sources":["webpack://./src/Components/HomeCards/PluginCard/PluginCard.scss"],"names":[],"mappings":"AACA;EACI,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;EACnB,2CAA2C;EAC3C,+EAA+E;EAC/E,0BAA0B;EAC1B,4BAA4B;EAC5B,kCAAkC,EAAA;;AAEtC;EACI,yBAAyB,EAAA;;AAG7B;EACI,yBAAyB,EAAA;;AAE7B;EACI,kBAAkB,EAAA;;AAEtB;EACI,aAAa;EACb,uBAAuB;EAEvB,SAAS;EACT,cAAc,EAAA;;AAGlB;EACI,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB,EAAA;;AAMpB;EACI,mBAAmB,EAAA","sourcesContent":["\n.PluginCard {\n    padding: 4rem 3rem;\n    height: auto;\n    border-radius: 20px;\n    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;\n    background-image: url(\"https://i.postimg.cc/wTySK2c6/Plug-In-Wave-with-BG.gif\");\n    background-size: 120% 120%;\n    background-repeat: no-repeat;\n    background-position: center center;\n}\n.charger {\n    background-color: #fff1e8;\n}\n\n.plugin {\n    background-color: #e6f7ef;\n}\n.icon {\n    text-align: center;\n}\n.header {\n    display: flex;\n    justify-content: center;\n\n    gap: 10px;\n    margin: 20px 0;\n}\n\n.subheading {\n    font-size: 20px;\n    font-weight: 700;\n    line-height: 24px;\n    letter-spacing: 0.02em;\n    text-align: left;\n}\n.IntiateCharging-false {\n    // background-color: #FFF1E8;\n    // background-image: url(\"../../../Assets/PluginWave.gif\");\n}\n.IntiateCharging-true {\n    background: #e6f7ef;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
