import React, { useEffect } from 'react';
import './Navbar.scss';
import QRIcon from '../../../Assets/icons/QRIcon';
import HomeIcon from '../../../Assets/icons/HomeIcon';
import CarIcon from '../../../Assets/icons/CarIcon';
import WalletIcon from '../../../Assets/icons/WalletIcon';
import ProfileIcon from '../../../Assets/icons/ProfileIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import { Pathname } from '../../../Router/Pathname';
import { useDispatch, useSelector } from 'react-redux';
import ProgressBar from '../ProgressBar/ProgressBar';
import { continousChargingStatus } from '../../../Views/AfterAuth/HomeView/redux/homeSlice';

const Navbar = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { homeInfo, chargingValues } = useSelector((store) => store.home);
    const chargingData = chargingValues?.payload;

    const ocpp_transaction_id = homeInfo?.payload?.last_sesh?.ocpp_transaction_id;
    const completion_percentage = chargingData?.chargingStatus?.rfid_tag && chargingValues?.payload?.chargingStatus?.current_soc
        ? chargingValues?.payload?.chargingStatus?.current_soc
        : (chargingValues?.payload?.chargingStatus?.current_soc ? chargingValues?.payload?.chargingStatus?.current_soc : chargingValues?.payload?.completion_percentage);

    useEffect(() => {
        let time;
        if (homeInfo?.payload?.last_sesh?.status?.toLowerCase() == 'charging') {
            time = setInterval(() => {
                dispatch(continousChargingStatus(ocpp_transaction_id));
            }, 5000);
        }
        return () => clearInterval(time);
    }, [homeInfo, ocpp_transaction_id]);

    return (
        //routes select charging replace/ start-charging
        <div className="navbar-container">
            <div
                className="semi-circle"
                onClick={() => {
                    homeInfo?.payload?.last_sesh?.status?.toLowerCase() == 'charging'
                        ? navigate(`${Pathname.START_CHARGING}/start-charging`)
                        : navigate(`${Pathname.START_CHARGING}`);
                }}
            >
                <div className="qr-icon">
                    {homeInfo?.payload?.last_sesh?.status?.toLowerCase() == 'charging' ? (
                        <ProgressBar
                            value={
                                !chargingValues?.payload?.chargingStatus?.current_soc
                                    ? chargingValues?.payload?.chargingStatus
                                        ?.charge_duration
                                    : chargingValues?.payload?.chargingStatus
                                        ?.current_soc + '%'
                            }
                            percentage={completion_percentage}
                        />
                    ) : (
                        <QRIcon />
                    )}
                </div>
            </div>
            <div className="navbar-content">
                <div onClick={() => navigate(`${Pathname.HOME}`)}>
                    <span
                        className={`${location.pathname}` === '/home' ? 'customnav' : ''}
                    >
                        <HomeIcon />
                    </span>
                </div>
                <div
                    className="option2"
                    onClick={() => navigate(`${Pathname.MY_VEHICLES}`)}
                >
                    <span
                        className={
                            `${location.pathname}` === '/my-vehicles' ? 'customnav' : ''
                        }
                    >
                        <CarIcon />
                    </span>
                </div>
                <div className="option3" onClick={() => navigate(`${Pathname.WALLET}`)}>
                    <span
                        className={
                            `${location.pathname}` === '/wallet' ? 'customnav' : ''
                        }
                    >
                        <WalletIcon />
                    </span>
                </div>
                <div onClick={() => navigate(`${Pathname.PROFILE}`)}>
                    <span
                        className={
                            `${location.pathname}` === '/profile' ? 'customnav' : ''
                        }
                    >
                        <ProfileIcon />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Navbar;
