import React from 'react';
import ArrowLeft from '../../../../Assets/icons/ArrowLeft';
import FuelIcon from '../../../../Assets/icons/FuelIcon';
import { useNavigate } from 'react-router-dom';
import { Pathname } from '../../../../Router/Pathname';

const ConnectorHeader = ({ stationName }) => {
    const navigate = useNavigate();
    return (
        <>
            {' '}
            <section className="SearchConnector__Header">
                <div
                    className="SearchConnector__Left"
                    onClick={() => navigate(Pathname.SEARCH_STATIONS)}
                >
                    <ArrowLeft />
                </div>
                <div className="SearchConnector__Right">
                    <div className="SearchConnector__Title">Search Connector</div>
                    <div className="SearchConnector__Address">
                        <FuelIcon />
                        <span className="SearchConnector__SubTitle">{stationName}</span>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ConnectorHeader;
