import React, { useState, useEffect, useRef } from 'react';
import './OTPInput.scss';
import { useDispatch, useSelector } from 'react-redux';
import { verifyOtp } from '../../../Views/beforeAuth/LoginView/redux/actionCreator';
import { useNavigate } from 'react-router-dom';
import { Pathname } from '../../../Router/Pathname';

const OTPInput = ({ otpLength, onOtpChange }) => {
    const navigateTo = useNavigate();

    const [otpValues, setOtpValues] = useState(Array(otpLength).fill(''));
    const [isSuccess, setIsSuccess] = useState(false);
    const { otp } = useSelector((store) => store);
    const { error, loading, otpInfo } = otp;
    const dispatch = useDispatch();
    // console.log('error', otp);
    const data = useSelector(
        (state) => state?.otp?.otpVerification?.payload?.customer?.is_onboarded
    );
    const inputRefs = useRef([]);

    useEffect(() => {
        inputRefs.current[0]?.focus();
    }, []);

    // useEffect(() => {
    //   if (otpValues.join("").length === otpLength) {
    //     // Simulating OTP validation API call
    //     setTimeout(() => {
    //       if (otpValues.join("") === "1234") {
    //         setIsSuccess(true);
    //         onOtpChange(true); // Notify parent component of correct OTP
    //       } else {
    //         setIsSuccess(false);
    //         onOtpChange(false); // Notify parent component of incorrect OTP
    //       }
    //     }, 1000);
    //   }
    // }, [otpValues, otpLength, onOtpChange]);

    useEffect(async () => {
        const enteredOtp = otpValues.join('');
        if (enteredOtp.length === otpLength) {
            const res = await dispatch(
                verifyOtp({
                    phoneNumber: otpInfo?.payload?.payload?.to,
                    otp: enteredOtp,
                })
            );

            //   console.log(
            //       'res?.payload?.payload?.access_token',
            //       res?.payload?.payload?.access_token
            //   );

            if (res.payload?.success) {
                setIsSuccess(true);
                onOtpChange(true);
                localStorage.setItem('t_id', res?.payload?.payload?.access_token);
                // console.log(res.payload?.success, 'masum');
                // navigateTo(Pathname.HOME);
                // localStorage.setItem('refreshToken', res?.payload?.payload?.refresh_token);
            } else {
                setIsSuccess(false);
                onOtpChange(false);
            }
        }
    }, [otpValues.join('').length === otpLength]);

    const handleOtpChange = (index, value) => {
        if (value.match(/^\d*$/)) {
            const newOtpValues = [...otpValues];
            newOtpValues[index] = value;
            setOtpValues(newOtpValues);

            // Focus the previous input field on backspace press
            if (value === '' && index > 0) {
                inputRefs.current[index - 1]?.focus();
            } else if (index < otpLength - 1 && value) {
                inputRefs.current[index + 1]?.focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && index > 0 && otpValues[index] === '') {
            // When backspace is pressed in an empty input field, focus on the previous input
            inputRefs.current[index - 1]?.focus();
        }
    };

    return (
        <div className="otp-input-container">
            {otpValues.map((value, index) => (
                <input
                    key={index}
                    ref={(ref) => (inputRefs.current[index] = ref)}
                    type="number"
                    value={value}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    className={`otp-input ${isSuccess ? 'success' : ''} ${
                        !isSuccess && value ? 'error' : ''
                    }`}
                    maxLength={1}
                    inputMode="numeric"
                />
            ))}
        </div>
    );
};

export default OTPInput;
