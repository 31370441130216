import React from 'react'

const Flash = () => {
    return (
        <svg
            width="32"
            height="33"
            viewBox="0 0 32 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M19.3276 15.2258V14.7934V5.19336C19.3276 4.66701 19.2556 4.28006 19.1476 4.01892C19.0406 3.75987 18.9145 3.65836 18.8164 3.62154C18.718 3.58462 18.5555 3.578 18.3032 3.70244C18.0491 3.82776 17.739 4.07102 17.3913 4.46571C17.3913 4.46577 17.3912 4.46582 17.3912 4.46588L16.3248 5.67888L16.3248 5.67889L7.29811 15.9456L7.29706 15.9467C6.71045 16.609 6.67623 17.0598 6.77584 17.2805C6.87565 17.5017 7.23696 17.7743 8.12002 17.7743H12.24H12.6724V18.2067V27.8067C12.6724 28.333 12.7445 28.72 12.8524 28.9811C12.9594 29.2402 13.0856 29.3417 13.1837 29.3785C13.282 29.4154 13.4446 29.4221 13.6969 29.2976C13.9509 29.1723 14.2609 28.9291 14.6086 28.5345L19.3276 15.2258ZM19.3276 15.2258H19.76H23.88C24.7631 15.2258 25.1244 15.4984 25.2242 15.7196C25.3238 15.9403 25.2896 16.391 24.703 17.0533L24.7019 17.0545L15.6753 27.3212L15.6752 27.3212L14.6089 28.5342L19.3276 15.2258Z"
                fill="#F9C237"
                stroke="#F9C237"
                strokeWidth="0.864865"
            />
        </svg>
    );
}

export default Flash