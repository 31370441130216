import React from 'react';
const ClockWithBg = () => {
    return (
        <div>
            <svg
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width="40" height="40" rx="8" fill="#E6F7EF" />
                <path
                    d="M19.9998 28.4584C15.3343 28.4584 11.5415 24.6656 11.5415 20.0001C11.5415 15.3346 15.3343 11.5417 19.9998 11.5417C24.6654 11.5417 28.4582 15.3346 28.4582 20.0001C28.4582 24.6656 24.6654 28.4584 19.9998 28.4584ZM19.9998 11.7917C15.4737 11.7917 11.7915 15.4739 11.7915 20.0001C11.7915 24.5262 15.4737 28.2084 19.9998 28.2084C24.526 28.2084 28.2082 24.5262 28.2082 20.0001C28.2082 15.4739 24.526 11.7917 19.9998 11.7917Z"
                    stroke="#00AA74"
                />
                <path
                    d="M23.0525 22.7673L23.0422 22.7603L23.0314 22.7539L20.4483 21.2124C20.4482 21.2124 20.4482 21.2123 20.4481 21.2123C19.9579 20.9193 19.5752 20.241 19.5752 19.675V16.2583C19.5752 16.2282 19.5875 16.1969 19.6131 16.1712C19.6388 16.1456 19.67 16.1333 19.7002 16.1333C19.7303 16.1333 19.7616 16.1456 19.7873 16.1712C19.8129 16.1969 19.8252 16.2282 19.8252 16.2583V19.675C19.8252 19.9433 19.9292 20.2156 20.0479 20.4242C20.1656 20.6313 20.3463 20.8608 20.5795 20.9973L23.1606 22.5377L23.1606 22.5377L23.1649 22.5402C23.2136 22.5686 23.2424 22.6375 23.2052 22.7082C23.1715 22.7576 23.1256 22.775 23.0919 22.775C23.0762 22.775 23.0671 22.7732 23.0628 22.772C23.0591 22.771 23.0563 22.7698 23.0525 22.7673Z"
                    stroke="#00AA74"
                />
            </svg>
        </div>
    );
};

export default ClockWithBg;
