import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Buffer } from 'buffer';
import Server from '../../../../Server';
import { updateChargingValue, updateStopChargingStatus } from './action';

const getCustomerId = (token) => {
    const parts = token.split('.');
    const payload =
        parts && parts.length && Buffer.from(parts[1], 'base64').toString('utf8');
    const payloadObj = JSON.parse(payload);
    return payloadObj?.customerId;
};

const initialState = {
    loading: false,
    homeInfo: null,
    error: null,
    initiateChargingStatus: null,
    startChargingPermission: null,
    walletBalance: null,
    startChargingInfo: null,
    connectorData: null,
    chargingValues: null,
    stopChargingStatus: null,
    ConnectorDetails: null,
    allStations: [],
};

export const getHomeDetails = createAsyncThunk('home/homeInformation', async () => {
    try {
        const res = await Server.get(
            `/api/v1/customer/chargingSession/home-screen`,
            { customerId: getCustomerId(localStorage.getItem('t_id')) },
            true
        );
        return res;
    } catch (error) {
        console.log('error', error);
    }
});

export const initiateCharging = createAsyncThunk('home/initiateCharging', async (id) => {
    try {
        const res = Server.get(
            `/api/v1/customer/ocpp/initiate-charging?connector_meta_cp_id=${id}`,
            true
        );
        return res;
    } catch (error) {
        console.log('error', error);
    }
});
export const startChargingStatus = createAsyncThunk(
    'home/startChargingStatus',
    async (id) => {
        try {
            const res = Server.get(
                `/api/v1/customer/ocpp/pluggedin-event-poll?connector_meta_cp_id=${id}`,
                true
            );
            return res;
        } catch (error) {
            console.log('error', error);
        }
    }
);
export const continousChargingStatus = createAsyncThunk(
    'home/giveChargingStatus',
    async (id) => {
        console.log('id--', id);
        try {
            const res = Server.get(
                `/api/v1/customer/ocpp/charging-status?ocpp_transaction_id=${id}`,
                true
            );
            return res;
        } catch (error) {
            console.log('error', error);
        }
    }
);

export const createWallet = createAsyncThunk('home/createWallet', async () => {
    try {
        const res = Server.post(
            `/api/v1/csms/wallet`,
            { customer_id: getCustomerId(localStorage.getItem('t_id')) },
            true
        );
        return res;
    } catch (error) {
        console.log('error', error);
    }
});

export const startCharging = createAsyncThunk(
    'home/startCharging',
    async ({ connector_id, calculatedAmount, calculatedEnergy }) => {
        try {
            const res = await Server.post(
                '/api/v1/customer/ocpp/start-charging',
                {
                    connector_id: connector_id,
                    amount: calculatedAmount,
                    energy: calculatedEnergy,
                },
                true
            );
            return res;
        } catch (error) {
            console.log('error', error);
        }
    }
);

export const stopCharging = createAsyncThunk(
    'home/stopCharging',
    async ({ charging_point_id, transaction_id }) => {
        try {
            const res = await Server.post(
                '/api/v1/customer/ocpp/stop-charging',
                {
                    charging_point_id: charging_point_id,
                    transaction_id: transaction_id,
                },
                true
            );
            return res;
        } catch (error) {
            console.log('error', error);
        }
    }
);

export const getWalletBalance = createAsyncThunk('home/getWalletBalance', async () => {
    try {
        const res = await Server.post(
            '/api/v1/csms/wallet/getwalletBalance',
            {
                customer_id: getCustomerId(localStorage.getItem('t_id')),
            },
            true
        );
        return res;
    } catch (error) {
        console.log('error', error);
    }
});
export const getConnector = createAsyncThunk('home/getConnector', async (data) => {
    try {
        console.log('data ======>', data);
        const res = await Server.get(
            `/api/v1/customer/ocpp/associated-connectors?connector_meta_cp_id=${data}`,
            true
        );
        return res;
    } catch (error) {
        console.log('error', error);
    }
});
export const refundBalance = createAsyncThunk('home/refundAllBalance', async () => {
    try {
        const res = await Server.get(`/api/v1/transaction/razorpay/refund`, true);
        return res;
    } catch (error) {
        console.log('error', error);
    }
});

const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        setChargingValue: updateChargingValue,
        setStopChargingStationStatus: updateStopChargingStatus,
    },
    extraReducers: (builder) => {
        builder
            .addCase(getHomeDetails.pending, (state) => {
                state.error = '';
                state.loading = true;
            })
            .addCase(getHomeDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.homeInfo = action.payload;
                state.error = '';
            })
            .addCase(getHomeDetails.rejected, (state, action) => {
                state.loading = false;
                state.homeInfo = null;
                state.error = action.error.message;
            })
            .addCase(initiateCharging.pending, (state) => {
                state.error = '';
                state.loading = true;
            })
            .addCase(initiateCharging.fulfilled, (state, action) => {
                state.loading = false;
                state.initiateChargingStatus = action.payload;
                state.error = '';
            })
            .addCase(initiateCharging.rejected, (state, action) => {
                state.loading = false;
                state.initiateChargingStatus = null;
                state.error = action.error.message;
            })
            .addCase(getWalletBalance.pending, (state) => {
                state.error = '';
                state.loading = true;
            })
            .addCase(getWalletBalance.fulfilled, (state, action) => {
                state.loading = false;
                state.walletBalance = action.payload;
                state.error = '';
            })
            .addCase(getWalletBalance.rejected, (state, action) => {
                state.loading = false;
                state.walletBalance = null;
                state.error = action.error.message;
            })
            .addCase(startCharging.pending, (state) => {
                state.error = '';
                state.loading = true;
            })
            .addCase(startCharging.fulfilled, (state, action) => {
                state.loading = false;
                state.startChargingInfo = action.payload;
                state.error = '';
            })
            .addCase(startCharging.rejected, (state, action) => {
                state.loading = false;
                state.startChargingInfo = null;
                state.error = action.error.message;
            })
            .addCase(getConnector.pending, (state) => {
                state.error = '';
                state.loading = true;
            })
            .addCase(getConnector.fulfilled, (state, action) => {
                state.loading = false;
                state.connectorData = action.payload;
                state.error = '';
            })
            .addCase(getConnector.rejected, (state, action) => {
                state.loading = false;
                state.connectorData = null;
                state.chargingStationName = null;
                state.error = action.error.message;
            })

            .addCase(startChargingStatus.pending, (state) => {
                state.error = '';
                state.loading = true;
                state.startChargingPermission = null;
            })
            .addCase(startChargingStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.startChargingPermission = action.payload;
                state.error = '';
            })
            .addCase(startChargingStatus.rejected, (state, action) => {
                state.loading = false;
                state.startChargingPermission = null;
                state.error = action.error.message;
            })
            .addCase(continousChargingStatus.pending, (state) => {
                state.error = '';
                state.loading = true;
            })
            .addCase(continousChargingStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.chargingValues = action.payload;
                state.error = '';
            })
            .addCase(continousChargingStatus.rejected, (state, action) => {
                state.loading = false;
                state.chargingValues = null;
                state.error = action.error.message;
            })
            .addCase(stopCharging.pending, (state) => {
                state.error = '';
                state.loading = true;
            })
            .addCase(stopCharging.fulfilled, (state, action) => {
                state.loading = false;
                state.stopChargingStatus = action.payload;
                state.error = '';
            })
            .addCase(stopCharging.rejected, (state, action) => {
                state.loading = false;
                state.stopChargingStatus = null;
                state.error = action.error.message;
            });
    },
});
export const { setChargingValue, setStopChargingStationStatus } = homeSlice.actions;
export default homeSlice.reducer;
