import React from 'react';
import './MiniStationCard.scss';
import GasIcon from '../../Assets/icons/GasIcon';
import Badges from '../Badges/Badges';
import GreyGasIcon from '../../Assets/icons/GreyGasIcon';
const MiniStationCard = ({ onClick, status = 'Available', name, address }) => {
    return (
        <div className={`MiniStationCard${status === "Unavailable" ? ' MiniStationCard--disabled' : ''}`}
             onClick={onClick}
             >
            <div className="MiniStationCard__LeftContent">
                {status === "Unavailable" ? <GreyGasIcon /> : <GasIcon />}
            </div>
            <div className="MiniStationCard__RightContent">
                <div className="MiniStationCard__RightTop">
                    <div className="MiniStationCard__Title">{name}</div>
                    <div className="MiniStationCard__Badges">
                        <Badges props={status} />
                    </div>
                </div>

                <div className="MiniStationCard__SubTitle">{address}</div>
            </div>
        </div>
    );
};

export default MiniStationCard;
