import React, { useEffect, useState } from 'react';
import './EnergyCalculation.scss';

import { Button } from '../../../../Components/Common';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Pathname } from '../../../../Router/Pathname';
import Modal from '../../../../Components/Common/Modal/Modal';
import walleticon from '../../../../Assets/pngIcon/walletXl.png';
import Stationbanner from './StationBanner/Stationbanner';
import EnergyHeader from './EnergyHeader/EnergyHeader';
import EnergyModal from './EnergyModal/EnergyModal';
import { calculateUnit } from './Redux/action';
import IncDecrement from '../../../../Components/Common/IncDecrement/InDecrement';
import { setPrice, setCharge } from './Redux/EnergySlice';
const EnergyCalculation = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { energy } = useSelector((store) => store);
    const {
        redirectToAddMoney,
        isZeroTarrif,
        calculatedEnergy,
        calculatedAmount,
        loading,
    } = energy;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [amountVal, setAmountVal] = useState(calculatedAmount);
    const [energyVal, setEnergyVal] = useState(calculatedEnergy);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleRecharge = () => {
        if (!redirectToAddMoney) {
            navigate(Pathname.INTIATE_CHARGING, {
                state: {
                    calculatedEnergy: calculatedEnergy,
                    calculatedAmount: calculatedAmount,
                    isZeroTarrif: isZeroTarrif,
                    connector_id: location?.state?.connector_id,
                },
            });
        } else if (redirectToAddMoney) {
            openModal();
        }
    };

    /*=============================================
    =            Amount Section             =
    =============================================*/

    const handleAmountChange = (e) => {
        const newAmount = e.target.value;
        setAmountVal(newAmount);
        dispatch(setPrice(newAmount));
        // if (!newAmount) {
        //     setEnergyVal('');
        //     dispatch(setCharge(''));
        // }
    };
    const handleIncrementAmount = () => {
        const newValue = Math.max(parseInt(calculatedAmount) + 10, 0);
        setAmountVal(newValue);
        dispatch(setPrice(newValue));
        dispatch(setCharge(calculatedEnergy));
    };
    const handleDecrementAmount = () => {
        const newValue = Math.max(parseInt(calculatedAmount) - 10, 0);
        setAmountVal(newValue);
        dispatch(setPrice(newValue));
        dispatch(setCharge(calculatedEnergy));
    };
    /*=============================================
    =            Energy Section             =
    =============================================*/

    const handleEnergyChange = async (e) => {
        const newUnit = e.target.value;
        setEnergyVal(newUnit);
        dispatch(setCharge(newUnit));
        // if (!newUnit) {
        //     setAmountVal('');
        //     dispatch(setPrice(''));
        // }
    };
    const handleIncrementEnergy = () => {
        const newValue = Math.max(parseInt(calculatedEnergy) + 1, 0);
        setEnergyVal(newValue);
        dispatch(setCharge(newValue));
        dispatch(setPrice(calculatedAmount));
    };
    const handleDecrementEnergy = () => {
        const newValue = Math.max(parseInt(calculatedEnergy) - 1, 0);
        setEnergyVal(newValue);
        dispatch(setCharge(newValue));
        dispatch(setPrice(calculatedAmount));
    };

    /*=============================================
    =            UseEffects             =
    =============================================*/

    useEffect(() => {
        const connectorId = sessionStorage.getItem('connector_id');
        if (connectorId !== undefined && connectorId !== null) return;
        sessionStorage.setItem('connector_id', location?.state?.connector_id);
    }, []);

    useEffect(() => {
        if (amountVal && !isZeroTarrif) {
            const debouncedAmountChange = setTimeout(() => {
                dispatch(
                    calculateUnit({
                        connector_id: location?.state?.connector_id || location?.state,
                        amount: amountVal,
                    })
                );
            }, 800);
            return () => clearTimeout(debouncedAmountChange);
        }
    }, [amountVal]);

    useEffect(() => {
        if (energyVal && !isZeroTarrif) {
            const debouncedUnitChange = setTimeout(() => {
                dispatch(
                    calculateUnit({
                        connector_id: location?.state?.connector_id || location?.state,
                        unit: energyVal,
                    })
                );
            }, 800);
            return () => clearTimeout(debouncedUnitChange);
        }
    }, [energyVal]);

    return (
        <main className="EnergyCalculation">
            <div>
                <EnergyHeader />
                <Stationbanner />

                <div className="EnergyCalculation__Label">
                    Enter{' '}
                    <span className="EnergyCalculation__LabelBold">
                        {isZeroTarrif ? 'Energy' : 'Amount or Energy'}
                    </span>{' '}
                    to Continue
                </div>
                <IncDecrement
                    label="No. of units (kW)"
                    placeholder="No. of units (kW)"
                    value={calculatedEnergy}
                    onChange={handleEnergyChange}
                    handleDecrement={handleDecrementEnergy}
                    handleIncrement={handleIncrementEnergy}
                    loading={loading}
                    unit={'kW'}
                />

                {!isZeroTarrif && (
                    <IncDecrement
                        label="Amount (in ₹-Incl. of taxes)"
                        placeholder={'Cost (in ₹)'}
                        value={calculatedAmount}
                        onChange={handleAmountChange}
                        handleDecrement={handleDecrementAmount}
                        handleIncrement={handleIncrementAmount}
                        loading={loading}
                        unit={'₹'}
                        disabled={isZeroTarrif}
                    />
                )}
                {isZeroTarrif && (
                    <div className="EnergyCalculation__isZeroTarrif">
                        The station you are using is Charge Free, please add energy to be
                        delivered to continue:
                    </div>
                )}
            </div>
            <div className="EnergyCalculation__RechargeButton">
                <Button
                    text={'Proceed'}
                    glow={calculatedEnergy && calculatedAmount ? true : false}
                    disabled={
                        !isZeroTarrif
                            ? !calculatedEnergy || !calculatedAmount
                            : !calculatedEnergy
                    }
                    onClick={handleRecharge}
                    variant="primary"
                />
            </div>
            <Modal
                isOpen={isModalOpen}
                onClose={closeModal}
                title={<img src={walleticon} alt="wallet" />}
                modalClass="IntiateCharging--custom-modal"
                overlayClass="custom-overlay"
                closeButtonClass="custom-close-button"
            >
                <EnergyModal connector_id={location?.state} />
            </Modal>
        </main>
    );
};

export default EnergyCalculation;
