import React from 'react';
import './ModernCard.scss';
import Card from '../../Common/Card/Card';

const ModernCard = ({ heading, subheading, icon, className, ...props }) => {
    return (
        <Card className={`ModernCard ${className}`} {...props}>
            <div className="header">
                <h4 className="heading">{heading}</h4>
                <p className="subheading">{subheading}</p>
            </div>
            <div className="icon">{icon}</div>
        </Card>
    );
};

export default ModernCard;
