import { createAsyncThunk } from '@reduxjs/toolkit';
import Server from '../../../../Server';
import { VEHICLE_DEFAULT_VEHICLE, VEHICLE_DELETE_VEHICLE, VEHICLE_GET_VEHICLE } from '../../../../API/Endpoint';
import { toast } from 'react-toastify';


export const getVehicle = createAsyncThunk(
    'vehicle/getVehicle',
    async ({ id }, thunkAPI) => {
        try {
            const res = await Server.get(`${VEHICLE_GET_VEHICLE}?customer_id=${id}`,true);
            if (res?.success) {
                return res?.payload;
            } 
            else {
                message.error(res?.payload?.error);
            }
        } catch (err) {
            message.error(err?.res?.message);
            return thunkAPI.rejectWithValue(err?.res?.message);
        }
    }
);


export const createVehicle=createAsyncThunk(
    'vehicle/createVehicle',
    async ( {body,next}, thunkAPI) => {
        try {
            const res = await Server.post(VEHICLE_GET_VEHICLE, body,true);
            console.log(res, 'CreateVehicleAPI');
            if (res?.success) {
                toast.success(`${res?.message}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    style: {
                        fontSize: '16px',
                    },
                });
                next();
            } else {
                toast.error(`Failed to create vehicle`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    style: {
                        fontSize: '16px',
                    },
                });
            }
        } catch (err) {
            return thunkAPI.rejectWithValue(err?.res?.message);
        }
    }
    
)

export const deleteVehicle = createAsyncThunk(
    'vehicle/DeleteVehicle',
    async ({id,next}, thunkAPI) => {
        try {
            const res = await Server.delete(`${VEHICLE_DELETE_VEHICLE}?vehicle_id=${id}`, "",true);
            console.log(res,"response233")  
            if (res?.success) {
                toast.success(`${res?.message}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    style: {
                        fontSize: '16px',
                    },
                });
                next();
            } else {
                toast.error(`Failed to delete vehicle`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    style: {
                        fontSize: '16px',
                    },
                });
            }
        } catch (err) {
            toast.error(`Failed to delete vehicle`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                style: {
                    fontSize: '16px',
                },
            })
            return thunkAPI.rejectWithValue(err?.res?.message);
        }
    }
);

export const setasdefault = createAsyncThunk(
    'vehicle/setasdefault',
    async ({body,next}, thunkAPI) => {
        try {
            const res = await Server.put(VEHICLE_DEFAULT_VEHICLE, '', body, true);
            if (res?.success) {
                next();
                toast.success(`${res?.message}`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    style: {
                        fontSize: '16px',
                    },
                });
            } else {
                toast.error(`Failed to set default vehicle`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    style: {
                        fontSize: '16px',
                    },
                });
            }
        } catch (error) {
            toast.error(`Failed to set default vehicle`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                style: {
                    fontSize: '16px',
                },
            });
            return thunkAPI.rejectWithValue(err?.res?.message);
        }
 }
);


