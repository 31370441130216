import React, { useState } from 'react';
import { Button } from '../../../Components/Common';
import { useNavigate } from 'react-router-dom';
import { Pathname } from '../../../Router/Pathname';
import './startchargingview.scss';
import ArrowLeft from '../../../Assets/icons/ArrowLeft';
import QrScanner from '../../../Components/QrScanner';
import { ReactGA } from "../../../Utils/ga4"

const StartCharginhView = () => {

    React.useEffect(() => {
        ReactGA.sendPageView("Charger QR Scan Page");
    }, []);

    const navigate = useNavigate();
    const [chargingPoint, setChargingPoint] = useState(null);
    const [startScan, setStartScan] = useState(true); // to start camera

    const handleClick = () => {
        setStartScan(false);

        navigate(`${Pathname.START_CHARGING}/select-connection`, {
            state: {
                chargingPointId: chargingPoint,
            },
        });
    };
    const handleBack = () => {
        navigate('/home');
        setStartScan(false);
    }

    console.log("startScan")

    return (
        <main className="startChargingView">
            <section>
                <div className="startChargingView__back">
                    <span onClick={() => {
                        handleBack();
                    }}>
                        <ArrowLeft />
                    </span>
                    <p>Scan QR</p>
                </div>
            </section>
            <div className="startChargingView__scanner">
                <QrScanner
                    handleClick={handleClick}
                    chargingPoint={chargingPoint}
                    setChargingPoint={setChargingPoint}
                    setStartScan={setStartScan}
                    startScan={startScan}
                />
            </div>
            {/* <Button text={'Connection Charging'} onClick={handleClick} /> */}
        </main>
    );
};
export default StartCharginhView;
