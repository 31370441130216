import React from 'react';
import './HelpAndSupportView.scss';
const HelpAndSupportView = () => {
    return (
        <section
            className="HelpAndSupportView
"
        ></section>
    );
};

export default HelpAndSupportView;
