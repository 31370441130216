import React, { useEffect, useRef } from 'react';
import Button from '../Button/Button';
import './worningmodal.scss';
import InformationXL from '../../../Assets/icons/InformationXL';
const WarnlingModal = ({onClose,isOpen,text,handleSubmit}) => {
    const modalRef = useRef(null);

    const handleOutsideClick = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    };
    useEffect(() => {
      if (isOpen) {
        document.addEventListener('mousedown', handleOutsideClick);
      } else {
        document.removeEventListener('mousedown', handleOutsideClick);
      }
  
      return () => {
        document.removeEventListener('mousedown', handleOutsideClick);
      };
    }, [isOpen]);
  
    if (!isOpen) {
      return null;
    }
    return (
        <div>
            <div  className="modal-overlay">
                <div ref={modalRef} className="modal" style={{borderRadius:"19px"}}>
                    <div className="worning-icon">
                        <InformationXL/>
                    </div>
                    <p>{text}</p>
                    <div className="WormimgModal--btn">
                        <div>
                            <Button text="No" variant="secondary" className={'warnling-btn warnling-no-btn'} onClick={onClose} />
                            <Button text="Yes" className={'warnling-btn'} onClick={handleSubmit} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WarnlingModal;