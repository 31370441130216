import React, { useEffect } from 'react';
import ArrowLeft from '../../../../../Assets/icons/ArrowLeft';
import { useNavigate } from 'react-router-dom';
import './EnergyHeader.scss';
const EnergyHeader = () => {
    const navigate = useNavigate();

    return (
        <div className="EnergyHeader--btn">
            <span
                onClick={() => {
                    navigate(-1);
                }}
            >
                <ArrowLeft />
            </span>
            <p>Start Charging</p>
        </div>
    );
};

export default EnergyHeader;
