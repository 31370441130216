// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SplashScreenView {
  display: flex;
  justify-content: center;
  height: 100dvh;
  align-items: center; }
`, "",{"version":3,"sources":["webpack://./src/Views/AfterAuth/SplashScreenView/splash.scss"],"names":[],"mappings":"AAAA;EACI,aAAa;EACb,uBAAuB;EACvB,cAAc;EACd,mBAAmB,EAAA","sourcesContent":[".SplashScreenView {\n    display: flex;\n    justify-content: center;\n    height: 100dvh;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
