import { createAsyncThunk } from '@reduxjs/toolkit';
import Server from '../../../../Server';
import { CONNECTOR_STANDARD, SEARCH_STATIONS } from '../../../../API/Endpoint';
import { toast } from 'react-toastify';

export const getallStations = createAsyncThunk(
    'searchStations/getallStations',
    async ({ station_name, page, connector_type, charging_point_name }, thunkAPI) => {
        try {
            let apiUrl = SEARCH_STATIONS;
            const queryParams = [];
            if (station_name) queryParams.push(`station_name=${station_name}`);

            if (connector_type) queryParams.push(`connector_type=${connector_type}`);
            if (charging_point_name)
                queryParams.push(`charging_point_name=${charging_point_name}`);
            if (page) queryParams.push(`page=${page}`);
            // if (limit) queryParams.push(`limit=${limit}`);
            if (queryParams.length > 0) {
                apiUrl += '?' + queryParams.join('&');
            }
            const response = await Server.get(apiUrl, true);
            if (response.success === true) {
                return response;
            } else {
                toast.error(response?.message);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.response?.message);
        }
    }
);

export const fetchConnectorStandards = createAsyncThunk(
    'searchStations/fetchConnectorStandards',
    async () => {
        try {
            const response = Server.get(CONNECTOR_STANDARD, true);
            return response;
        } catch (error) {
            console.log('error', error);
        }
    }
);
export const filterStations = createAsyncThunk(
    'searchStations/filterStations',
    async ({ station_name, page, connector_type }, thunkAPI) => {
        try {
            let apiUrl = SEARCH_STATIONS;
            const queryParams = [];
            if (station_name) queryParams.push(`station_name=${station_name}`);
            if (connector_type) queryParams.push(`connector_type=${connector_type}`);
            if (page) queryParams.push(`page=${page}`);
            // if (limit) queryParams.push(`limit=${limit}`);
            if (queryParams.length > 0) {
                apiUrl += '?' + queryParams.join('&');
            }
            const response = await Server.get(apiUrl, true);
            if (response.success === true) {
                return response;
            } else {
                toast.error(response?.message);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.response?.message);
        }
    }
);
export const getConnectorById = createAsyncThunk(
    'searchStations/getConnectorById',
    async ({ station_id, charging_point_name }, thunkAPI) => {
        try {
            let apiUrl = SEARCH_STATIONS;
            const queryParams = [];
            if (station_id) queryParams.push(`station_id=${station_id}`);
            if (charging_point_name)
                queryParams.push(`charging_point_name=${charging_point_name}`);
            // if (limit) queryParams.push(`limit=${limit}`);
            if (queryParams.length > 0) {
                apiUrl += '?' + queryParams.join('&');
            }
            const response = await Server.get(apiUrl, true);
            if (response.success === true) {
                return response;
            } else {
                toast.error(response?.message);
            }
        } catch (error) {
            return thunkAPI.rejectWithValue(error?.response?.message);
        }
    }
);
