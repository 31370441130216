import React from 'react'

const Close = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="#8C8C8C"
      />
      <path
        d="M12.7063 11.6466L12.3528 12.0001L12.7063 12.3537L15.0063 14.6537C15.101 14.7484 15.101 14.9118 15.0063 15.0066C14.955 15.0578 14.8944 15.0801 14.8299 15.0801C14.7653 15.0801 14.7047 15.0578 14.6534 15.0066L12.3534 12.7066L11.9999 12.353L11.6463 12.7066L9.34631 15.0066C9.29502 15.0578 9.23441 15.0801 9.16986 15.0801C9.10532 15.0801 9.04471 15.0578 8.99342 15.0066C8.89868 14.9118 8.89868 14.7484 8.99342 14.6537L11.2934 12.3537L11.647 12.0001L11.2934 11.6466L8.99342 9.34655C8.89868 9.25182 8.89868 9.0884 8.99342 8.99366C9.08815 8.89892 9.25157 8.89892 9.34631 8.99366L11.6463 11.2937L11.9999 11.6472L12.3534 11.2937L14.6534 8.99366C14.7482 8.89892 14.9116 8.89892 15.0063 8.99366C15.101 9.0884 15.101 9.25182 15.0063 9.34655L12.7063 11.6466Z"
        fill="white"
        stroke="white"
      />
    </svg>
  );
}

export default Close