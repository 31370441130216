import React, { useRef, useState } from 'react';
import './style.scss';
import dotmore from '../../../Assets/svg/more-horizontal.svg'
import caricon from '../../../Assets/pngIcon/caricon.png'
import VehicleModal from '../VehiclesModal/VehicleModal';
import bike from "../../../Assets/pngIcon/bike.png"
const MyVehiclesCard = ({vehicle,details,type,id,is_default}) => {
    const [openVehicles,setOpenVehicles]=useState(false)
    const [defaultVehicle,setdefaultVehicle]=useState('')
    
    const handleClose=()=>{
            setOpenVehicles(false)
    }
    const handleOpen=()=>{
            setOpenVehicles(id)
    }

    const handleset=(callback)=>{
        setdefaultVehicle(callback)
    }
    
    return (
        <div>
            <div className="myvehicles-div">
                {defaultVehicle || is_default ? (
                    <div className="vehicle-default">
                        <span>Default</span>
                    </div>
                ) : (
                    ''
                )}
                <div className="myvehicle--content">

                <div className="vehicles">
                  {type=="4-Wheeler"? <img src={caricon} alt="" /> : <img src={bike} alt=""/>}
                    <div className="vehicles-details">
                        <p>{details}</p>
                        <p>{type}</p>
                    </div>
                </div>
                <div className="vehicles-modal" onClick={handleOpen}>
                    {!is_default? <img src={dotmore} alt="more-dot" />:null}
                     {/* <img src={dotmore} alt="more-dot" /> */}
                </div>

                {openVehicles && (
                    <VehicleModal openVehicles={openVehicles} handleClose={handleClose} handleset={handleset} isDefault={is_default}/>
                    )}
                    </div>
            </div>
        </div>
    );
};

export default MyVehiclesCard;
