// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center; }

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  text-align: center; }

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer; }

.loading-icon img {
  height: 256px; }

.modal--amount {
  padding-block: 1rem; }
`, "",{"version":3,"sources":["webpack://./src/Components/Common/ModalSucessProgress/modalsucessprogress.scss"],"names":[],"mappings":"AAAA,cAAA;AACA;EACI,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB,EAAA;;AAGrB;EACE,uBAAuB;EACvB,aAAa;EACb,kBAAkB;EAClB,0CAA0C;EAC1C,kBAAkB;EAClB,kBAAkB,EAAA;;AAGpB;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,eAAe,EAAA;;AAGjB;EAEM,aAAa,EAAA;;AAGrB;EACI,mBAAmB,EAAA","sourcesContent":["/* Modal.css */\n.modal-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .modal {\n    background-color: white;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);\n    position: relative;\n    text-align: center;\n  }\n  \n  .modal-close {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    border: none;\n    background: none;\n    font-size: 20px;\n    cursor: pointer;\n  }\n  \n  .loading-icon {\n    img {\n        height: 256px;\n    }\n}\n.modal--amount {\n    padding-block: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
