import React from 'react';
import './TopupInvoiceDownload.scss';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import DividerCard from '../DividerCard/DividerCard';
import { useDispatch, useSelector } from 'react-redux';
import { downloadTopupInvoice } from '../../../Views/AfterAuth/WalletView/redux/walletSlice';
import dayjs from 'dayjs';

const TopupInvoiceDownload = ({ openModal, invoiceClose }) => {
    const { topupInvoiceDetails } = useSelector((store) => store.wallet);

    const dispatch = useDispatch();

    const handleDownloadInvoice = () => {
        dispatch(
            downloadTopupInvoice({
                id: topupInvoiceDetails?.id,
                invoice_id: topupInvoiceDetails?.invoice_id,
            })
        );
    };

    return (
        <div>
            <Modal
                onClose={invoiceClose}
                isOpen={openModal}
                closeButtonClass="wallet-modal-close"
            >
                <div className="DownloadInvoice">
                    <p className="invoice-tag">Invoice</p>
                    <div className="invoice">
                        <div className="card status-name">
                            <p>Invoice Date</p>
                            <p>
                                {dayjs(topupInvoiceDetails?.createdAt).format(
                                    'DD MMMM YYYY'
                                )}
                            </p>
                        </div>
                        <div className="card station-rate">
                            <p>User Name</p>
                            <p>{topupInvoiceDetails?.customer?.user_name}</p>
                        </div>
                    </div>
                    <div className="card withchild">
                        <DividerCard>
                            <div className="left">
                                <p>Invoice Type</p>
                                <p>Payment Mode</p>
                                <p>Invoice Number</p>
                            </div>
                            <div className="right">
                                <p>{topupInvoiceDetails?.invoice_type}</p>
                                <p>{topupInvoiceDetails?.mode}</p>
                                <p>{topupInvoiceDetails?.invoice_id}</p>
                            </div>
                        </DividerCard>
                    </div>
                    <div className="card withchild">
                        <DividerCard>
                            <div className="left">
                                <p>Top Up Amount</p>
                                <p>Total Balance in Wallet</p>
                            </div>
                            <div className="right">
                                <p>{topupInvoiceDetails?.amount}</p>
                                <p>{topupInvoiceDetails?.current_balance}</p>
                            </div>
                        </DividerCard>
                    </div>
                    <div className="download--btn">
                        <Button
                            text={'Download Invoice'}
                            className={'download-btn'}
                            onClick={handleDownloadInvoice}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default TopupInvoiceDownload;
