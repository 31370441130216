import React, { useEffect } from 'react';
import { Buffer } from 'buffer';
import './MyVehiclesView.scss';
import Navbar from '../../../Components/Common/Navbar/Navbar';
import AddIcon from '../../../Assets/icons/AddIcon';
import MyVehiclesCard from '../../../Components/Common/MyVehiclesCard/MyVehiclesCard';
import { useLocation, useNavigate } from 'react-router-dom';
import { Pathname } from '../../../Router/Pathname';
import { useDispatch, useSelector } from 'react-redux';
import { getVehicle } from './Redux/actionCreator';
import { getHomeDetails } from '../HomeView/redux/homeSlice';

const MyVehiclesView = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { vehicleDetail } = useSelector((state) => state.vehicle);

    const token = localStorage.getItem('t_id') || null;
    const parts = token?.split('.');

    // Base64 decode the parts
    const payload =
        parts && parts.length && Buffer.from(parts[1], 'base64').toString('utf8');

    // Parse the JSON in the decoded Header and Payload
    const payloadObj = JSON.parse(payload);

    // Now, you can access the data in the JWT
    let customer_id = payloadObj?.customerId;
    console.log('Payload:', customer_id);

    const getVehicleById = () => {
        dispatch(
            getVehicle({
                id: customer_id,
            })
        );
    };

    useEffect(async () => {
        let data = await dispatch(getHomeDetails());
        //   if (!data?.payload?.payload?.is_onboarded) {
        //         navigate('/userinfo');
        //     }
    }, []);

    useEffect(() => {
        getVehicleById();
    }, []);

    return (
        <main className="MyVehivleView">
            <div className="MyVehivleView--addVehicle">
                <p>My Vehicles</p>
                <span onClick={() => navigate(`${Pathname.MY_VEHICLES}/add-vehicle`)}>
                    <AddIcon />
                </span>
            </div>
            <div className="MyVehivleView--List">
                {/* <MyVehiclesCard
                    setvehicle={'Default'}
                    vehicle={'Myvehicle'}
                    details={'Arna Electric Auto - ARNA 100 model name 1'}
                    type={'Four - wheeler'}
                />
                <MyVehiclesCard
                    vehicle={'Myvehicle'}
                    details={'Arna Electric Auto - ARNA 100 model name 1'}
                    type={'Four - wheeler'}
                /> */}
                <div>
                    {vehicleDetail &&
                        vehicleDetail.map((item, id) => {
                            console.log(item, 'items');
                            return (
                                <MyVehiclesCard
                                    vehicle={'Myvehicle'}
                                    details={item.make}
                                    type={item.type}
                                    {...item}
                                />
                            );
                        })}
                </div>
            </div>
            <Navbar />
        </main>
    );
};

export default MyVehiclesView;
