import React from 'react';

const ElectricityGreen = () => {
    return (
        <div>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.74984 13.3334H11.2498C13.3332 13.3334 14.5832 11.8334 14.5832 10.0001V5.75842C14.5832 4.88342 13.8665 4.16675 12.9915 4.16675H7.01652C6.14152 4.16675 5.42485 4.88342 5.42485 5.75842V10.0001C5.41651 11.8334 6.6665 13.3334 8.74984 13.3334Z"
                    stroke="#00AA74"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.9165 1.66675V4.16675"
                    stroke="#00AA74"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.0835 1.66675V4.16675"
                    stroke="#00AA74"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10 18.3333V13.3333"
                    stroke="#00AA74"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
};

export default ElectricityGreen;
