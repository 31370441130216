import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import Server from '../../../../Server';

const initialState = {
    error: null,
    loading: false,
    otpInfo: null,
    otpVerification: null,
    userInfo: null,
};

export const sendOtpToMobile = createAsyncThunk('otp/mobile', async (data) => {
    // console.log('phn--', data);
    try {
        const response = await Server.post('/api/v1/csms/ocpp/customer/sendotp', {
            phoneNumber: data,
        });
        return response;
    } catch (error) {
        console.log('error', error);
    }
});
export const verifyOtp = createAsyncThunk(
    'otp/verification',
    async ({ phoneNumber, otp }) => {
        try {
            const res = await Server.post('/api/v1/csms/ocpp/customer/verifyotp', {
                phoneNumber: phoneNumber,
                otp: otp,
            });
            return res;
        } catch (error) {
            console.log('error', error);
        }
    }
);

export const updateUser = createAsyncThunk('otp/onboarding', async (data) => {
    try {
        const response = await Server.post(
            '/api/v1/csms/ocpp/customer/onboarding',
            data,
            true
        );
        return response;
    } catch (error) {
        console.log('error', error);
    }
});

const otpSlice = createSlice({
    name: 'otpVerification',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(sendOtpToMobile.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(sendOtpToMobile.fulfilled, (state, action) => {
                state.loading = false;
                state.otpInfo = action.payload;
                state.error = '';
            })
            .addCase(sendOtpToMobile.rejected, (state, action) => {
                state.loading = false;
                state.otpInfo = null;
                state.error = action.error.message;
            })
            .addCase(verifyOtp.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(verifyOtp.fulfilled, (state, action) => {
                state.loading = false;
                state.otpVerification = action.payload;
                state.error = '';
            })
            .addCase(verifyOtp.rejected, (state, action) => {
                state.loading = false;
                state.otpVerification = null;
                state.error = action.error.message;
            })
            .addCase(updateUser.pending, (state) => {
                state.loading = true;
                state.error = '';
            })
            .addCase(updateUser.fulfilled, (state, action) => {
                state.loading = false;
                state.userInfo = action.payload;
                state.error = '';
            })
            .addCase(updateUser.rejected, (state, action) => {
                state.loading = false;
                state.userInfo = null;
                state.error = action.error.message;
            });
    },
});

export default otpSlice.reducer;
