import React from 'react';

export default function MessageQuestion() {
    return (
        <div>
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M11.3335 12.2868L8.66683 12.2868L5.70015 14.2601C5.26015 14.5535 4.66683 14.2402 4.66683 13.7068L4.66683 12.2868C2.66683 12.2868 1.3335 10.9535 1.3335 8.95349L1.3335 4.95345C1.3335 2.95345 2.66683 1.62012 4.66683 1.62012L11.3335 1.62012C13.3335 1.62012 14.6668 2.95345 14.6668 4.95345L14.6668 8.95349C14.6668 10.9535 13.3335 12.2868 11.3335 12.2868Z"
                    stroke="#00AA74"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.0002 7.57324V7.43327C8.0002 6.97993 8.28022 6.73992 8.56022 6.54659C8.83355 6.35992 9.10685 6.11993 9.10685 5.67993C9.10685 5.0666 8.61354 4.57324 8.0002 4.57324C7.38687 4.57324 6.89355 5.0666 6.89355 5.67993"
                    stroke="#00AA74"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.99684 9.16683H8.00284"
                    stroke="#00AA74"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </div>
    );
}
