import React, { useEffect, useState } from 'react';
import './addmoney.scss';
import ArrowLeft from '../../../../Assets/icons/ArrowLeft';
import { Button } from '../../../../Components/Common';
import { useLocation, useNavigate } from 'react-router-dom';
import { Pathname } from '../../../../Router/Pathname';
import Input from '../../../../Components/Common/Input/Input';
import AddMoneySuggests from '../../../../Components/Common/AddMoneySuggests/AddMoneySuggests';
import ModalSucessProgress from '../../../../Components/Common/ModalSucessProgress/ModalSucessProgress';
import Payment from '../../../../Components/RazorPay/Payment';
import { useDispatch, useSelector } from 'react-redux';
import {
    addMoney,
    getWalletTransactions,
    razorpayStatusInfo,
    resetWalletTransactions,
} from '../../WalletView/redux/walletSlice';
import { getProfileDetails } from '../../MyProfileView/redux/profileSlice';
import { ReactGA } from '../../../../Utils/ga4';

const AddMoney = () => {
    React.useEffect(() => {
        ReactGA.sendPageView('Add money page');
    }, []);

    const razorKey = process.env.razorpaykey;
    const location = useLocation(); //connector_id
    const [data, setData] = useState();
    const [amount, setAmount] = useState('');
    console.log('🚀 ~ file: AddMoney.jsx:20 ~ AddMoney ~ amount:', amount);
    const [modalOpen, setModalOpen] = useState(false);
    // const [inputData, setInputData] = useState({
    //     moneyAmount: '',
    // });
    const [inputData, setInputData] = useState('');
    console.log('🚀 ~ file: AddMoney.jsx:26 ~ AddMoney ~ inputData:', inputData);
    const { profile } = useSelector((state) => state);
    const { error, loading, profileInfo } = profile;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // const handleValueChange = (fieldName, value) => {
    //     setInputData((prevInputData) => ({
    //         ...prevInputData,
    //         [fieldName]: value,
    //     }));
    // };

    const sumValue = Number(amount) + Number(inputData);

    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
    };
    const handledata = (data) => {
        setData(data);
    };

    useEffect(() => {
        dispatch(getProfileDetails());
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);
    const openPayModal = async () => {
        // Fetch to get order details
        dispatch(addMoney({ amount: sumValue }))
            .then((data) => {
                const options = {
                    key: razorKey,
                    order_id: data?.payload?.payload?.order_id,
                    handler: async (response) => {
                        console.log('response--', response);
                        await dispatch(
                            razorpayStatusInfo({
                                payment_id: response?.razorpay_payment_id,
                                order_id: response?.razorpay_order_id,
                            })
                        );
                        openModal();
                        setTimeout(() => {
                            setModalOpen(false);
                            location.state
                                ? navigate(`${Pathname.ENERGY_BASED}`, {
                                      state: location.state,
                                  })
                                : navigate('/wallet');
                            //  window.location.reload();
                            dispatch(resetWalletTransactions());

                            // dispatch(getWalletTransactions({ page: 0 }));
                        }, 2500);
                        await dispatch(getWalletTransactions({ page: 0 }));
                    },
                    prefill: {
                        name: profileInfo?.payload?.customer?.user_name,
                        email: profileInfo?.payload?.customer?.email,
                        contact: profileInfo?.payload?.customer?.mobile_number,
                    },
                    notes: {
                        address: profileInfo?.payload?.customer?.address,
                    },
                    theme: {
                        color: '#528ff0',
                    },
                };

                var rzp1 = new window.Razorpay(options);
                rzp1.open();
            })
            .catch((e) => console.log(e));
    };
    return (
        <section
            className="AddMoney"
            style={{ height: `calc(100vh - calc(100vh - ${window.innerHeight}px))` }}
        >
            <div className="AddMoney--btn">
                <span
                    onClick={() => {
                        dispatch(resetWalletTransactions());
                        navigate(-1);
                    }}
                >
                    <ArrowLeft />
                </span>
                <p>Add Money</p>
            </div>
            <div className="AddMoney--Input">
                <Input
                    type="number"
                    label="Enter Amount to Add to Wallet"
                    suggestAm={amount}
                    setAmount={setAmount}
                    sumValue={sumValue}
                    onValueChange={(value) => setInputData(value)}
                />
            </div>
            <div className="AddMoney--tag">
                <AddMoneySuggests amount="2000" setAmount={setAmount} />
                <AddMoneySuggests amount="3000" setAmount={setAmount} />
                <AddMoneySuggests amount="4000" setAmount={setAmount} />
                <AddMoneySuggests amount="5000" setAmount={setAmount} />
            </div>
            {/* )} */}
            <div
                style={{ position: 'absolute', bottom: '2rem' }}
                className="SelectView--btn"
            >
                <Button
                    disabled={sumValue ? false : true}
                    onClick={openPayModal}
                    text={sumValue ? `Continue to Add  ${sumValue}` : 'Continue'}
                />
            </div>
            <ModalSucessProgress
                isOpen={modalOpen}
                onClose={closeModal}
                amount={inputData?.moneyAmount}
                data={data}
                text="Added to Wallet"
            />
        </section>
    );
};
export default AddMoney;
