import React, { useEffect, useState } from 'react';
import Splash from '../../../Assets/SplashScreen1.gif';
import { useNavigate } from 'react-router-dom';
import './splash.scss';
import { Pathname } from '../../../Router/Pathname';

const SplashScreenView = () => {
    const naviagte = useNavigate();
    useEffect(() => {
        const timer = setTimeout(() => {
            naviagte(Pathname.LOGIN);
        }, 4500);
        return () => clearTimeout(timer);
    }, []);
    return (
        <section className="SplashScreenView">
            <img width={'100%'} src={Splash} alt="splash" />
        </section>
    );
};

export default SplashScreenView;
