import React from 'react';

const MoneyIcon = () => {
    return (
        <div>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M14.1665 17.2084H5.83317C4.40725 17.2084 3.35055 16.7999 2.65027 16.0997C1.94999 15.3994 1.5415 14.3427 1.5415 12.9167V7.08342C1.5415 5.65749 1.94999 4.60079 2.65027 3.90051C3.35055 3.20023 4.40725 2.79175 5.83317 2.79175H14.1665C15.5924 2.79175 16.6491 3.20023 17.3494 3.90051C18.0497 4.60079 18.4582 5.65749 18.4582 7.08342V12.9167C18.4582 14.3427 18.0497 15.3994 17.3494 16.0997C16.6491 16.7999 15.5924 17.2084 14.1665 17.2084ZM5.83317 3.04175C4.56802 3.04175 3.53115 3.34917 2.81503 4.06528C2.09892 4.78139 1.7915 5.81826 1.7915 7.08342V12.9167C1.7915 14.1819 2.09892 15.2188 2.81503 15.9349C3.53115 16.651 4.56802 16.9584 5.83317 16.9584H14.1665C15.4317 16.9584 16.4685 16.651 17.1846 15.9349C17.9008 15.2188 18.2082 14.1819 18.2082 12.9167V7.08342C18.2082 5.81826 17.9008 4.78139 17.1846 4.06528C16.4685 3.34917 15.4317 3.04175 14.1665 3.04175H5.83317Z"
                    stroke="#00AA74"
                />
                <path
                    d="M10 12.625C8.55114 12.625 7.375 11.4489 7.375 10C7.375 8.55114 8.55114 7.375 10 7.375C11.4489 7.375 12.625 8.55114 12.625 10C12.625 11.4489 11.4489 12.625 10 12.625ZM10 7.625C8.69052 7.625 7.625 8.69052 7.625 10C7.625 11.3095 8.69052 12.375 10 12.375C11.3095 12.375 12.375 11.3095 12.375 10C12.375 8.69052 11.3095 7.625 10 7.625Z"
                    stroke="#00AA74"
                />
                <path
                    d="M4.5835 12.2084C4.55335 12.2084 4.52207 12.1961 4.49642 12.1705C4.47078 12.1448 4.4585 12.1136 4.4585 12.0834V7.91675C4.4585 7.8866 4.47078 7.85532 4.49642 7.82968C4.52207 7.80403 4.55335 7.79175 4.5835 7.79175C4.61364 7.79175 4.64492 7.80403 4.67057 7.82968C4.69621 7.85532 4.7085 7.8866 4.7085 7.91675V12.0834C4.7085 12.1136 4.69621 12.1448 4.67057 12.1705C4.64492 12.1961 4.61364 12.2084 4.5835 12.2084Z"
                    fill="#00AA74"
                    stroke="#00AA74"
                />
                <path
                    d="M15.4165 12.7084C15.0748 12.7084 14.7915 12.4251 14.7915 12.0834V7.91675C14.7915 7.57508 15.0748 7.29175 15.4165 7.29175C15.7582 7.29175 16.0415 7.57508 16.0415 7.91675V12.0834C16.0415 12.4251 15.7582 12.7084 15.4165 12.7084Z"
                    fill="#00AA74"
                />
            </svg>
        </div>
    );
};
export default MoneyIcon;
